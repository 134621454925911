import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Base64: any;
  Date: string;
  DateTime: string;
  JSON: any;
  Time: any;
  UUID: string;
  Upload: any;
  Void: any;
};

export type AccountFilter = {
  AND?: InputMaybe<AccountFilter>;
  NOT?: InputMaybe<AccountFilter>;
  OR?: InputMaybe<AccountFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type AccountInput = {
  company: Scalars['UUID'];
  legacyId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AccountNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AccountOrder = {
  name?: InputMaybe<Ordering>;
};

export type AccumulatedFlaggedInvoiceLinesFilterConfig = {
  assignedUsers?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  productGroup?: InputMaybe<Scalars['String']>;
};

export type AccumulatedInvoiceLine = {
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  flagged?: Maybe<InvoiceLineFlags>;
  hasActiveResolutionHistory: Scalars['Boolean'];
  index?: Maybe<Scalars['Int']>;
  invoiceLineIds: Array<Scalars['String']>;
  invoiceLineType: InvoiceLineTypes;
  matchedPromotionLine?: Maybe<PromotionLineNode>;
  productGroupId?: Maybe<Scalars['String']>;
  productGroupName?: Maybe<Scalars['String']>;
  productIds?: Maybe<Scalars['String']>;
  productNames?: Maybe<Scalars['String']>;
  promotionLineKey?: Maybe<Scalars['String']>;
  spendRate?: Maybe<Scalars['String']>;
  spendRatePct?: Maybe<Scalars['String']>;
  status: InvoiceLineNextStatuses;
  suggestedFundTypeId?: Maybe<Scalars['String']>;
  suggestedFundTypeName?: Maybe<Scalars['String']>;
  sumOpenAmounts?: Maybe<Scalars['Float']>;
  sumOriginalAmounts?: Maybe<Scalars['Float']>;
  sumResolvedAmounts?: Maybe<Scalars['Float']>;
};

export type AccumulatedInvoiceLineSummary = {
  accumulatedIds: Array<Array<Scalars['UUID']>>;
  results: Array<AccumulatedInvoiceLine>;
  totalCount: Scalars['Int'];
  totalOpenAmount: Scalars['Float'];
  totalOriginalAmount: Scalars['Float'];
};

export type AccumulatedInvoiceLinesFilterConfig = {
  customer?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<Array<Scalars['String']>>;
  flagged?: InputMaybe<Scalars['String']>;
  matchedPromotionLine?: InputMaybe<Scalars['String']>;
  openAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  product?: InputMaybe<Scalars['String']>;
  productGroup?: InputMaybe<Scalars['String']>;
  signedAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  spendRatePctRange?: InputMaybe<Array<Scalars['Float']>>;
  spendRateRange?: InputMaybe<Array<Scalars['Float']>>;
  status?: InputMaybe<InvoiceLineNextStatuses>;
  suggestedFundType?: InputMaybe<Scalars['String']>;
};

export type AccumulatedInvoiceLinesNode = {
  accumulatedLines: Array<AccumulatedInvoiceLinesSummaryNode>;
  allAccumulatedLineIds: Array<Scalars['String']>;
  totalCount: Scalars['Int'];
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export type AccumulatedInvoiceLinesSummaryNode = {
  customer?: Maybe<CustomerNode>;
  fundType?: Maybe<FundTypeNode>;
  id: Scalars['Int'];
  lineDate: Scalars['String'];
  openAmount: Scalars['Float'];
  originalLines: Array<InvoiceLineNextNode>;
  productGroup?: Maybe<ProductGroupNode>;
  signedAmount: Scalars['Float'];
  spendRate?: Maybe<Scalars['String']>;
  spendRatePct?: Maybe<Scalars['String']>;
};

export type AddPromotionAttachmentsInput = {
  attachments: Array<Scalars['UUID']>;
  companyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
};

export type AddPromotionTagToPromotionInput = {
  promotionId: Scalars['UUID'];
  promotionTagId: Scalars['UUID'];
};

export type AddUploadedLinesInput = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  spendRate?: InputMaybe<Scalars['String']>;
  spendRatePct?: InputMaybe<Scalars['String']>;
};

export type AddUploadedLinesInputNext = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  spendRate?: InputMaybe<Scalars['String']>;
  spendRatePct?: InputMaybe<Scalars['String']>;
};

export type AdminActivityLogFilter = {
  AND?: InputMaybe<AdminActivityLogFilter>;
  NOT?: InputMaybe<AdminActivityLogFilter>;
  OR?: InputMaybe<AdminActivityLogFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  user?: InputMaybe<UserFilter>;
};

export type AdminActivityLogNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  message: Scalars['String'];
  occuranceDatetime: Scalars['DateTime'];
  user: UserNode;
};

export type AdminActivityLogNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<AdminActivityLogNode>;
};

export type AllowManualMatchNode = {
  allowManualMatch: Scalars['Boolean'];
  id: Scalars['UUID'];
};

export type AllowManualMatchNodeNext = {
  allowManualMatch: Scalars['Boolean'];
  id: Scalars['UUID'];
};

export type AssignedUsersInput = {
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  id: Scalars['UUID'];
};

export type AttachBackupsInput = {
  backups: Array<Scalars['UUID']>;
  id: Scalars['UUID'];
};

export type AttachedTransactionsAndInvoices = {
  invoiceNumbers: Array<Scalars['String']>;
  transactionDisplayIds: Array<Scalars['String']>;
};

export type AutoAddBackupInput = {
  companyIds?: InputMaybe<Array<Scalars['UUID']>>;
  /** If this flag is True, the value of CompanyIds will be igored.             This updates all companies and add all backup sources to all the companies. */
  updateAll?: InputMaybe<Scalars['Boolean']>;
};

export type BackupAmountFilterRangeNode = {
  maxAmount: Scalars['Float'];
  minAmount: Scalars['Float'];
};

export type BackupFileFilter = {
  AND?: InputMaybe<BackupFileFilter>;
  NOT?: InputMaybe<BackupFileFilter>;
  OR?: InputMaybe<BackupFileFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  amountRange?: InputMaybe<Array<Scalars['Float']>>;
  attachedInvoicesNext?: InputMaybe<InvoiceNextFilter>;
  attachedTransactions?: InputMaybe<TransactionNextFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  backupSourceName?: InputMaybe<Scalars['String']>;
  browseAll?: InputMaybe<Scalars['Boolean']>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  customerName?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<DateFilterLookup>;
  excludeTransactionId?: InputMaybe<Scalars['UUID']>;
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<UuidFilterLookup>;
  onlyTransactionId?: InputMaybe<Scalars['UUID']>;
  promoType?: InputMaybe<FundTypeFilter>;
  promoTypeName?: InputMaybe<Scalars['String']>;
  sortFileName?: InputMaybe<Ordering>;
};

export type BackupFileNode = {
  amount?: Maybe<Scalars['Float']>;
  backupSource?: Maybe<BackupSourceNode>;
  checkNumber?: Maybe<Scalars['String']>;
  company: CompanyNode;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: CustomerNode;
  date?: Maybe<Scalars['Date']>;
  file?: Maybe<CompanyFileNode>;
  id: Scalars['UUID'];
  linkedInvoiceNext?: Maybe<InvoiceNextNode>;
  name?: Maybe<Scalars['String']>;
  promoType?: Maybe<Array<FundTypeNode>>;
};


export type BackupFileNodePromoTypeArgs = {
  filters?: InputMaybe<FundTypeFilter>;
};

export type BackupFileNodeBackupFilePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<BackupFileNode>;
  summary: BackupFileSummary;
};

export type BackupFileOrder = {
  amount?: InputMaybe<Ordering>;
  backupSource?: InputMaybe<BackupSourceOrder>;
  checkNumber?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  customer_Name?: InputMaybe<Ordering>;
  date?: InputMaybe<Ordering>;
  file?: InputMaybe<CompanyFileOrder>;
  file_VisibleName?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  promoType?: InputMaybe<FundTypeOrder>;
  updatedAt?: InputMaybe<Ordering>;
};

export type BackupFileSignedUrlNode = {
  fileType: FileTypes;
  signedUrl?: Maybe<Scalars['String']>;
  visibleName?: Maybe<Scalars['String']>;
};

export type BackupFileSimple = {
  attachedFileName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export type BackupFileSummary = {
  allBackupFiles: Array<BackupFileSimple>;
};

export type BackupRetrievalFilter = {
  AND?: InputMaybe<BackupRetrievalFilter>;
  NOT?: InputMaybe<BackupRetrievalFilter>;
  OR?: InputMaybe<BackupRetrievalFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  portal?: InputMaybe<StrFilterLookup>;
};

export type BackupRetrievalNode = {
  active: Scalars['Boolean'];
  company: CompanyNode;
  customer: CustomerNode;
  id: Scalars['UUID'];
  isDeductionPositive: Scalars['Boolean'];
  isScrapingLoading: Scalars['Boolean'];
  portal: Scalars['String'];
  username: Scalars['String'];
};

export type BackupSourceFilter = {
  AND?: InputMaybe<BackupSourceFilter>;
  NOT?: InputMaybe<BackupSourceFilter>;
  OR?: InputMaybe<BackupSourceFilter>;
  displayName?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  scannerName?: InputMaybe<StrFilterLookup>;
};

export type BackupSourceGroupFilter = {
  AND?: InputMaybe<BackupSourceGroupFilter>;
  NOT?: InputMaybe<BackupSourceGroupFilter>;
  OR?: InputMaybe<BackupSourceGroupFilter>;
  backupSources?: InputMaybe<BackupSourceFilter>;
  company?: InputMaybe<CompanyFilter>;
  customers?: InputMaybe<CustomerFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type BackupSourceGroupNode = {
  backupSources: Array<BackupSourceNode>;
  company: CompanyNode;
  confidenceThreshold: Scalars['Int'];
  customers: Array<CustomerNode>;
  enforceSourceMatch: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  wildcardCustomer?: Maybe<CustomerNode>;
};


export type BackupSourceGroupNodeBackupSourcesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type BackupSourceGroupNodeCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type BackupSourceGroupRelationshipFilter = {
  AND?: InputMaybe<BackupSourceGroupRelationshipFilter>;
  NOT?: InputMaybe<BackupSourceGroupRelationshipFilter>;
  OR?: InputMaybe<BackupSourceGroupRelationshipFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type BackupSourceGroupRelationshipNode = {
  backupSource: BackupSourceNode;
  backupSourceGroup: BackupSourceGroupNode;
  id: Scalars['UUID'];
};

export type BackupSourceGroupRelationshipNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<BackupSourceGroupRelationshipNode>;
};

export type BackupSourceNode = {
  displayName: Scalars['String'];
  footer?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  scannerName: Scalars['String'];
  sensibleConfigurations?: Maybe<Array<Scalars['String']>>;
  sourceType: BackupSourceTypes;
  templateFiles: Array<BackupSourceTemplateFileNode>;
};


export type BackupSourceNodeTemplateFilesArgs = {
  filters?: InputMaybe<BackupSourceTemplateFileFilter>;
};

export type BackupSourceOrder = {
  displayName?: InputMaybe<Ordering>;
};

export type BackupSourceSettingsOverrideFilter = {
  AND?: InputMaybe<BackupSourceSettingsOverrideFilter>;
  NOT?: InputMaybe<BackupSourceSettingsOverrideFilter>;
  OR?: InputMaybe<BackupSourceSettingsOverrideFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type BackupSourceSettingsOverrideNode = {
  backupSource: BackupSourceNode;
  company: CompanyNode;
  confidenceThreshold?: Maybe<Scalars['Int']>;
  customers: Array<CustomerNode>;
  enforceSourceMatch?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  wildcardCustomer?: Maybe<CustomerNode>;
};


export type BackupSourceSettingsOverrideNodeCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type BackupSourceTemplateFileFilter = {
  AND?: InputMaybe<BackupSourceTemplateFileFilter>;
  NOT?: InputMaybe<BackupSourceTemplateFileFilter>;
  OR?: InputMaybe<BackupSourceTemplateFileFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  internalFile?: InputMaybe<InternalFileFilter>;
  name?: InputMaybe<StrFilterLookup>;
};

export type BackupSourceTemplateFileNode = {
  backupSource?: Maybe<BackupSourceNode>;
  id: Scalars['UUID'];
  internalFile: InternalFileNode;
  name: Scalars['String'];
};

export type BackupSourceTemplateFileNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<BackupSourceTemplateFileNode>;
};

export type BackupSourceToBackupSourceGroupInput = {
  backupSourceGroup: Scalars['UUID'];
  backupSources: Array<Scalars['UUID']>;
};

export enum BackupSourceTypes {
  General = 'GENERAL',
  Ocr = 'OCR',
  Sensible = 'SENSIBLE'
}

export type BasicNode = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type BasicPromotionLineNode = {
  account: BasicNode;
  customProducts?: Maybe<Array<BasicNode>>;
  fundType: BasicNode;
  id: Scalars['UUID'];
  lineKey: Scalars['String'];
  productGroup?: Maybe<BasicNode>;
  promotion: BasicPromotionNode;
  spendRate?: Maybe<Scalars['Float']>;
};

export type BasicPromotionNode = {
  customer: BasicNode;
  id: Scalars['UUID'];
  month: Scalars['Int'];
  promKey: Scalars['String'];
  year: Scalars['Int'];
};

export type BatchAddPromTagsInput = {
  companyId: Scalars['UUID'];
  promotionIds: Array<Scalars['UUID']>;
  tags: Array<Scalars['UUID']>;
  tagsToRemove?: InputMaybe<Array<Scalars['UUID']>>;
};

export type BatchDeletePosInput = {
  companyId: Scalars['UUID'];
  posIds: Array<Scalars['UUID']>;
};

export type BatchDeleteRevenueInput = {
  companyId: Scalars['UUID'];
  revenueIds: Array<Scalars['UUID']>;
};

export type BatchIntroduceBackupFileInput = {
  files: Array<IntroduceBackupFileInput>;
};

export type BatchIntroduceBackupFileNode = {
  backups?: Maybe<Array<BackupFileNode>>;
};

export type BatchUpdatePromStatusInput = {
  companyId: Scalars['UUID'];
  contains?: InputMaybe<Scalars['String']>;
  createUsers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  firstReceivers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  fromStatus: PromotionStatus;
  fundTypeIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  hasAttachments?: InputMaybe<Scalars['Boolean']>;
  hasContracts?: InputMaybe<Scalars['Boolean']>;
  lineClosed?: InputMaybe<Scalars['Boolean']>;
  months?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  productGroupIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  promotionIds: Array<Scalars['UUID']>;
  starred?: InputMaybe<Scalars['Boolean']>;
  status: PromotionStatus;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  updateAll: Scalars['Boolean'];
  years?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum BudgetColumnToDelete {
  Revenue = 'REVENUE',
  TradeSpend = 'TRADE_SPEND'
}

export type BudgetDefaultViewFilter = {
  AND?: InputMaybe<BudgetDefaultViewFilter>;
  NOT?: InputMaybe<BudgetDefaultViewFilter>;
  OR?: InputMaybe<BudgetDefaultViewFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  user?: InputMaybe<UserFilter>;
};

export type BudgetDefaultViewNode = {
  budgetView: Scalars['JSON'];
  id: Scalars['UUID'];
  user: UserNode;
};

export type BudgetDiffThresholdNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  revenueLeSource: BudgetRevenueLeSource;
  threshold?: Maybe<Scalars['Float']>;
};

export type BudgetFilter = {
  AND?: InputMaybe<BudgetFilter>;
  NOT?: InputMaybe<BudgetFilter>;
  OR?: InputMaybe<BudgetFilter>;
  company?: InputMaybe<CompanyFilter>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DatetimeFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  product?: InputMaybe<ProductFilter>;
};

export type BudgetGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: BudgetUploadWarningEnum;
};

export type BudgetNode = {
  company: CompanyNode;
  customer: CustomerNode;
  date: Scalars['DateTime'];
  id: Scalars['UUID'];
  product?: Maybe<ProductNode>;
  revenueBudget?: Maybe<Scalars['Float']>;
  tradeBudget?: Maybe<Scalars['Float']>;
};

export enum BudgetRevenueLeSource {
  File = 'FILE',
  Fore = 'FORE'
}

export type BudgetUploadErrors = {
  aobEntryFound?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  productNotFound?: Maybe<Array<Scalars['Int']>>;
};

export type BudgetUploadValidationResponse = {
  errors?: Maybe<BudgetUploadErrors>;
  warnings?: Maybe<BudgetUploadWarnings>;
};

export enum BudgetUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES',
  RevenueTradeBudgetZero = 'REVENUE_TRADE_BUDGET_ZERO'
}

export type BudgetUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: BudgetGroupedIndiceWarning;
  revenueTradeBudgetZero?: Maybe<Array<Scalars['Int']>>;
};

export type BulkCancelInvoicesAttachedToBackupInput = {
  backupFileId: Scalars['UUID'];
  transactionId: Scalars['UUID'];
};

export type BulkInvoiceBackupLinkingInput = {
  backupFileId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
};

export type BulkInvoiceIntroduceInputNext = {
  backupFileId?: InputMaybe<Scalars['UUID']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['UUID'];
};

export type BulkProductGroupProductRelationshipInput = {
  productGroupIds: Array<Scalars['UUID']>;
  productId: Scalars['UUID'];
};

export type BulkResolutionActivityFlagInput = {
  inputs: Array<SingleResolutionActivityFlagInput>;
};

export type BulkResolutionActivityIntroduceInput = {
  companyId: Scalars['UUID'];
  deductionId?: InputMaybe<Scalars['UUID']>;
  invoiceId: Scalars['UUID'];
  resolutionLineInputs: Array<ResolutionActivityInput>;
};

export type BulkResolutionActivityResponseNode = {
  errorMessage?: Maybe<Scalars['String']>;
  resolutionActivities?: Maybe<Array<ResolutionActivityNextNode>>;
};

export type BulkResolutionClearWriteOffInput = {
  companyId: Scalars['UUID'];
  deductionId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
  resolutionLineInputs: Array<SingleResolutionClearWriteOffInput>;
};

export type BulkUpdateInvoiceInputNext = {
  amount?: InputMaybe<Scalars['Float']>;
  backupSource?: InputMaybe<Scalars['UUID']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['UUID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  endPage?: InputMaybe<Scalars['Int']>;
  id: Scalars['UUID'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<InvoiceTypes>;
  originalFile?: InputMaybe<Scalars['UUID']>;
  signedAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['Date']>;
  startPage?: InputMaybe<Scalars['Int']>;
  suggestedFundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  transactionId?: InputMaybe<Scalars['UUID']>;
};

export type BumpChartColumnMapping = {
  arp: Scalars['String'];
  baseUnits: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  incrUnits: Scalars['String'];
  productGrouping: Scalars['String'];
  tdp: Scalars['String'];
  tdpAnypromo: Scalars['String'];
};

export type BumpChartDataFilter = {
  AND?: InputMaybe<BumpChartDataFilter>;
  NOT?: InputMaybe<BumpChartDataFilter>;
  OR?: InputMaybe<BumpChartDataFilter>;
  company?: InputMaybe<CompanyFilter>;
  customerName?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  productGrouping?: InputMaybe<StrFilterLookup>;
};

export type BumpChartDataNode = {
  arp: Scalars['Float'];
  baseUnits: Scalars['Float'];
  company: CompanyNode;
  customerName: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['UUID'];
  incrUnits: Scalars['Float'];
  productGrouping: Scalars['String'];
  tdp?: Maybe<Scalars['Float']>;
  tdpAnypromo?: Maybe<Scalars['Float']>;
};

export type BumpChartFileData = {
  arp: Array<Scalars['String']>;
  baseUnits: Array<Scalars['String']>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  incrUnits: Array<Scalars['String']>;
  productGrouping: Array<Scalars['String']>;
  tdp: Array<Maybe<Scalars['String']>>;
  tdpAnypromo: Array<Maybe<Scalars['String']>>;
};

export type BumpChartFileDataInput = {
  arp: Array<Scalars['String']>;
  baseUnits: Array<Scalars['String']>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  incrUnits: Array<Scalars['String']>;
  productGrouping: Array<Scalars['String']>;
  tdp: Array<InputMaybe<Scalars['String']>>;
  tdpAnypromo: Array<InputMaybe<Scalars['String']>>;
};

export type BumpChartFileDataOutput = BumpChartFileData & {
  arp: Array<Scalars['String']>;
  baseUnits: Array<Scalars['String']>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  incrUnits: Array<Scalars['String']>;
  productGrouping: Array<Scalars['String']>;
  tdp: Array<Maybe<Scalars['String']>>;
  tdpAnypromo: Array<Maybe<Scalars['String']>>;
};

export type BumpChartGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: BumpChartUploadWarningEnum;
};

export type BumpChartMappedFileResponse = {
  columnMapping: BumpChartColumnMapping;
  fileData: BumpChartFileDataOutput;
  validationResults: BumpChartUploadValidationResponse;
};

export type BumpChartUploadErrors = {
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
};

export type BumpChartUploadValidationResponse = {
  errors?: Maybe<BumpChartUploadErrors>;
  warnings?: Maybe<BumpChartUploadWarnings>;
};

export enum BumpChartUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type BumpChartUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: BumpChartGroupedIndiceWarning;
};

export type BuyInWindowFilter = {
  AND?: InputMaybe<BuyInWindowFilter>;
  NOT?: InputMaybe<BuyInWindowFilter>;
  OR?: InputMaybe<BuyInWindowFilter>;
  customer?: InputMaybe<CustomerFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  month?: InputMaybe<StrFilterLookup>;
  year?: InputMaybe<IntFilterLookup>;
};

export type BuyInWindowInput = {
  customer: Scalars['UUID'];
  endDate: Scalars['Date'];
  month?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
  year: Scalars['Int'];
};

export type BuyInWindowNode = {
  customer: CustomerNode;
  endDate: Scalars['Date'];
  id: Scalars['UUID'];
  month: Scalars['String'];
  startDate: Scalars['Date'];
  year: Scalars['Int'];
};

export type BuyInWindowPartialInput = {
  customer: Scalars['UUID'];
  endDate?: InputMaybe<Scalars['Date']>;
  month?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type CancelSalesReviewTagInput = {
  endIndex: Scalars['Int'];
  startIndex: Scalars['Int'];
  userId: Scalars['UUID'];
};

export type CancelTransactionNextInput = {
  cancellationReason?: InputMaybe<Scalars['String']>;
  cancelled: Scalars['Boolean'];
  id: Scalars['UUID'];
};

export type CheckClearableInvoiceLinesResponse = {
  clearableLines: Array<Scalars['UUID']>;
  invalidOpenAmounts: Scalars['Boolean'];
};

export type CheckPromotionHasDeductionsInput = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type CheckPromotionLineDependencyInput = {
  promotionLineId: Scalars['UUID'];
};

export type CheckPromotionLineDependencyResponse = {
  hasInvoice: Scalars['Boolean'];
  hasSpend: Scalars['Boolean'];
};

export type CheckPromotionNameConflictInput = {
  companyId: Scalars['UUID'];
  names: Array<Scalars['String']>;
};

export type ClearExactAmountsResponseNode = {
  errorMessage?: Maybe<Scalars['String']>;
  resolutionActivities?: Maybe<Array<ResolutionActivityNextNode>>;
};

export type CommentFilter = {
  AND?: InputMaybe<CommentFilter>;
  NOT?: InputMaybe<CommentFilter>;
  OR?: InputMaybe<CommentFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  text?: InputMaybe<StrFilterLookup>;
};

export type CommentNode = {
  createUser?: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  text: Scalars['String'];
};

export type CommentOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type CommentsInput = {
  id?: InputMaybe<Scalars['UUID']>;
  text: Scalars['String'];
  toDelete?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyBackupSourceRelationshipFilter = {
  AND?: InputMaybe<CompanyBackupSourceRelationshipFilter>;
  NOT?: InputMaybe<CompanyBackupSourceRelationshipFilter>;
  OR?: InputMaybe<CompanyBackupSourceRelationshipFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  company: CompanyFilter;
};

export type CompanyBackupSourceRelationshipInput = {
  backupSource: Scalars['UUID'];
  company: Scalars['UUID'];
};

export type CompanyBackupSourceRelationshipNode = {
  backupSource: BackupSourceNode;
  company: CompanyNode;
  id: Scalars['UUID'];
};

export type CompanyDefaultApprovalLimitFilter = {
  AND?: InputMaybe<CompanyDefaultApprovalLimitFilter>;
  NOT?: InputMaybe<CompanyDefaultApprovalLimitFilter>;
  OR?: InputMaybe<CompanyDefaultApprovalLimitFilter>;
  company: CompanyFilter;
  id?: InputMaybe<UuidFilterLookup>;
};

export type CompanyDefaultApprovalLimitInput = {
  appearsInFrontendFilters?: InputMaybe<Scalars['Boolean']>;
  company: Scalars['UUID'];
  lowerLimit?: InputMaybe<Scalars['Int']>;
  upperLimit?: InputMaybe<Scalars['Int']>;
};

export type CompanyDefaultApprovalLimitNode = {
  appearsInFrontendFilters?: Maybe<Scalars['Boolean']>;
  company: CompanyNode;
  id: Scalars['UUID'];
  lowerLimit?: Maybe<Scalars['Int']>;
  upperLimit?: Maybe<Scalars['Int']>;
};

export type CompanyDefaultApprovalLimitPartialInput = {
  appearsInFrontendFilters?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  lowerLimit?: InputMaybe<Scalars['Int']>;
  upperLimit?: InputMaybe<Scalars['Int']>;
};

export type CompanyFeatureFilter = {
  AND?: InputMaybe<CompanyFeatureFilter>;
  NOT?: InputMaybe<CompanyFeatureFilter>;
  OR?: InputMaybe<CompanyFeatureFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type CompanyFeatureGateFilter = {
  AND?: InputMaybe<CompanyFeatureGateFilter>;
  NOT?: InputMaybe<CompanyFeatureGateFilter>;
  OR?: InputMaybe<CompanyFeatureGateFilter>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type CompanyFeatureGateNode = {
  featureGate: FeatureGates;
  id: Scalars['UUID'];
};

export type CompanyFeatureGateRelationshipInput = {
  company: Scalars['UUID'];
  featureGate: Scalars['String'];
};

export type CompanyFeatureNode = {
  company: DjangoModelType;
  feature: Feature;
  id: Scalars['UUID'];
};

export type CompanyFeatureRelationshipInput = {
  company: Scalars['UUID'];
  feature: Scalars['String'];
};

export enum CompanyFileCategories {
  Attachment = 'ATTACHMENT',
  BackupSourceTemplate = 'BACKUP_SOURCE_TEMPLATE',
  BumpChart = 'BUMP_CHART',
  Contacts = 'CONTACTS',
  Customer = 'CUSTOMER',
  DeductionsMatching = 'DEDUCTIONS_MATCHING',
  DeductionsScan = 'DEDUCTIONS_SCAN',
  Dispute = 'DISPUTE',
  ErpTransactions = 'ERP_TRANSACTIONS',
  InvoiceBackup = 'INVOICE_BACKUP',
  InvoiceLines = 'INVOICE_LINES',
  LatestEstimate = 'LATEST_ESTIMATE',
  Lift = 'LIFT',
  PointOfSale = 'POINT_OF_SALE',
  Pricing = 'PRICING',
  Product = 'PRODUCT',
  ProductGroupHierarchy = 'PRODUCT_GROUP_HIERARCHY',
  Promotions = 'PROMOTIONS',
  PromotionsEdit = 'PROMOTIONS_EDIT',
  ReconciledSpend = 'RECONCILED_SPEND',
  Revenue = 'REVENUE',
  RevenueBudget = 'REVENUE_BUDGET',
  Seasonality = 'SEASONALITY',
  Spend = 'SPEND',
  SyndicatedData = 'SYNDICATED_DATA',
  Transactions = 'TRANSACTIONS'
}

export type CompanyFileFilter = {
  AND?: InputMaybe<CompanyFileFilter>;
  NOT?: InputMaybe<CompanyFileFilter>;
  OR?: InputMaybe<CompanyFileFilter>;
  company?: InputMaybe<CompanyFilter>;
  fileType?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  visibleName?: InputMaybe<StrFilterLookup>;
};

export type CompanyFileNode = {
  categories: Array<FileCategoryRelationshipNode>;
  company: CompanyNode;
  fileData: Scalars['Base64'];
  fileType: FileTypes;
  id: Scalars['UUID'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
  visibleName: Scalars['String'];
};


export type CompanyFileNodeCategoriesArgs = {
  filters?: InputMaybe<FileCategoryRelationshipFilter>;
};

export type CompanyFileNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<CompanyFileNode>;
};

export type CompanyFileOrder = {
  fileType?: InputMaybe<Ordering>;
  visibleName?: InputMaybe<Ordering>;
};

export type CompanyFilter = {
  AND?: InputMaybe<CompanyFilter>;
  NOT?: InputMaybe<CompanyFilter>;
  OR?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  isDeactivated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StrFilterLookup>;
};

export type CompanyInput = {
  isDeactivated?: InputMaybe<Scalars['Boolean']>;
  isDemo?: InputMaybe<Scalars['Boolean']>;
  isImplementation?: InputMaybe<Scalars['Boolean']>;
  logoutTime?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  tier?: InputMaybe<Tier>;
  users?: InputMaybe<ManyToManyInput>;
};

export type CompanyNode = {
  allOtherBucketsAveragedWeeks: Scalars['Int'];
  autoCloseDays?: Maybe<Scalars['Int']>;
  customers: Array<CustomerNode>;
  featureGates: Array<CompanyFeatureGateNode>;
  features: Array<CompanyFeatureNode>;
  id: Scalars['UUID'];
  isDeactivated: Scalars['Boolean'];
  isDemo: Scalars['Boolean'];
  isImplementation: Scalars['Boolean'];
  logoutTime: Scalars['Int'];
  name: Scalars['String'];
  noAutoClose: Scalars['Boolean'];
  paginatedUsers: UserNodePaginationConnection;
  productGroups: Array<ProductGroupNode>;
  providedServices: Array<CompanyProvidedServiceNode>;
  retailersAveragedWeeks: Scalars['Int'];
  roles: Array<RoleNode>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  tier?: Maybe<Tier>;
  users: Array<UserNode>;
};


export type CompanyNodeCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type CompanyNodeFeatureGatesArgs = {
  filters?: InputMaybe<CompanyFeatureGateFilter>;
};


export type CompanyNodeFeaturesArgs = {
  filters?: InputMaybe<CompanyFeatureFilter>;
};


export type CompanyNodePaginatedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type CompanyNodeProductGroupsArgs = {
  filters?: InputMaybe<ProductGroupFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type CompanyNodeProvidedServicesArgs = {
  filters?: InputMaybe<CompanyProvidedServiceFilter>;
};


export type CompanyNodeRolesArgs = {
  filters?: InputMaybe<RoleFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type CompanyNodeUsersArgs = {
  filters?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type CompanyOrder = {
  name?: InputMaybe<Ordering>;
};

export type CompanyProvidedServiceFilter = {
  AND?: InputMaybe<CompanyProvidedServiceFilter>;
  NOT?: InputMaybe<CompanyProvidedServiceFilter>;
  OR?: InputMaybe<CompanyProvidedServiceFilter>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type CompanyProvidedServiceNode = {
  id: Scalars['UUID'];
  providedService: ProvidedServices;
};

export type CompanyProvidedServiceRelationshipInput = {
  company: Scalars['UUID'];
  providedService: Scalars['String'];
};

export type CompanyScanningSettingInput = {
  additionalIncrementCharge?: InputMaybe<Scalars['Int']>;
  additionalPageIncrement?: InputMaybe<Scalars['Int']>;
  basePageLimit?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Scalars['Int']>;
  company: Scalars['UUID'];
};

export type CompanyScanningSettingNode = {
  additionalIncrementCharge: Scalars['Int'];
  additionalPageIncrement: Scalars['Int'];
  basePageLimit: Scalars['Int'];
  basePrice: Scalars['Int'];
  company: CompanyNode;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
};

export type CompanyScanningSettingPartialInput = {
  additionalIncrementCharge?: InputMaybe<Scalars['Int']>;
  additionalPageIncrement?: InputMaybe<Scalars['Int']>;
  basePageLimit?: InputMaybe<Scalars['Int']>;
  basePrice?: InputMaybe<Scalars['Int']>;
  company: Scalars['UUID'];
};

export type CompanyUpdateInput = {
  allOtherBucketsAveragedWeeks?: InputMaybe<Scalars['Int']>;
  autoCloseDays?: InputMaybe<Scalars['Int']>;
  id: Scalars['UUID'];
  isDeactivated?: InputMaybe<Scalars['Boolean']>;
  isDemo?: InputMaybe<Scalars['Boolean']>;
  isImplementation?: InputMaybe<Scalars['Boolean']>;
  logoutTime?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  noAutoClose?: InputMaybe<Scalars['Boolean']>;
  retailersAveragedWeeks?: InputMaybe<Scalars['Int']>;
  tier?: InputMaybe<Tier>;
  updatingUser?: InputMaybe<Scalars['UUID']>;
};

export type CompanyUserRelationshipInput = {
  companyId: Scalars['UUID'];
  roles: Array<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type CompanyUserRelationshipPartialInput = {
  companyId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type ContactColumnMapping = {
  company: Scalars['String'];
  contactName: Scalars['String'];
  contactTitle: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ContactFileData = {
  company: Array<Maybe<Scalars['String']>>;
  contactName: Array<Scalars['String']>;
  contactTitle: Array<Maybe<Scalars['String']>>;
  email: Array<Maybe<Scalars['String']>>;
  phoneNumber: Array<Maybe<Scalars['String']>>;
};

export type ContactFileDataInput = {
  company: Array<InputMaybe<Scalars['String']>>;
  contactName: Array<Scalars['String']>;
  contactTitle: Array<InputMaybe<Scalars['String']>>;
  email: Array<InputMaybe<Scalars['String']>>;
  phoneNumber: Array<InputMaybe<Scalars['String']>>;
};

export type ContactFileDataOutput = ContactFileData & {
  company: Array<Maybe<Scalars['String']>>;
  contactName: Array<Scalars['String']>;
  contactTitle: Array<Maybe<Scalars['String']>>;
  email: Array<Maybe<Scalars['String']>>;
  phoneNumber: Array<Maybe<Scalars['String']>>;
};

export type ContactFilter = {
  AND?: InputMaybe<ContactFilter>;
  NOT?: InputMaybe<ContactFilter>;
  OR?: InputMaybe<ContactFilter>;
  company?: InputMaybe<CompanyFilter>;
  externalCompany?: InputMaybe<StrFilterLookup>;
  externalEmail?: InputMaybe<StrFilterLookup>;
  externalName?: InputMaybe<StrFilterLookup>;
  externalPhone?: InputMaybe<StrFilterLookup>;
  externalTitle?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  omni?: Scalars['String'];
};

export type ContactGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: ContactUploadWarningEnum;
};

export type ContactInput = {
  company: Scalars['UUID'];
  externalCompany?: InputMaybe<Scalars['String']>;
  externalEmail?: InputMaybe<Scalars['String']>;
  externalName?: InputMaybe<Scalars['String']>;
  externalPhone?: InputMaybe<Scalars['String']>;
  externalTitle?: InputMaybe<Scalars['String']>;
};

export type ContactMappedFileResponse = {
  columnMapping: ContactColumnMapping;
  fileData: ContactFileDataOutput;
  validationResults: ContactUploadValidationResponse;
};

export type ContactNode = {
  company?: Maybe<CompanyNode>;
  externalCompany?: Maybe<Scalars['String']>;
  externalEmail?: Maybe<Scalars['String']>;
  externalName?: Maybe<Scalars['String']>;
  externalPhone?: Maybe<Scalars['String']>;
  externalTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export type ContactNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<ContactNode>;
};

export type ContactOrder = {
  externalCompany?: InputMaybe<Ordering>;
  externalEmail?: InputMaybe<Ordering>;
  externalName?: InputMaybe<Ordering>;
  externalTitle?: InputMaybe<Ordering>;
};

export type ContactPartialInput = {
  company: Scalars['UUID'];
  externalCompany?: InputMaybe<Scalars['String']>;
  externalEmail?: InputMaybe<Scalars['String']>;
  externalName?: InputMaybe<Scalars['String']>;
  externalPhone?: InputMaybe<Scalars['String']>;
  externalTitle?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export type ContactUploadErrors = {
  alreadyInDatabase?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
};

export type ContactUploadValidationResponse = {
  errors?: Maybe<ContactUploadErrors>;
  warnings?: Maybe<ContactUploadWarnings>;
};

export enum ContactUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type ContactUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: ContactGroupedIndiceWarning;
};

export type CreateAdminActivityLogInput = {
  company: Scalars['UUID'];
  message: Scalars['String'];
  occuranceDatetime: Scalars['DateTime'];
  user: Scalars['UUID'];
};

export type CreateBackupSourceGroupInput = {
  company: Scalars['UUID'];
  confidenceThreshold?: InputMaybe<Scalars['Int']>;
  enforceSourceMatch?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  wildcardCustomer?: InputMaybe<Scalars['UUID']>;
};

export type CreateBackupSourcePartialInput = {
  displayName?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Scalars['String']>;
  scannerName?: InputMaybe<Scalars['String']>;
  sourceType: BackupSourceTypes;
  thirdPartyId?: InputMaybe<Scalars['String']>;
};

export type CreateBackupSourceSettingsOverrideInput = {
  backupSource: Scalars['UUID'];
  company: Scalars['UUID'];
  confidenceThreshold?: InputMaybe<Scalars['Int']>;
  enforceSourceMatch?: InputMaybe<Scalars['Boolean']>;
  wildcardCustomer: Scalars['UUID'];
};

export type CreateBackupSourceTemplateFileInput = {
  backupSource: Scalars['UUID'];
  internalFile: Scalars['UUID'];
  name: Scalars['String'];
};

export type CreateBudgetDefaultViewInput = {
  budgetView?: InputMaybe<Scalars['JSON']>;
  userId: Scalars['UUID'];
};

export type CreateCommentInput = {
  companyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  text?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyFileInput = {
  categories: Array<CreateFileCategoryInput>;
  company: Scalars['UUID'];
  fileType: FileTypes;
  visibleName: Scalars['String'];
};

export type CreateDeductionMatchingColumnMappingInput = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  customerNameAcc?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  deductionMatchingInstance: Scalars['UUID'];
  fundType: Scalars['String'];
  productName: Scalars['String'];
  spendRate: Scalars['String'];
};

export type CreateDeductionMatchingColumnOrderInput = {
  columnHeader: Scalars['String'];
  deductionMatchingInstance: Scalars['UUID'];
  orderIndex: Scalars['Int'];
  view: Scalars['String'];
};

export type CreateDeductionMatchingInstanceInput = {
  backupSource?: InputMaybe<Scalars['UUID']>;
  backupSourceGroup?: InputMaybe<Scalars['UUID']>;
  company: Scalars['UUID'];
  uploadFile: Scalars['UUID'];
};

export type CreateDeductionScanInput = {
  backupSource: Scalars['UUID'];
  checkNumber?: InputMaybe<Scalars['String']>;
  company: Scalars['UUID'];
  uploadedFiles: Array<CreateFileInput>;
};

export type CreateDiscountCutoffInput = {
  company: Scalars['UUID'];
  comparison: NumberComparison;
  cutoffValue: Scalars['Float'];
  fundType: Scalars['UUID'];
};

export type CreateFileCategoryInput = {
  category: CompanyFileCategories;
};

export type CreateFileInput = {
  inputFile: Scalars['UUID'];
  pagesScanned?: InputMaybe<Scalars['Int']>;
};

export type CreateInternalFileInput = {
  category: InternalFileCategories;
  customerVisible?: InputMaybe<Scalars['Boolean']>;
  fileType: FileTypes;
  visibleName: Scalars['String'];
};

export type CreateLiftBucketInput = {
  company: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
};

export type CreateLiftInput = {
  company: Scalars['UUID'];
  customer: Scalars['UUID'];
  liftBucket: Scalars['UUID'];
  liftValue?: InputMaybe<Scalars['Float']>;
  productGroup: Scalars['UUID'];
};

export type CreateOriginalFileColumnHeaderInput = {
  columnHeader: Scalars['String'];
  sheetNumber?: InputMaybe<Scalars['Int']>;
  uploadedFile: Scalars['UUID'];
};

export type CreateProductCodes = {
  createProductCodes: CreateProductCodes;
  duplicateCodes: Array<Scalars['String']>;
  productCodes: Array<ProductCodeNode>;
};


export type CreateProductCodesCreateProductCodesArgs = {
  data: CreateProductCodesInput;
};

export type CreateProductCodesInput = {
  productCodes: Array<Scalars['String']>;
  productId: Scalars['UUID'];
};

export type CreateProductGroup = {
  createProductGroup: CreateProductGroup;
  productGroup: ProductGroupNode;
};


export type CreateProductGroupCreateProductGroupArgs = {
  data: ProductGroupPartialInput;
};

export type CreateProductGroupProductRelationship = {
  createProductGroupProductRelationship: CreateProductGroupProductRelationship;
  product: ProductNode;
  productGroup: ProductGroupNode;
};


export type CreateProductGroupProductRelationshipCreateProductGroupProductRelationshipArgs = {
  data: ProductGroupProductRelationshipInput;
};

export type CreatePromotionTagInput = {
  color: Scalars['String'];
  company: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  legacyId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  textColor: Scalars['String'];
};

export type CreateRoleInput = {
  company: Scalars['UUID'];
  name: Scalars['String'];
};

export type CustomerAlternativeNameFilter = {
  AND?: InputMaybe<CustomerAlternativeNameFilter>;
  NOT?: InputMaybe<CustomerAlternativeNameFilter>;
  OR?: InputMaybe<CustomerAlternativeNameFilter>;
  customer?: InputMaybe<CustomerTimeFrameFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type CustomerAlternativeNameInput = {
  customer: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  importedFromPresetCustomer?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type CustomerAlternativeNameNode = {
  customer: CustomerTimeFrameNode;
  id: Scalars['UUID'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type CustomerAlternativeNamePartialInput = {
  customer?: InputMaybe<Scalars['UUID']>;
  customerType?: InputMaybe<CustomerType>;
  id?: InputMaybe<Scalars['UUID']>;
  importedFromPresetCustomer?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

export type CustomerAlternativeNameTagFilter = {
  AND?: InputMaybe<CustomerAlternativeNameTagFilter>;
  NOT?: InputMaybe<CustomerAlternativeNameTagFilter>;
  OR?: InputMaybe<CustomerAlternativeNameTagFilter>;
  company?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type CustomerAlternativeNameTagInput = {
  company: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

export type CustomerAlternativeNameTagNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type CustomerBucketInput = {
  bucket: Scalars['UUID'];
  customer: Scalars['UUID'];
};

export type CustomerBucketRelationshipFilter = {
  AND?: InputMaybe<CustomerBucketRelationshipFilter>;
  NOT?: InputMaybe<CustomerBucketRelationshipFilter>;
  OR?: InputMaybe<CustomerBucketRelationshipFilter>;
  bucket?: InputMaybe<ForecastSeasonalityBucketFilter>;
  customer?: InputMaybe<CustomerFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type CustomerBucketRelationshipNode = {
  bucket: ForecastSeasonalityBucketNode;
  customer: CustomerNode;
  id: Scalars['UUID'];
};

export type CustomerBucketRelationshipOrder = {
  customer?: InputMaybe<CustomerOrder>;
};

export type CustomerDistributorRelationshipNode = {
  customer: CustomerTimeFrameNode;
  customerDistributorSpendSplits: Array<CustomerDistributorSpendSplitNode>;
  distributor: CustomerNode;
  id: Scalars['UUID'];
};

export type CustomerDistributorSpendSplitNode = {
  id: Scalars['UUID'];
  percentSplit: Scalars['Float'];
  productGroup?: Maybe<ProductGroupNode>;
};

export type CustomerFilter = {
  AND?: InputMaybe<CustomerFilter>;
  NOT?: InputMaybe<CustomerFilter>;
  OR?: InputMaybe<CustomerFilter>;
  allOtherBucket?: InputMaybe<CustomerFilter>;
  allOtherBucketDistributor?: InputMaybe<CustomerFilter>;
  company?: InputMaybe<CompanyFilter>;
  customerAlternativeNames?: InputMaybe<CustomerAlternativeNameFilter>;
  customerType?: InputMaybe<CustomerType>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  omni?: Scalars['String'];
  orderByLift?: InputMaybe<OrderByLiftValue>;
  pnlGroup?: InputMaybe<PnlGroupFilter>;
  users?: InputMaybe<UserFilter>;
};

export type CustomerGroupNextCreateErrors = {
  /** Database Integrity Error such as unique constraint error */
  dbIntegrityError?: Maybe<Scalars['String']>;
  /** The hierarchy integrity is violated with the new entry */
  hierarchyIntegrityViolation?: Maybe<Scalars['Boolean']>;
  /** The parent customer group doesn't have a hierarchy entry. */
  parentCustomerGroupDoesNotExistInHierarchy?: Maybe<Scalars['Boolean']>;
};

export type CustomerGroupNextCreateResponse = {
  data?: Maybe<CustomerGroupNextCreateSuccess>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<CustomerGroupNextCreateErrors>;
};

export type CustomerGroupNextCreateSuccess = {
  customerGroup: CustomerGroupNode;
  parentCustomerGroup?: Maybe<CustomerGroupNode>;
};

export type CustomerGroupNextInput = {
  companyId: Scalars['UUID'];
  name: Scalars['String'];
  /** Parent Customer Group ID, if any at all. If not provided, then this is a root customer group. */
  parentCustomerGroup?: InputMaybe<Scalars['UUID']>;
};

export type CustomerGroupNode = {
  company: CompanyNode;
  customers: Array<CustomerNode>;
  id: Scalars['UUID'];
  isRoot: Scalars['Boolean'];
  name: Scalars['String'];
};


export type CustomerGroupNodeCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
};

export type CustomerHierarchyList = {
  currentNode: CustomerHierarchyNode;
  descendants: Array<CustomerHierarchyNode>;
  steplen: Scalars['Int'];
  /** The total number of descendants of the current node. */
  totalDescendants: Scalars['Int'];
};

export type CustomerHierarchyNextOptions = {
  /** If true, only customer groups are returned. */
  getOnlyCustomerGroups?: InputMaybe<Scalars['Boolean']>;
  /** If true, only direct children are returned otherwise all descendants, including children of children will be returned. */
  getOnlyDirectChildren?: InputMaybe<Scalars['Boolean']>;
  /** The parent customer group ID to get the children of; if not provided, the root customer group will be used. */
  parentCustomerGroupId?: InputMaybe<Scalars['ID']>;
  /** The date from which the created_at field of the node should be considered; if not provided, all nodes will be considered. */
  validFrom?: InputMaybe<Scalars['DateTime']>;
  /** The date till which the deleted_at field of the node should be considered; if not provided, all nodes will be considered. */
  validTill?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerHierarchyNode = {
  customer?: Maybe<CustomerNode>;
  customerGroup?: Maybe<CustomerGroupNode>;
  depth: Scalars['Int'];
  doesNodeRepresentCustomerGroup: Scalars['Boolean'];
  path: Scalars['String'];
};

export type CustomerInput = {
  allOtherBucketDistributor?: InputMaybe<Scalars['UUID']>;
  company?: InputMaybe<Scalars['UUID']>;
  customerType?: InputMaybe<CustomerType>;
  name: Scalars['String'];
};

export type CustomerNextCreateErrors = {
  /** Error while creating customer */
  customerCreationError?: Maybe<Scalars['String']>;
  /** Parent Customer Group has no Hierarchy entry */
  customerGroupHierarchyDoesNotExist?: Maybe<Scalars['Boolean']>;
  /** Name already exists, either as customer or alternate name */
  customerNameAlreadyExists?: Maybe<Scalars['Boolean']>;
  /** Hierarchy Integrity Violation while adding customer */
  hierarchyIntegrityViolation?: Maybe<Scalars['Boolean']>;
  /** Parent Customer Group provided in the request object does not exist */
  parentCustomerGroupDoesNotExist?: Maybe<Scalars['Boolean']>;
  /** Parent Customer Group not provided and Root Customer Group does not exist */
  parentCustomerGroupNotProvidedAndRootDoesNotExist?: Maybe<Scalars['Boolean']>;
};

export type CustomerNextCreateResponse = {
  data?: Maybe<CustomerNextCreateSuccess>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<CustomerNextCreateErrors>;
};

export type CustomerNextCreateSuccess = {
  /** New AOB Customer, if new_aob_name is provided and customer_type is Distributor */
  aobCustomer?: Maybe<CustomerNode>;
  customer: CustomerNode;
  parentCustomerGroup: CustomerGroupNode;
};

export type CustomerNextInput = {
  allOtherBucketDistributor?: InputMaybe<Scalars['UUID']>;
  companyId: Scalars['UUID'];
  customerType?: InputMaybe<CustomerType>;
  name: Scalars['String'];
  /** New AOB Name, if provided, will be used to create a new AOB. This shall only be used when the Customer Type is Distributor */
  newAobName?: InputMaybe<Scalars['String']>;
  /** Parent Customer Group ID, if not provided - the Customer will be added to the root Customer Group by default */
  parentCustomerGroup?: InputMaybe<Scalars['UUID']>;
};

export type CustomerNode = {
  allOtherBucket?: Maybe<CustomerNode>;
  allOtherBucketDistributor?: Maybe<CustomerNode>;
  company: CompanyNode;
  customerAlternativeNames: Array<CustomerAlternativeNameNode>;
  customerTimeFrameByDate?: Maybe<CustomerTimeFrameNode>;
  customerTimeFrames: Array<CustomerTimeFrameNode>;
  customerType: CustomerType;
  id: Scalars['UUID'];
  liftSet: Array<LiftNode>;
  name: Scalars['String'];
  pnlGroup?: Maybe<PnlGroupNode>;
  promotionCustomer: Array<PromotionNode>;
  totalSpend?: Maybe<Scalars['Float']>;
  users: Array<UserNode>;
  visibleDistributorsByDateRange: Array<CustomerNode>;
  visibleDistributorsByDateRanges: Array<Array<CustomerNode>>;
};


export type CustomerNodeCustomerAlternativeNamesArgs = {
  filters?: InputMaybe<CustomerAlternativeNameFilter>;
};


export type CustomerNodeCustomerTimeFrameByDateArgs = {
  effectiveDate: Scalars['Date'];
};


export type CustomerNodeCustomerTimeFramesArgs = {
  filters?: InputMaybe<CustomerTimeFrameFilter>;
};


export type CustomerNodeUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};


export type CustomerNodeVisibleDistributorsByDateRangeArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  sort: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type CustomerNodeVisibleDistributorsByDateRangesArgs = {
  endDates: Array<Scalars['Date']>;
  startDates: Array<Scalars['Date']>;
};

export type CustomerNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<CustomerNode>;
};

export type CustomerOrder = {
  alternativeNames?: InputMaybe<Ordering>;
  assigned?: InputMaybe<Ordering>;
  contact?: InputMaybe<Ordering>;
  customerType?: InputMaybe<Ordering>;
  distributors?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  pnlGroup?: InputMaybe<Ordering>;
};

export type CustomerPartialInput = {
  allOtherBucketDistributor?: InputMaybe<Scalars['UUID']>;
  associatedPresetCustomer?: InputMaybe<Scalars['UUID']>;
  company?: InputMaybe<Scalars['UUID']>;
  customerAlternativeNames?: InputMaybe<Array<Scalars['UUID']>>;
  customerType?: InputMaybe<CustomerType>;
  name?: InputMaybe<Scalars['String']>;
  pnlGroup?: InputMaybe<Scalars['UUID']>;
};

export type CustomerTimeFrameDuplicationInput = {
  associatedPresetCustomer?: InputMaybe<Scalars['UUID']>;
  channel?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<Scalars['UUID']>>;
  copyFrom: Scalars['UUID'];
  customCustomerField1?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['UUID']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  distributors?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  effectiveDate: Scalars['Date'];
  name?: InputMaybe<Scalars['String']>;
  revenueSources?: InputMaybe<Array<Scalars['UUID']>>;
  weeksToRetail?: InputMaybe<Scalars['Int']>;
};

export type CustomerTimeFrameFilter = {
  AND?: InputMaybe<CustomerTimeFrameFilter>;
  NOT?: InputMaybe<CustomerTimeFrameFilter>;
  OR?: InputMaybe<CustomerTimeFrameFilter>;
  associatedPresetCustomer?: InputMaybe<PresetCustomerFilter>;
  channel?: InputMaybe<StrFilterLookup>;
  className?: InputMaybe<StrFilterLookup>;
  contacts?: InputMaybe<ContactFilter>;
  customer?: InputMaybe<CustomerFilter>;
  customerRegion?: InputMaybe<StrFilterLookup>;
  effectiveDate?: InputMaybe<DateFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  weeksToRetail?: InputMaybe<IntFilterLookup>;
};

export type CustomerTimeFrameNode = {
  associatedPresetCustomer?: Maybe<PresetCustomerNode>;
  channel?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  contacts: Array<ContactNode>;
  customCustomerField1?: Maybe<Scalars['String']>;
  customer: CustomerNode;
  customerAlternativeNames: Array<CustomerAlternativeNameNode>;
  customerNumber?: Maybe<Scalars['String']>;
  customerRegion?: Maybe<Scalars['String']>;
  customerRelationships: Array<CustomerDistributorRelationshipNode>;
  distributorRelationships: Array<CustomerDistributorRelationshipNode>;
  distributors: Array<CustomerNode>;
  effectiveDate?: Maybe<Scalars['Date']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  regionGroups: Array<RegionGroupNode>;
  revenueSources: Array<RevenueSourceNode>;
  weeksToRetail: Scalars['Int'];
};


export type CustomerTimeFrameNodeContactsArgs = {
  filters?: InputMaybe<ContactFilter>;
};


export type CustomerTimeFrameNodeCustomerAlternativeNamesArgs = {
  filters?: InputMaybe<CustomerAlternativeNameFilter>;
};


export type CustomerTimeFrameNodeDistributorsArgs = {
  filters?: InputMaybe<CustomerFilter>;
};

export type CustomerTimeFrameOrder = {
  id?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

export type CustomerTimeFramePartialInput = {
  associatedPresetCustomer?: InputMaybe<Scalars['UUID']>;
  channel?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<Scalars['UUID']>>;
  customCustomerField1?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['UUID']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  distributors?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  revenueSources?: InputMaybe<Array<Scalars['UUID']>>;
  weeksToRetail?: InputMaybe<Scalars['Int']>;
};

export type CustomerTimeFramePartialUpdateInput = {
  associatedPresetCustomer?: InputMaybe<Scalars['UUID']>;
  channel?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<Scalars['UUID']>>;
  customCustomerField1?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['UUID']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  distributors?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  revenueSources?: InputMaybe<Array<Scalars['UUID']>>;
  weeksToRetail?: InputMaybe<Scalars['Int']>;
};

export enum CustomerType {
  Aob = 'AOB',
  Distributor = 'DISTRIBUTOR',
  Retailer = 'RETAILER'
}

export type DrmEventCommentTagFilter = {
  AND?: InputMaybe<DrmEventCommentTagFilter>;
  NOT?: InputMaybe<DrmEventCommentTagFilter>;
  OR?: InputMaybe<DrmEventCommentTagFilter>;
  event?: InputMaybe<DrmEventFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  user?: InputMaybe<UserFilter>;
};

export type DrmEventCommentTagNextFilter = {
  AND?: InputMaybe<DrmEventCommentTagNextFilter>;
  NOT?: InputMaybe<DrmEventCommentTagNextFilter>;
  OR?: InputMaybe<DrmEventCommentTagNextFilter>;
  event?: InputMaybe<DrmEventNextFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  user?: InputMaybe<UserFilter>;
};

export type DrmEventCommentTagNextNode = {
  createUser: UserNode;
  createdAt: Scalars['DateTime'];
  endIndex: Scalars['Int'];
  event: DrmEventNextNode;
  id: Scalars['UUID'];
  startIndex: Scalars['Int'];
  tagType: DrmUserTagTypes;
  user: UserNode;
  viewed: Scalars['Boolean'];
};

export type DrmEventCommentTagNextNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DrmEventCommentTagNextNode>;
};

export type DrmEventCommentTagNode = {
  createUser: UserNode;
  createdAt: Scalars['DateTime'];
  endIndex: Scalars['Int'];
  event: DrmEventNode;
  id: Scalars['UUID'];
  startIndex: Scalars['Int'];
  user: UserNode;
  viewed: Scalars['Boolean'];
};

export type DrmEventCommentTagNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DrmEventCommentTagNode>;
};

export type DrmEventFilter = {
  AND?: InputMaybe<DrmEventFilter>;
  NOT?: InputMaybe<DrmEventFilter>;
  OR?: InputMaybe<DrmEventFilter>;
  comment?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createUser?: InputMaybe<UserFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  invoice?: InputMaybe<InvoiceFilter>;
  invoiceLine?: InputMaybe<InvoiceLineFilter>;
  transaction?: InputMaybe<TransactionFilter>;
  type?: InputMaybe<DeductionsReconciliationEventTypes>;
};

export type DrmEventNextFilter = {
  AND?: InputMaybe<DrmEventNextFilter>;
  NOT?: InputMaybe<DrmEventNextFilter>;
  OR?: InputMaybe<DrmEventNextFilter>;
  comment?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createUser?: InputMaybe<UserFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  invoice?: InputMaybe<InvoiceNextFilter>;
  invoiceLine?: InputMaybe<InvoiceLineNextFilter>;
  transaction?: InputMaybe<TransactionNextFilter>;
  type?: InputMaybe<StrFilterLookup>;
};

export type DrmEventNextNode = {
  amount?: Maybe<Scalars['Float']>;
  clearedAmount?: Maybe<Scalars['Float']>;
  clearedFundType?: Maybe<FundTypeNode>;
  clearedGl?: Maybe<AccountNode>;
  comment: Scalars['String'];
  company: CompanyNode;
  createUser?: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<CustomerNode>;
  fundType?: Maybe<FundTypeNode>;
  fundTypeAccount?: Maybe<AccountNode>;
  id: Scalars['UUID'];
  invoice?: Maybe<InvoiceNextNode>;
  invoiceLine?: Maybe<InvoiceLineNextNode>;
  linkedInvoiceLine?: Maybe<InvoiceLineNextNode>;
  product?: Maybe<ProductNode>;
  productGroup?: Maybe<ProductGroupNode>;
  targetUser?: Maybe<UserNode>;
  totalInvoiceLineAmount?: Maybe<Scalars['Float']>;
  transaction?: Maybe<TransactionNextNode>;
  type: DeductionsReconciliationEventNextTypes;
  userTags: Array<DrmEventCommentTagNextNode>;
  writeoffAmount?: Maybe<Scalars['Float']>;
  writeoffFundType?: Maybe<FundTypeNode>;
};

export type DrmEventNextNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DrmEventNextNode>;
};

export type DrmEventNextOrder = {
  comment?: InputMaybe<Ordering>;
};

export type DrmEventNode = {
  amount?: Maybe<Scalars['Float']>;
  clearedAmount?: Maybe<Scalars['Float']>;
  clearedFundType?: Maybe<FundTypeNode>;
  clearedGl?: Maybe<AccountNode>;
  comment: Scalars['String'];
  company: CompanyNode;
  createUser?: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<CustomerNode>;
  fundType?: Maybe<FundTypeNode>;
  fundTypeAccount?: Maybe<AccountNode>;
  id: Scalars['UUID'];
  invoice?: Maybe<InvoiceNode>;
  invoiceLine?: Maybe<InvoiceLineNode>;
  product?: Maybe<ProductNode>;
  productGroup?: Maybe<ProductGroupNode>;
  targetUser?: Maybe<UserNode>;
  totalInvoiceLineAmount?: Maybe<Scalars['Float']>;
  transaction?: Maybe<TransactionNode>;
  type: DeductionsReconciliationEventTypes;
  userTags: Array<DrmEventCommentTagNode>;
  writeoffAmount?: Maybe<Scalars['Float']>;
  writeoffFundType?: Maybe<FundTypeNode>;
};

export type DrmEventNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DrmEventNode>;
};

export enum DrmUserTagTypes {
  DisputeAssignment = 'DISPUTE_ASSIGNMENT',
  DisputeMention = 'DISPUTE_MENTION',
  SalesReviewAssignment = 'SALES_REVIEW_ASSIGNMENT',
  SalesReviewMention = 'SALES_REVIEW_MENTION',
  UserTag = 'USER_TAG'
}

export type DsmFreeTrialFilter = {
  AND?: InputMaybe<DsmFreeTrialFilter>;
  NOT?: InputMaybe<DsmFreeTrialFilter>;
  OR?: InputMaybe<DsmFreeTrialFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  maxDays?: InputMaybe<IntFilterLookup>;
  startDate?: InputMaybe<DateFilterLookup>;
};

export type DsmFreeTrialNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  maxDays: Scalars['Int'];
  startDate: Scalars['Date'];
};

export enum DashboardType {
  Deductions = 'DEDUCTIONS',
  Overview = 'OVERVIEW'
}

export type DateFilterLookup = {
  contains?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  exact?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  iContains?: InputMaybe<Scalars['Date']>;
  iEndsWith?: InputMaybe<Scalars['Date']>;
  iExact?: InputMaybe<Scalars['Date']>;
  iRegex?: InputMaybe<Scalars['String']>;
  iStartsWith?: InputMaybe<Scalars['Date']>;
  inList?: InputMaybe<Array<Scalars['Date']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  range?: InputMaybe<Array<Scalars['Date']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DatetimeFilterLookup = {
  contains?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  exact?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  iContains?: InputMaybe<Scalars['DateTime']>;
  iEndsWith?: InputMaybe<Scalars['DateTime']>;
  iExact?: InputMaybe<Scalars['DateTime']>;
  iRegex?: InputMaybe<Scalars['String']>;
  iStartsWith?: InputMaybe<Scalars['DateTime']>;
  inList?: InputMaybe<Array<Scalars['DateTime']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  range?: InputMaybe<Array<Scalars['DateTime']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export enum DaysOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DbSelectionObj = {
  /** DB config, to be provided when preset_db is OTHER */
  dbConfig?: InputMaybe<DynamicDbConfig>;
  /** Signifies any preset selected for db. Ex: prod, beta. If OTHER is selected, db_config should be provided */
  presetDb: PresetDb;
};

export enum DeductionMatchingColumnHeaders {
  Amount = 'AMOUNT',
  CustomerName = 'CUSTOMER_NAME',
  CustomerNameAcc = 'CUSTOMER_NAME_ACC',
  Date = 'DATE',
  FundTypes = 'FUND_TYPES',
  ProductName = 'PRODUCT_NAME',
  SpendRate = 'SPEND_RATE'
}

export type DeductionMatchingColumnMappingFilter = {
  AND?: InputMaybe<DeductionMatchingColumnMappingFilter>;
  NOT?: InputMaybe<DeductionMatchingColumnMappingFilter>;
  OR?: InputMaybe<DeductionMatchingColumnMappingFilter>;
  amount?: InputMaybe<StrFilterLookup>;
  createUser?: InputMaybe<Scalars['UUID']>;
  customerName?: InputMaybe<StrFilterLookup>;
  customerNameAcc?: InputMaybe<StrFilterLookup>;
  date?: InputMaybe<StrFilterLookup>;
  deductionMatchingInstance?: InputMaybe<DeductionMatchingInstanceFilter>;
  fundType?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  productName?: InputMaybe<StrFilterLookup>;
  spendRate?: InputMaybe<StrFilterLookup>;
};

export type DeductionMatchingColumnMappingNode = {
  amount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerName: Scalars['String'];
  customerNameAcc?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  deductionMatchingInstance: DeductionMatchingInstanceNode;
  fundType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  productName: Scalars['String'];
  spendRate: Scalars['String'];
};

export type DeductionMatchingColumnMappingNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DeductionMatchingColumnMappingNode>;
};

export type DeductionMatchingColumnOrderFilter = {
  AND?: InputMaybe<DeductionMatchingColumnOrderFilter>;
  NOT?: InputMaybe<DeductionMatchingColumnOrderFilter>;
  OR?: InputMaybe<DeductionMatchingColumnOrderFilter>;
  deductionMatchingInstance?: InputMaybe<DeductionMatchingInstanceFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type DeductionMatchingColumnOrderNode = {
  columnHeader: Scalars['String'];
  deductionMatchingInstance: DeductionMatchingInstanceNode;
  id: Scalars['UUID'];
};

export type DeductionMatchingColumnOrderNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DeductionMatchingColumnOrderNode>;
};

export type DeductionMatchingInstanceFilter = {
  AND?: InputMaybe<DeductionMatchingInstanceFilter>;
  NOT?: InputMaybe<DeductionMatchingInstanceFilter>;
  OR?: InputMaybe<DeductionMatchingInstanceFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  backupSourceGroup?: InputMaybe<BackupSourceGroupFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  status?: InputMaybe<StrFilterLookup>;
  uploadFile?: InputMaybe<CompanyFileFilter>;
};

export type DeductionMatchingInstanceNode = {
  backupSource?: Maybe<BackupSourceNode>;
  backupSourceGroup?: Maybe<BackupSourceGroupNode>;
  company: CompanyNode;
  createdAt: Scalars['DateTime'];
  deductionMatchingColumnMapping?: Maybe<DeductionMatchingColumnMappingNode>;
  id: Scalars['UUID'];
  resultFile?: Maybe<CompanyFileNode>;
  status: Scalars['String'];
  uploadFile: CompanyFileNode;
};

export enum DeductionMatchingInstanceStatuses {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS'
}

export type DeductionScanFileFilter = {
  AND?: InputMaybe<DeductionScanFileFilter>;
  NOT?: InputMaybe<DeductionScanFileFilter>;
  OR?: InputMaybe<DeductionScanFileFilter>;
  deductionScan?: InputMaybe<DeductionScanFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  pagesScanned?: InputMaybe<IntFilterLookup>;
  status?: InputMaybe<StrFilterLookup>;
};

export type DeductionScanFileNode = {
  convertedFile?: Maybe<CompanyFileNode>;
  deductionScan: DeductionScanNode;
  id: Scalars['UUID'];
  inputFile: CompanyFileNode;
  pagesScanned: Scalars['Int'];
  status: DeductionScanningStatus;
};

export type DeductionScanFileNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DeductionScanFileNode>;
};

export type DeductionScanFilter = {
  AND?: InputMaybe<DeductionScanFilter>;
  NOT?: InputMaybe<DeductionScanFilter>;
  OR?: InputMaybe<DeductionScanFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  status?: InputMaybe<DeductionScanningStatus>;
};

export type DeductionScanNode = {
  allScansZip?: Maybe<CompanyFileNode>;
  autoClassificationName?: Maybe<Scalars['String']>;
  backupSource?: Maybe<BackupSourceNode>;
  checkNumber?: Maybe<Scalars['String']>;
  company: CompanyNode;
  concatenatedScan?: Maybe<CompanyFileNode>;
  createUser?: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  deactivated: Scalars['Boolean'];
  id: Scalars['UUID'];
  numFilesScannedSuccessfully: Scalars['Int'];
  numPagesScannedSuccessfully: Scalars['Int'];
  status: DeductionScanningStatus;
  totalFiles: Scalars['Int'];
  totalPages: Scalars['Int'];
  uploadedFiles: Array<DeductionScanFileNode>;
};

export type DeductionScanNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<DeductionScanNode>;
};

export type DeductionScanOrder = {
  autoClassificationName?: InputMaybe<Ordering>;
  backupSource?: InputMaybe<BackupSourceOrder>;
  checkNumber?: InputMaybe<Ordering>;
  createUser?: InputMaybe<UserOrder>;
  createdAt?: InputMaybe<Ordering>;
  deactivated?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
};

export enum DeductionScanningStatus {
  Failure = 'FAILURE',
  MaybeSuccess = 'MAYBE_SUCCESS',
  PartialSuccess = 'PARTIAL_SUCCESS',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type DeductionsForPromLineResponse = {
  customProducts?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lineKey: Scalars['String'];
  productGroup?: Maybe<Scalars['String']>;
  sales?: Maybe<Scalars['Float']>;
  spend?: Maybe<Scalars['Float']>;
  spendRate?: Maybe<Scalars['Float']>;
};

export type DeductionsReconciliationEventCommentTagNextOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type DeductionsReconciliationEventCommentTagOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export enum DeductionsReconciliationEventNextTypes {
  AllInvoiceLinesDeleted = 'ALL_INVOICE_LINES_DELETED',
  FlagDispute = 'FLAG_DISPUTE',
  InvoiceAssigned = 'INVOICE_ASSIGNED',
  InvoiceCancelled = 'INVOICE_CANCELLED',
  InvoiceCreated = 'INVOICE_CREATED',
  InvoiceLinesLinked = 'INVOICE_LINES_LINKED',
  InvoiceLinesSaved = 'INVOICE_LINES_SAVED',
  InvoiceLinesUnlinked = 'INVOICE_LINES_UNLINKED',
  InvoiceLineCleared = 'INVOICE_LINE_CLEARED',
  InvoiceLineDeleted = 'INVOICE_LINE_DELETED',
  InvoiceLinePromLineAutoMatchAdded = 'INVOICE_LINE_PROM_LINE_AUTO_MATCH_ADDED',
  InvoiceLinePromLineManualMatchAdded = 'INVOICE_LINE_PROM_LINE_MANUAL_MATCH_ADDED',
  InvoiceLinePromLineUnmatched = 'INVOICE_LINE_PROM_LINE_UNMATCHED',
  InvoiceLinked = 'INVOICE_LINKED',
  InvoiceUnassigned = 'INVOICE_UNASSIGNED',
  InvoiceUnlinked = 'INVOICE_UNLINKED',
  NewCommentAdded = 'NEW_COMMENT_ADDED',
  SetSalesReview = 'SET_SALES_REVIEW',
  TransactionAdded = 'TRANSACTION_ADDED',
  TransactionAssigned = 'TRANSACTION_ASSIGNED',
  TransactionCancelled = 'TRANSACTION_CANCELLED',
  TransactionCleared = 'TRANSACTION_CLEARED',
  TransactionUnassigned = 'TRANSACTION_UNASSIGNED',
  VoidClear = 'VOID_CLEAR',
  VoidDispute = 'VOID_DISPUTE',
  VoidSalesReview = 'VOID_SALES_REVIEW',
  VoidWriteOff = 'VOID_WRITE_OFF',
  WriteOff = 'WRITE_OFF'
}

export type DeductionsReconciliationEventOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type DeductionsReconciliationEventOrderNext = {
  createdAt?: InputMaybe<Ordering>;
};

export enum DeductionsReconciliationEventTypes {
  AllInvoiceLinesDeleted = 'ALL_INVOICE_LINES_DELETED',
  FlagDispute = 'FLAG_DISPUTE',
  InvoiceAssigned = 'INVOICE_ASSIGNED',
  InvoiceCancelled = 'INVOICE_CANCELLED',
  InvoiceCreated = 'INVOICE_CREATED',
  InvoiceLinesSaved = 'INVOICE_LINES_SAVED',
  InvoiceLineCleared = 'INVOICE_LINE_CLEARED',
  InvoiceLineDeleted = 'INVOICE_LINE_DELETED',
  InvoiceLinePromLineAutoMatchAdded = 'INVOICE_LINE_PROM_LINE_AUTO_MATCH_ADDED',
  InvoiceLinePromLineManualMatchAdded = 'INVOICE_LINE_PROM_LINE_MANUAL_MATCH_ADDED',
  InvoiceLinePromLineUnmatched = 'INVOICE_LINE_PROM_LINE_UNMATCHED',
  InvoiceLinked = 'INVOICE_LINKED',
  InvoiceUnassigned = 'INVOICE_UNASSIGNED',
  InvoiceUnlinked = 'INVOICE_UNLINKED',
  NewCommentAdded = 'NEW_COMMENT_ADDED',
  SetSalesReview = 'SET_SALES_REVIEW',
  TransactionAdded = 'TRANSACTION_ADDED',
  TransactionAssigned = 'TRANSACTION_ASSIGNED',
  TransactionCancelled = 'TRANSACTION_CANCELLED',
  TransactionCleared = 'TRANSACTION_CLEARED',
  TransactionUnassigned = 'TRANSACTION_UNASSIGNED',
  WriteOff = 'WRITE_OFF'
}

export enum DefaultRoleName {
  Admin = 'ADMIN',
  Director = 'DIRECTOR',
  Manager = 'MANAGER',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type DeleteAccountReturn = {
  hasFundTypeConflict: Scalars['Boolean'];
  hasPromotionConflict: Scalars['Boolean'];
  id: Scalars['UUID'];
};

export type DeleteBackupSourceFilter = {
  AND?: InputMaybe<DeleteBackupSourceFilter>;
  NOT?: InputMaybe<DeleteBackupSourceFilter>;
  OR?: InputMaybe<DeleteBackupSourceFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type DeleteBackupSourceGroupFilter = {
  AND?: InputMaybe<DeleteBackupSourceGroupFilter>;
  NOT?: InputMaybe<DeleteBackupSourceGroupFilter>;
  OR?: InputMaybe<DeleteBackupSourceGroupFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type DeleteBackupSourceTemplateFileFilter = {
  AND?: InputMaybe<DeleteBackupSourceTemplateFileFilter>;
  NOT?: InputMaybe<DeleteBackupSourceTemplateFileFilter>;
  OR?: InputMaybe<DeleteBackupSourceTemplateFileFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type DeleteCommentInput = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type DeleteFilteredPosInput = {
  companyId: Scalars['UUID'];
  confirmDelete: Scalars['Boolean'];
  deleteAllEntries: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  revenueSources: Array<Scalars['UUID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteFilteredRevenuesInput = {
  companyId: Scalars['UUID'];
  confirmDelete: Scalars['Boolean'];
  deleteAllEntries: Scalars['Boolean'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  revenueSources: Array<Scalars['UUID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteProduct = {
  deleteProducts: DeleteProduct;
  product: ProductNode;
};


export type DeleteProductDeleteProductsArgs = {
  data: ProductDeleteInput;
};

export type DeleteProductGroup = {
  deleteProductGroups: DeleteProductGroup;
  productGroup: ProductGroupNode;
};


export type DeleteProductGroupDeleteProductGroupsArgs = {
  data: ProductGroupDeleteInput;
};

export type DeleteProductGroupProductRelationship = {
  deleteAnyway?: Maybe<Scalars['Boolean']>;
  deleteProductGroupProductRelationship: DeleteProductGroupProductRelationship;
  deleteProductGroupProductRelationships: Array<DeleteProductGroupProductRelationship>;
  product: ProductNode;
  productGroup: ProductGroupNode;
};


export type DeleteProductGroupProductRelationshipDeleteProductGroupProductRelationshipArgs = {
  data: ProductGroupProductRelationshipInput;
};


export type DeleteProductGroupProductRelationshipDeleteProductGroupProductRelationshipsArgs = {
  data: BulkProductGroupProductRelationshipInput;
};

export type DeletePromotionAttachmentsInput = {
  attachments: Array<Scalars['UUID']>;
  companyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
};

export type DeletePromotionInput = {
  id?: InputMaybe<Scalars['UUID']>;
};

export type DeletePromotionTagInput = {
  promotionTagId: Scalars['UUID'];
};

export type DeletePromotionTagReturn = {
  hasPromotionConflict: Scalars['Boolean'];
  id: Scalars['UUID'];
};

export type DiscountCutoffFilter = {
  AND?: InputMaybe<DiscountCutoffFilter>;
  NOT?: InputMaybe<DiscountCutoffFilter>;
  OR?: InputMaybe<DiscountCutoffFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type DiscountCutoffNode = {
  backupSources: Array<BackupSourceNode>;
  company: CompanyNode;
  comparison: NumberComparison;
  cutoffValue: Scalars['Float'];
  fundType: FundTypeNode;
  id: Scalars['UUID'];
};

export type DjangoModelFilterInput = {
  pk: Scalars['ID'];
};

export type DjangoModelType = {
  pk: Scalars['ID'];
};

export type DynamicDbConfig = {
  atomicRequest?: InputMaybe<Scalars['Boolean']>;
  autoCommit?: InputMaybe<Scalars['Boolean']>;
  commonHealthChecks?: InputMaybe<Scalars['Boolean']>;
  connectionMaxAge?: InputMaybe<Scalars['Int']>;
  disableServerSideCusrors?: InputMaybe<Scalars['Boolean']>;
  /** engine of the database. Ex: django.db.backends.postgresql */
  engine?: InputMaybe<Scalars['String']>;
  /** host of the database. Ex: localhost or some IP address */
  host: Scalars['String'];
  /** id of the database. Ex: prod, beta. Do not 'default' */
  id: Scalars['String'];
  /** name of the database */
  name: Scalars['String'];
  /** password of the database */
  password: Scalars['String'];
  /** port of the database */
  port?: InputMaybe<Scalars['Int']>;
  timeZone?: InputMaybe<Scalars['String']>;
  /** user of the database */
  user: Scalars['String'];
};

export type EmailData = {
  mostRecentLineAssignedToUser: MostRecentLine;
  oldestLineAssignedToUser: OldestLine;
  totalCountOfLinesAssignedToUser: Scalars['Int'];
  totalOpenAmountOfLinesAssignedToUser: Scalars['Float'];
  user: UserNode;
};

export type EmailDomainFilter = {
  AND?: InputMaybe<EmailDomainFilter>;
  NOT?: InputMaybe<EmailDomainFilter>;
  OR?: InputMaybe<EmailDomainFilter>;
  category?: InputMaybe<StrFilterLookup>;
  domain?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type EmailDomainNode = {
  category: Scalars['String'];
  domain: Scalars['String'];
  id: Scalars['UUID'];
};

export type EnumsNode = {
  backupSourceTypes: BackupSourceTypes;
  companyFileCategories: CompanyFileCategories;
  customerType: CustomerType;
  deductionMatchingColumnHeaders: DeductionMatchingColumnHeaders;
  deductionMatchingInstanceStatuses: DeductionMatchingInstanceStatuses;
  deductionScanningStatus: DeductionScanningStatus;
  defaultRoleName: DefaultRoleName;
  feature: Feature;
  featureGate: FeatureGates;
  fileType: FileTypes;
  fundTypeDateRangeChoices: FundTypeDateRangeChoices;
  fundTypeFieldChoices: FundTypeFieldChoices;
  internalFileCategories: InternalFileCategories;
  invoiceLineStatuses: InvoiceLineStatuses;
  invoiceLineTypes: InvoiceLineTypes;
  invoiceLineUploadStatuses: InvoiceLineUploadStatuses;
  invoiceStatuses: InvoiceStatuses;
  invoiceTypes: InvoiceTypes;
  invoiceUploadSurces: InvoiceUploadSources;
  months: Months;
  numberComparison: NumberComparison;
  permissionName: PermissionName;
  promotionApprovalFilterOptions: PromotionApprovalFilterOptions;
  promotionSettingChoices: PromotionSettingChoices;
  promotionStatus: PromotionStatus;
  providedService: ProvidedServices;
  resolutionActivityTypes: ResolutionActivityTypes;
  revenueColumnHeaders: RevenueColumnHeaders;
  scrapingPortal: ScrapingPortal;
  spendColumnHeaders: SpendColumnHeaders;
  tier: Tier;
  tnsactionTypes: TransactionTypes;
  transactionBackupStatuses: TransactionBackupStatuses;
  transactionStatuses: TransactionStatuses;
  userSettingsTypes: UserSettingsTypes;
};

export type ErpInvoice = {
  amount: Scalars['Float'];
  invoiceNumber: Scalars['String'];
};

export type ErpTransaction = {
  amount: Scalars['Float'];
  checkNumber: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  invoices: Array<ErpInvoice>;
  transactionId: Scalars['String'];
};

export type ErpTransactionFlat = {
  amount: Scalars['Float'];
  checkNumber: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  invoiceNumber: Scalars['String'];
  transactionId: Scalars['String'];
};

export type ErpTransactionResponse = {
  message: Scalars['String'];
  success: Scalars['Boolean'];
  transactionId: Scalars['String'];
  transactionInternalId?: Maybe<Scalars['String']>;
};

export type ErpTransactionUploadResponse = {
  transactions: Array<ErpTransactionResponse>;
};

export type ExpectedRevenueNode = {
  promotionLineTotalExpRevenues: Array<Maybe<Scalars['Float']>>;
  promotionLineWarningMessages?: Maybe<Array<Maybe<Array<ExpectedRevenueWarningMessage>>>>;
  promotionTotalExpRevenue?: Maybe<Scalars['Float']>;
  promotionWarningMessages?: Maybe<Array<ExpectedRevenueWarningMessage>>;
};

export type ExpectedRevenuePromotionLineInput = {
  customProductIds?: InputMaybe<Array<Scalars['UUID']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['UUID']>;
  productGroupId?: InputMaybe<Scalars['UUID']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  totalExpRevenue?: InputMaybe<Scalars['Float']>;
  totalExpSales?: InputMaybe<Scalars['Float']>;
};

export enum ExpectedRevenueWarningMessage {
  InvalidPricingTimeFrames = 'INVALID_PRICING_TIME_FRAMES',
  MissingDates = 'MISSING_DATES',
  MissingExpectedUnits = 'MISSING_EXPECTED_UNITS',
  OverlappingProductGroups = 'OVERLAPPING_PRODUCT_GROUPS',
  PartialExpectedRevenueCalculated = 'PARTIAL_EXPECTED_REVENUE_CALCULATED',
  ProductGroupMissingPricing = 'PRODUCT_GROUP_MISSING_PRICING',
  ProductHasNoProductGroups = 'PRODUCT_HAS_NO_PRODUCT_GROUPS'
}

export type ExportPromotionInput = {
  companyId: Scalars['UUID'];
  contains?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<Scalars['String']>;
  createUsers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  firstReceivers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  fundTypeIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  hasAttachments?: InputMaybe<Scalars['Boolean']>;
  hasContracts?: InputMaybe<Scalars['Boolean']>;
  isClosedView?: InputMaybe<Scalars['Boolean']>;
  lineClosed?: InputMaybe<Scalars['Boolean']>;
  months?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  productGroupIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  starred?: InputMaybe<Scalars['Boolean']>;
  statusIn?: InputMaybe<Array<PromotionStatus>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  years?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ExportSqlInstanceRequest = {
  database: Scalars['String'];
  instance: Scalars['String'];
  project: Scalars['String'];
};

export type ExpungeLiftReturn = {
  id: Scalars['UUID'];
};

export enum Feature {
  Api = 'API',
  Backup = 'BACKUP',
  Budget = 'BUDGET',
  BudgetV2 = 'BUDGET_V2',
  CashApp = 'CASH_APP',
  Dmm = 'DMM',
  Drm = 'DRM',
  DrmDashboard = 'DRM_DASHBOARD',
  DrmV2 = 'DRM_V2',
  Dsm = 'DSM',
  EnhancedBackups = 'ENHANCED_BACKUPS',
  Forecast = 'FORECAST',
  Hierarchy = 'HIERARCHY',
  MainDashboard = 'MAIN_DASHBOARD',
  PredictiveLift = 'PREDICTIVE_LIFT',
  PresetCustomers = 'PRESET_CUSTOMERS',
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  SalesReviewEmails = 'SALES_REVIEW_EMAILS',
  Tpm = 'TPM'
}

export enum FeatureGates {
  AllowPercentageSpendRates = 'ALLOW_PERCENTAGE_SPEND_RATES',
  AutoFillWithSensible = 'AUTO_FILL_WITH_SENSIBLE',
  BackupSourceAutoClassification = 'BACKUP_SOURCE_AUTO_CLASSIFICATION',
  CombinedSettlementAndDeductionsReport = 'COMBINED_SETTLEMENT_AND_DEDUCTIONS_REPORT',
  DeductionsSpendAutoIngest = 'DEDUCTIONS_SPEND_AUTO_INGEST',
  DrmAggregateInvoiceLines = 'DRM_AGGREGATE_INVOICE_LINES',
  DrmAutoAssign = 'DRM_AUTO_ASSIGN',
  DrmAutoMatcherV2 = 'DRM_AUTO_MATCHER_V2',
  DrmMissLog = 'DRM_MISS_LOG',
  DrmMissLogAggregation = 'DRM_MISS_LOG_AGGREGATION',
  DrmMissLogBulkResolutions = 'DRM_MISS_LOG_BULK_RESOLUTIONS',
  DrmProductGroupAutopopulate = 'DRM_PRODUCT_GROUP_AUTOPOPULATE',
  DrmQuickClear = 'DRM_QUICK_CLEAR',
  ExpandedForecastFuture = 'EXPANDED_FORECAST_FUTURE',
  Hierarchy = 'HIERARCHY',
  InvoiceHeaderTableBulkEdit = 'INVOICE_HEADER_TABLE_BULK_EDIT',
  OdrTransactionImportDate = 'ODR_TRANSACTION_IMPORT_DATE',
  PromotionActivityLog = 'PROMOTION_ACTIVITY_LOG',
  PromotionContractEnable = 'PROMOTION_CONTRACT_ENABLE',
  PromotionExpectedRevenueAndTotalUnits = 'PROMOTION_EXPECTED_REVENUE_AND_TOTAL_UNITS',
  PromotionNegativeSpendEnable = 'PROMOTION_NEGATIVE_SPEND_ENABLE',
  PromotionUploader = 'PROMOTION_UPLOADER',
  PromotionUploaderCreate = 'PROMOTION_UPLOADER_CREATE',
  PromotionUploaderUpdate = 'PROMOTION_UPLOADER_UPDATE',
  PromotionUploaderVividlyOnly = 'PROMOTION_UPLOADER_VIVIDLY_ONLY',
  PromoApprovalFilter = 'PROMO_APPROVAL_FILTER',
  Repayments = 'REPAYMENTS',
  SapSettlementReport = 'SAP_SETTLEMENT_REPORT',
  SettlementReportExpanded = 'SETTLEMENT_REPORT_EXPANDED',
  SettlementReportFilter = 'SETTLEMENT_REPORT_FILTER',
  SitewideSearch = 'SITEWIDE_SEARCH'
}

export type FieldValidationErrorType = {
  column: Scalars['String'];
  indices?: Maybe<Array<Scalars['Int']>>;
  message: Scalars['String'];
  validationFailType: Scalars['String'];
};

export type FileCategoryRelationshipFilter = {
  AND?: InputMaybe<FileCategoryRelationshipFilter>;
  NOT?: InputMaybe<FileCategoryRelationshipFilter>;
  OR?: InputMaybe<FileCategoryRelationshipFilter>;
  id?: InputMaybe<UuidFilterLookup>;
};

export type FileCategoryRelationshipNode = {
  category: CompanyFileCategories;
  file: CompanyFileNode;
  id: Scalars['UUID'];
};

export type FileTypeSystemHeader = {
  internalHeader: Scalars['String'];
  optionalWith: Array<Scalars['String']>;
  required: Scalars['Boolean'];
};

export enum FileTypes {
  Bin = 'BIN',
  Csv = 'CSV',
  Doc = 'DOC',
  Docx = 'DOCX',
  Eml = 'EML',
  Gif = 'GIF',
  Htm = 'HTM',
  Html = 'HTML',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Json = 'JSON',
  Layoutx = 'LAYOUTX',
  Msg = 'MSG',
  Osr = 'OSR',
  Osz = 'OSZ',
  Pdf = 'PDF',
  Png = 'PNG',
  Ppt = 'PPT',
  Pptx = 'PPTX',
  Rtf = 'RTF',
  Tif = 'TIF',
  Tiff = 'TIFF',
  Txt = 'TXT',
  Xls = 'XLS',
  Xlsb = 'XLSB',
  Xlsm = 'XLSM',
  Xlsx = 'XLSX',
  Zip = 'ZIP'
}

export type FloatFilterLookup = {
  contains?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  exact?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  iContains?: InputMaybe<Scalars['Float']>;
  iEndsWith?: InputMaybe<Scalars['Float']>;
  iExact?: InputMaybe<Scalars['Float']>;
  iRegex?: InputMaybe<Scalars['String']>;
  iStartsWith?: InputMaybe<Scalars['Float']>;
  inList?: InputMaybe<Array<Scalars['Float']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  range?: InputMaybe<Array<Scalars['Float']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type ForecastOverrideInput = {
  addedUnits?: InputMaybe<Scalars['Int']>;
  baseSales?: InputMaybe<Scalars['Float']>;
  companyId: Scalars['UUID'];
  customer: Scalars['UUID'];
  product: Scalars['UUID'];
  stores?: InputMaybe<Scalars['Int']>;
  velocity?: InputMaybe<Scalars['Float']>;
  version: Scalars['Int'];
  week: Scalars['Date'];
};

export type ForecastOverrideNode = {
  baseSales?: Maybe<Scalars['Float']>;
  company: CompanyNode;
  createdAt: Scalars['DateTime'];
  customer: CustomerNode;
  id: Scalars['UUID'];
  product: ProductNode;
  stores?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  velocity?: Maybe<Scalars['Float']>;
  version: Scalars['Int'];
  week: Scalars['Date'];
};

export type ForecastSeasonalityBucketFilter = {
  AND?: InputMaybe<ForecastSeasonalityBucketFilter>;
  NOT?: InputMaybe<ForecastSeasonalityBucketFilter>;
  OR?: InputMaybe<ForecastSeasonalityBucketFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type ForecastSeasonalityBucketIntroduceInput = {
  company: Scalars['UUID'];
  name: Scalars['String'];
};

export type ForecastSeasonalityBucketNode = {
  allCustomers: Scalars['Boolean'];
  allProducts: Scalars['Boolean'];
  company: CompanyNode;
  customerBucketRelationships: Array<CustomerBucketRelationshipNode>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  productBucketRelationships: Array<ProductBucketRelationshipNode>;
  products: Array<ProductNode>;
  seasonalityIndexes: Array<ForecastSeasonalityIndexNode>;
};


export type ForecastSeasonalityBucketNodeCustomerBucketRelationshipsArgs = {
  filters?: InputMaybe<CustomerBucketRelationshipFilter>;
  order?: InputMaybe<CustomerBucketRelationshipOrder>;
};


export type ForecastSeasonalityBucketNodeProductBucketRelationshipsArgs = {
  filters?: InputMaybe<ProductBucketRelationshipFilter>;
  order?: InputMaybe<ProductBucketRelationshipOrder>;
};


export type ForecastSeasonalityBucketNodeProductsArgs = {
  filters?: InputMaybe<ProductFilter>;
  order?: InputMaybe<ProductOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type ForecastSeasonalityBucketNodeSeasonalityIndexesArgs = {
  filters?: InputMaybe<ForecastSeasonalityIndexFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type ForecastSeasonalityBucketOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

export type ForecastSeasonalityIndexFilter = {
  AND?: InputMaybe<ForecastSeasonalityIndexFilter>;
  NOT?: InputMaybe<ForecastSeasonalityIndexFilter>;
  OR?: InputMaybe<ForecastSeasonalityIndexFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  seasonalityBucket?: InputMaybe<ForecastSeasonalityBucketFilter>;
};

export type ForecastSeasonalityIndexInput = {
  date: Scalars['DateTime'];
  index: Scalars['Float'];
  seasonalityBucket?: InputMaybe<Scalars['UUID']>;
};

export type ForecastSeasonalityIndexNode = {
  date: Scalars['DateTime'];
  id: Scalars['UUID'];
  index: Scalars['Float'];
  seasonalityBucket: ForecastSeasonalityBucketNode;
};

export type ForecastSeasonalityPartialInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  index?: InputMaybe<Scalars['Float']>;
  seasonalityBucket?: InputMaybe<Scalars['UUID']>;
};

export enum FundTypeDateRangeChoices {
  Buy = 'BUY',
  Scan = 'SCAN',
  Store = 'STORE'
}

export type FundTypeDateRangeChoicesReturn = {
  id: Scalars['String'];
  label: Scalars['String'];
};

export enum FundTypeFieldChoices {
  Buy = 'BUY',
  Lift = 'LIFT',
  Pricing = 'PRICING',
  Rate = 'RATE',
  Scan = 'SCAN',
  Store = 'STORE',
  Sum = 'SUM'
}

export type FundTypeFieldChoicesReturn = {
  id: Scalars['String'];
  label: Scalars['String'];
};

export type FundTypeFieldFilter = {
  AND?: InputMaybe<FundTypeFieldFilter>;
  NOT?: InputMaybe<FundTypeFieldFilter>;
  OR?: InputMaybe<FundTypeFieldFilter>;
  fundType?: InputMaybe<FundTypeFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type FundTypeFieldIntroduceInput = {
  fundType: Scalars['UUID'];
  name: Scalars['String'];
};

export type FundTypeFieldNode = {
  fundType: FundTypeNode;
  id: Scalars['UUID'];
  name: FundTypeFieldChoices;
};

export type FundTypeFilter = {
  AND?: InputMaybe<FundTypeFilter>;
  NOT?: InputMaybe<FundTypeFilter>;
  OR?: InputMaybe<FundTypeFilter>;
  account?: InputMaybe<AccountFilter>;
  company?: InputMaybe<CompanyFilter>;
  fundTypeFields?: InputMaybe<FundTypeFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  reminder?: InputMaybe<StrFilterLookup>;
  writeOffAccount?: InputMaybe<AccountFilter>;
};

export type FundTypeIntroduceInput = {
  account: Scalars['UUID'];
  company: Scalars['UUID'];
  legacyId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  reminder?: InputMaybe<Scalars['String']>;
  writeOffAccount?: InputMaybe<Scalars['UUID']>;
};

export type FundTypeNode = {
  account?: Maybe<AccountNode>;
  company: CompanyNode;
  forecastDateRange?: Maybe<Scalars['String']>;
  fundTypeFields: Array<FundTypeFieldNode>;
  id: Scalars['UUID'];
  includeInForecast: Scalars['Boolean'];
  name: Scalars['String'];
  reminder: Scalars['String'];
  writeOffAccount?: Maybe<AccountNode>;
};


export type FundTypeNodeFundTypeFieldsArgs = {
  filters?: InputMaybe<FundTypeFieldFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type FundTypeOrder = {
  account?: InputMaybe<AccountOrder>;
  name?: InputMaybe<Ordering>;
};

export type FundTypeUpdateInput = {
  account?: InputMaybe<Scalars['UUID']>;
  company?: InputMaybe<Scalars['UUID']>;
  forecastDateRange?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  includeInForecast?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  reminder?: InputMaybe<Scalars['String']>;
  writeOffAccount?: InputMaybe<Scalars['UUID']>;
};

export type GcpSqlExportResponse = {
  exportUri: Scalars['String'];
  insertTime: Scalars['String'];
  name: Scalars['String'];
  operationType: Scalars['String'];
  selfLink: Scalars['String'];
  status: Scalars['String'];
  targetId: Scalars['String'];
  targetLink: Scalars['String'];
  targetProject: Scalars['String'];
  user: Scalars['String'];
};

export type GenerateLiftPredictionsOutput = {
  predictedLift: Scalars['Float'];
  success: Scalars['Boolean'];
};

export type GenerateSignedUrlInput = {
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};

export type GroupedIndiceWarning = {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
};

export type IndividualIndiceWarning = {
  indices?: Maybe<Array<Scalars['Int']>>;
};

export type InitDbCopyInput = {
  /** name of the new company in destination DB */
  destinationCompanyName: Scalars['String'];
  /** email address to notify when the copy task is completed */
  notifyEmail?: InputMaybe<Scalars['String']>;
  /** name of the source company which is to be copied */
  sourceCompanyName: Scalars['String'];
  /** db config for source DB, from which data will be copied */
  sourceDb: DbSelectionObj;
  /** list of user emails to be added to the copied over company */
  userEmailsToConfigure?: InputMaybe<Array<Scalars['String']>>;
};

export type InitDbCopyOutput = {
  /** id of the copied over company in the destination database */
  newCompanyId?: Maybe<Scalars['UUID']>;
  status: Scalars['String'];
};

export type InitInternalDbCopyInput = {
  /** name of the new company in destination DB */
  destinationCompanyName: Scalars['String'];
};

export type IntFilterLookup = {
  contains?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  exact?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  iContains?: InputMaybe<Scalars['Int']>;
  iEndsWith?: InputMaybe<Scalars['Int']>;
  iExact?: InputMaybe<Scalars['Int']>;
  iRegex?: InputMaybe<Scalars['String']>;
  iStartsWith?: InputMaybe<Scalars['Int']>;
  inList?: InputMaybe<Array<Scalars['Int']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  range?: InputMaybe<Array<Scalars['Int']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export enum InternalFileCategories {
  BackupSourceTemplate = 'BACKUP_SOURCE_TEMPLATE',
  UploadBumpChartTemplate = 'UPLOAD_BUMP_CHART_TEMPLATE',
  UploadContactsTemplate = 'UPLOAD_CONTACTS_TEMPLATE',
  UploadCustomersTemplate = 'UPLOAD_CUSTOMERS_TEMPLATE',
  UploadErpSapTransactionsTemplate = 'UPLOAD_ERP_SAP_TRANSACTIONS_TEMPLATE',
  UploadErpTransactionsTemplate = 'UPLOAD_ERP_TRANSACTIONS_TEMPLATE',
  UploadInvoiceLinesTemplate = 'UPLOAD_INVOICE_LINES_TEMPLATE',
  UploadLatestEstimateTemplate = 'UPLOAD_LATEST_ESTIMATE_TEMPLATE',
  UploadLiftTemplate = 'UPLOAD_LIFT_TEMPLATE',
  UploadPointOfSaleTemplate = 'UPLOAD_POINT_OF_SALE_TEMPLATE',
  UploadPricingTemplate = 'UPLOAD_PRICING_TEMPLATE',
  UploadProductTemplate = 'UPLOAD_PRODUCT_TEMPLATE',
  UploadPromEditTemplate = 'UPLOAD_PROM_EDIT_TEMPLATE',
  UploadPromTemplate = 'UPLOAD_PROM_TEMPLATE',
  UploadRevenueBudgetTemplate = 'UPLOAD_REVENUE_BUDGET_TEMPLATE',
  UploadRevenueTemplate = 'UPLOAD_REVENUE_TEMPLATE',
  UploadSpendTemplate = 'UPLOAD_SPEND_TEMPLATE'
}

export type InternalFileFilter = {
  AND?: InputMaybe<InternalFileFilter>;
  NOT?: InputMaybe<InternalFileFilter>;
  OR?: InputMaybe<InternalFileFilter>;
  category?: InputMaybe<InternalFileCategories>;
  fileType?: InputMaybe<FileTypes>;
  id?: InputMaybe<UuidFilterLookup>;
  visibleName?: InputMaybe<StrFilterLookup>;
};

export type InternalFileNode = {
  backupSources: Array<BackupSourceNode>;
  category: InternalFileCategories;
  customerVisible: Scalars['Boolean'];
  fileData: Scalars['Base64'];
  fileType: FileTypes;
  id: Scalars['UUID'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
  visibleName: Scalars['String'];
};

export type InternalFileNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InternalFileNode>;
};

export type IntroduceBackupFileInput = {
  amount?: InputMaybe<Scalars['Float']>;
  backupSource?: InputMaybe<Scalars['UUID']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  date?: InputMaybe<Scalars['Date']>;
  fileId: Scalars['UUID'];
  fundType?: InputMaybe<Array<Scalars['UUID']>>;
  needsInternalApproval?: InputMaybe<Scalars['Boolean']>;
};

export type IntroduceBackupFileInputForManualAdd = {
  amount?: InputMaybe<Scalars['Float']>;
  backupSource?: InputMaybe<Scalars['UUID']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  date?: InputMaybe<Scalars['Date']>;
  fileId?: InputMaybe<Scalars['UUID']>;
  fundType?: InputMaybe<Array<Scalars['UUID']>>;
  name?: InputMaybe<Scalars['String']>;
  needsInternalApproval?: InputMaybe<Scalars['Boolean']>;
};

export type IntroduceBackupFileNode = {
  backup?: Maybe<BackupFileNode>;
};

export type IntroduceBackupRetrievalInput = {
  company: Scalars['UUID'];
  customer: Scalars['UUID'];
  isDeductionPositive: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
  portal: ScrapingPortal;
  username: Scalars['String'];
};

export type IntroduceCompanyFileError = {
  errorMessage: Scalars['String'];
};

export type IntroduceCompanyFileNode = {
  companyFile?: Maybe<CompanyFileNode>;
  error?: Maybe<IntroduceCompanyFileError>;
};

export type IntroduceDrmEventCommentTagInput = {
  endIndex: Scalars['Int'];
  event: Scalars['UUID'];
  startIndex: Scalars['Int'];
  user: Scalars['UUID'];
};

export type IntroduceDrmEventCommentTagNextInput = {
  endIndex: Scalars['Int'];
  event: Scalars['UUID'];
  startIndex: Scalars['Int'];
  tagType?: InputMaybe<DrmUserTagTypes>;
  user: Scalars['UUID'];
};

export type IntroduceDrmEventInput = {
  amount?: InputMaybe<Scalars['Float']>;
  clearedAmount?: InputMaybe<Scalars['Float']>;
  clearedFundType?: InputMaybe<Scalars['UUID']>;
  clearedGl?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<Scalars['String']>;
  company: Scalars['UUID'];
  customer?: InputMaybe<Scalars['UUID']>;
  fundType?: InputMaybe<Scalars['UUID']>;
  fundTypeAccount?: InputMaybe<Scalars['UUID']>;
  invoice?: InputMaybe<Scalars['UUID']>;
  invoiceLine?: InputMaybe<Scalars['UUID']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  totalInvoiceLineAmount?: InputMaybe<Scalars['Float']>;
  transaction?: InputMaybe<Scalars['UUID']>;
  type: DeductionsReconciliationEventTypes;
  writeoffAmount?: InputMaybe<Scalars['Float']>;
  writeoffFundType?: InputMaybe<Scalars['UUID']>;
};

export type IntroduceDrmEventNextInput = {
  amount?: InputMaybe<Scalars['Float']>;
  clearedAmount?: InputMaybe<Scalars['Float']>;
  clearedFundType?: InputMaybe<Scalars['UUID']>;
  clearedGl?: InputMaybe<Scalars['UUID']>;
  comment?: InputMaybe<Scalars['String']>;
  company: Scalars['UUID'];
  customer?: InputMaybe<Scalars['UUID']>;
  fundType?: InputMaybe<Scalars['UUID']>;
  fundTypeAccount?: InputMaybe<Scalars['UUID']>;
  invoice?: InputMaybe<Scalars['UUID']>;
  invoiceLine?: InputMaybe<Scalars['UUID']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  totalInvoiceLineAmount?: InputMaybe<Scalars['Float']>;
  transaction?: InputMaybe<Scalars['UUID']>;
  type: DeductionsReconciliationEventNextTypes;
  writeoffAmount?: InputMaybe<Scalars['Float']>;
  writeoffFundType?: InputMaybe<Scalars['UUID']>;
};

export type IntroduceDsmFreeTrialInput = {
  company: Scalars['UUID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  maxDays: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type IntroduceEmailDomainInput = {
  category?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
};

export type IntroduceInvoiceError = {
  errorMessage: Scalars['String'];
};

export type IntroduceInvoiceNextError = {
  errorMessage: Scalars['String'];
};

export type IntroduceInvoiceNextNode = {
  error?: Maybe<IntroduceInvoiceNextError>;
  invoice?: Maybe<InvoiceNextNode>;
};

export type IntroduceInvoiceNode = {
  error?: Maybe<IntroduceInvoiceError>;
  invoice?: Maybe<InvoiceNode>;
};

export type IntroducePassThroughTypesInput = {
  company: Scalars['UUID'];
  fundTypes: Array<Scalars['UUID']>;
};

export type IntroduceRevenueSourceInput = {
  company: Scalars['UUID'];
  isAccountingSource?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type IntroduceTransactionIssue = {
  errorMessage: Scalars['String'];
  issueType: TransactionIssueType;
};

export type IntroduceTransactionNextIssue = {
  errorMessage: Scalars['String'];
  issueType: TransactionNextIssueType;
};

export type IntroduceTransactionNextNode = {
  error?: Maybe<IntroduceTransactionNextIssue>;
  transaction?: Maybe<TransactionNextNode>;
};

export type IntroduceTransactionNode = {
  error?: Maybe<IntroduceTransactionIssue>;
  transaction?: Maybe<TransactionNode>;
};

export type InvoiceAmountFilterRangeNode = {
  maxOpenAmount: Scalars['Float'];
  maxSignedAmount: Scalars['Float'];
  minOpenAmount: Scalars['Float'];
  minSignedAmount: Scalars['Float'];
};

export type InvoiceAmountFilterRangeNodeNext = {
  maxOpenAmount: Scalars['Float'];
  maxSignedAmount: Scalars['Float'];
  minOpenAmount: Scalars['Float'];
  minSignedAmount: Scalars['Float'];
};

export type InvoiceFilter = {
  AND?: InputMaybe<InvoiceFilter>;
  NOT?: InputMaybe<InvoiceFilter>;
  OR?: InputMaybe<InvoiceFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  assignedUsers?: InputMaybe<UserFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  customerName?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<DateFilterLookup>;
  hasAtLeastOneLinkedTransaction?: InputMaybe<Scalars['Boolean']>;
  hasResolutionHistory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  id_NotIn?: InputMaybe<Array<Scalars['UUID']>>;
  invoiceNumber?: InputMaybe<StrFilterLookup>;
  invoiceType?: InputMaybe<StrFilterLookup>;
  invoiceType_In?: InputMaybe<Array<InvoiceTypes>>;
  linkedTransactions?: InputMaybe<TransactionFilter>;
  needsInternalApproval?: InputMaybe<Scalars['Boolean']>;
  openAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  originalFile?: InputMaybe<CompanyFileFilter>;
  signedAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  sortAssignedUserNames?: InputMaybe<Ordering>;
  sortHasAtLeastOneLinkedTransaction?: InputMaybe<Ordering>;
  sortOpenAmount?: InputMaybe<Ordering>;
  sortSignedAmount?: InputMaybe<Ordering>;
  startDate?: InputMaybe<DateFilterLookup>;
  status?: InputMaybe<StrFilterLookup>;
  status_In?: InputMaybe<Array<InvoiceStatuses>>;
  status_Not?: InputMaybe<InvoiceStatuses>;
  suggestedFundTypes?: InputMaybe<FundTypeFilter>;
  typeName?: InputMaybe<Scalars['String']>;
  uploadSource?: InputMaybe<StrFilterLookup>;
  uploadSourceName?: InputMaybe<Scalars['String']>;
  userSearch?: InputMaybe<Scalars['String']>;
};

export type InvoiceIntroduceInput = {
  amount: Scalars['Float'];
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  backupSource?: InputMaybe<Scalars['UUID']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['UUID']>;
  endDate: Scalars['Date'];
  endPage?: InputMaybe<Scalars['Int']>;
  invoiceNumber: Scalars['String'];
  invoiceType?: InputMaybe<InvoiceTypes>;
  linkedTransactions?: InputMaybe<Array<Scalars['UUID']>>;
  needsInternalApproval?: InputMaybe<Scalars['Boolean']>;
  originalFile?: InputMaybe<Scalars['UUID']>;
  signedAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['Date']>;
  startPage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InvoiceStatuses>;
  suggestedFundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  uploadSource: InvoiceUploadSources;
};

export type InvoiceLineColumnMappingFilter = {
  AND?: InputMaybe<InvoiceLineColumnMappingFilter>;
  NOT?: InputMaybe<InvoiceLineColumnMappingFilter>;
  OR?: InputMaybe<InvoiceLineColumnMappingFilter>;
  amount?: InputMaybe<StrFilterLookup>;
  customerName?: InputMaybe<StrFilterLookup>;
  date?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  invoiceLineUploadFile?: InputMaybe<InvoiceLineUploadFileFilter>;
  productName?: InputMaybe<StrFilterLookup>;
  spendRate?: InputMaybe<StrFilterLookup>;
  spendRatePct?: InputMaybe<StrFilterLookup>;
};

export type InvoiceLineColumnMappingNode = {
  allHeaders: Array<Scalars['String']>;
  amount: Scalars['String'];
  company?: Maybe<CompanyNode>;
  customerName: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['UUID'];
  invoiceLineUploadFile?: Maybe<InvoiceLineUploadFileNode>;
  productName: Scalars['String'];
  spendRate: Scalars['String'];
  spendRatePct: Scalars['String'];
};

export type InvoiceLineColumnMappingNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceLineColumnMappingNode>;
};

export type InvoiceLineColumnMappingOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type InvoiceLineFilter = {
  AND?: InputMaybe<InvoiceLineFilter>;
  NOT?: InputMaybe<InvoiceLineFilter>;
  OR?: InputMaybe<InvoiceLineFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DateFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  index?: InputMaybe<IntFilterLookup>;
  invoice?: InputMaybe<InvoiceFilter>;
  invoiceLineType?: InputMaybe<InvoiceLineTypes>;
  matchedPromotionLine?: InputMaybe<PromotionLineFilter>;
  product?: InputMaybe<ProductFilter>;
  productGroup?: InputMaybe<ProductGroupFilter>;
  sortFundTypeName?: InputMaybe<Ordering>;
  sortOpenAmount?: InputMaybe<Ordering>;
  sortSignedAmount?: InputMaybe<Ordering>;
  spendRate?: InputMaybe<FloatFilterLookup>;
  spendRatePct?: InputMaybe<FloatFilterLookup>;
  status?: InputMaybe<InvoiceLineStatuses>;
  suggestedFundType?: InputMaybe<FundTypeFilter>;
};

export enum InvoiceLineFlags {
  Dispute = 'DISPUTE',
  SalesReview = 'SALES_REVIEW'
}

export type InvoiceLineIntroduceInput = {
  amount: Scalars['Float'];
  customer?: InputMaybe<Scalars['UUID']>;
  date?: InputMaybe<Scalars['DateTime']>;
  index: Scalars['Int'];
  invoice: Scalars['UUID'];
  invoiceLineType?: InputMaybe<InvoiceLineTypes>;
  matchedPromotionLine?: InputMaybe<Scalars['UUID']>;
  matchedPromotionLineKey?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  spendRate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<InvoiceLineStatuses>;
  suggestedFundType?: InputMaybe<Scalars['UUID']>;
};

export type InvoiceLineMappedFileResponse = {
  columnMapping: InvoiceLineColumnMappingNode;
  errorMessage?: Maybe<Scalars['String']>;
  fileData: Array<InvoiceLineMappedRow>;
};

export type InvoiceLineMappedRow = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  errors: Array<InvoiceLinesUploadErrorEnum>;
  productName?: Maybe<Scalars['String']>;
  spendRate?: Maybe<Scalars['String']>;
  spendRatePct?: Maybe<Scalars['String']>;
  warnings: Array<InvoiceLinesUploadWarningEnum>;
};

export type InvoiceLineNextBatchIntroduceInput = {
  invoiceId: Scalars['UUID'];
  newInvoiceLinesCount: Scalars['Int'];
};

export type InvoiceLineNextBatchIntroduceResponse = {
  error?: Maybe<Scalars['String']>;
  invoiceLines: Array<InvoiceLineNextNode>;
};

export type InvoiceLineNextColumnMappingNode = {
  allHeaders: Array<Scalars['String']>;
  amount: Scalars['String'];
  company?: Maybe<CompanyNode>;
  customerName: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['UUID'];
  invoiceLineUploadFile?: Maybe<InvoiceLineNextUploadFileNode>;
  productName: Scalars['String'];
  spendRate: Scalars['String'];
  spendRatePct: Scalars['String'];
};

export type InvoiceLineNextFilter = {
  AND?: InputMaybe<InvoiceLineNextFilter>;
  NOT?: InputMaybe<InvoiceLineNextFilter>;
  OR?: InputMaybe<InvoiceLineNextFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  assignedUsers?: InputMaybe<UserFilter>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DateFilterLookup>;
  flagActivity?: InputMaybe<DrmEventNextFilter>;
  flagReporter?: InputMaybe<UserFilter>;
  flagged?: InputMaybe<StrFilterLookup>;
  flagged_In?: InputMaybe<Array<InvoiceLineFlags>>;
  hasActiveResolutionHistory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  index?: InputMaybe<IntFilterLookup>;
  invoice?: InputMaybe<InvoiceNextFilter>;
  invoiceLineType?: InputMaybe<InvoiceLineTypes>;
  invoice_InvoiceNumber?: InputMaybe<Scalars['String']>;
  invoice_Transaction_CheckNumber?: InputMaybe<Scalars['String']>;
  matchedPromotionLine?: InputMaybe<PromotionLineFilter>;
  openAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  product?: InputMaybe<ProductFilter>;
  productGroup?: InputMaybe<ProductGroupFilter>;
  signedAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  sortFundTypeName?: InputMaybe<Ordering>;
  sortOpenAmount?: InputMaybe<Ordering>;
  sortSignedAmount?: InputMaybe<Ordering>;
  spendRate?: InputMaybe<FloatFilterLookup>;
  spendRatePct?: InputMaybe<FloatFilterLookup>;
  spendRatePctRange?: InputMaybe<Array<Scalars['Float']>>;
  spendRateRange?: InputMaybe<Array<Scalars['Float']>>;
  status?: InputMaybe<InvoiceLineNextStatuses>;
  suggestedFundType?: InputMaybe<FundTypeFilter>;
};

export type InvoiceLineNextMappedFileResponse = {
  columnMapping: InvoiceLineNextColumnMappingNode;
  errorMessage?: Maybe<Scalars['String']>;
  fileData: Array<InvoiceLineNextMappedRow>;
};

export type InvoiceLineNextMappedRow = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  errors: Array<InvoiceLinesNextUploadErrorEnum>;
  productName?: Maybe<Scalars['String']>;
  spendRate?: Maybe<Scalars['String']>;
  spendRatePct?: Maybe<Scalars['String']>;
  warnings: Array<InvoiceLinesNextUploadWarningEnum>;
};

export type InvoiceLineNextNode = {
  allowManualMatch: Scalars['Boolean'];
  amount: Scalars['Float'];
  appliedResolutionActivities: Array<ResolutionActivityNextNode>;
  assignedUsers?: Maybe<Array<UserNode>>;
  cancelled: Scalars['Boolean'];
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerNode>;
  date?: Maybe<Scalars['Date']>;
  flagActivity?: Maybe<DrmEventNextNode>;
  flagReporter?: Maybe<UserNode>;
  flagged?: Maybe<InvoiceLineFlags>;
  fundTypeName?: Maybe<Scalars['String']>;
  hasActiveResolutionHistory: Scalars['Boolean'];
  hasResolutionHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  index: Scalars['Int'];
  invoice: InvoiceNextNode;
  invoiceLineType: InvoiceLineTypes;
  linkedInvoiceLineId?: Maybe<Scalars['UUID']>;
  matchedPromotionLine?: Maybe<PromotionLineNode>;
  openAmount: Scalars['Float'];
  product?: Maybe<ProductNode>;
  productGroup?: Maybe<ProductGroupNode>;
  signedAmount: Scalars['Float'];
  spendRate?: Maybe<Scalars['Float']>;
  spendRatePct?: Maybe<Scalars['Float']>;
  status: InvoiceLineNextStatuses;
  suggestedFundType?: Maybe<FundTypeNode>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type InvoiceLineNextNodeAssignedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};

export type InvoiceLineNextNodeInvoiceLinePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceLineNextNode>;
  summary: InvoiceLineSummaryNext;
};

export type InvoiceLineNextNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceLineNextNode>;
};

export type InvoiceLineNextOrder = {
  assignedUsers?: InputMaybe<UserOrder>;
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  date?: InputMaybe<Ordering>;
  flagActivity?: InputMaybe<DrmEventNextOrder>;
  flagReporter?: InputMaybe<UserOrder>;
  flagged?: InputMaybe<Ordering>;
  index?: InputMaybe<Ordering>;
  invoice?: InputMaybe<InvoiceNextOrder>;
  invoiceLineType?: InputMaybe<Ordering>;
  invoice_CreatedAt?: InputMaybe<Ordering>;
  invoice_InvoiceNumber?: InputMaybe<Ordering>;
  invoice_Transaction_CheckNumber?: InputMaybe<Ordering>;
  invoice_Transaction_Date?: InputMaybe<Ordering>;
  matchedPromotionLine?: InputMaybe<PromotionLineOrder>;
  openAmount?: InputMaybe<Ordering>;
  product?: InputMaybe<ProductOrder>;
  productGroup?: InputMaybe<ProductGroupOrder>;
  signedAmount?: InputMaybe<Ordering>;
  spendRate?: InputMaybe<Ordering>;
  spendRatePct?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  suggestedFundType?: InputMaybe<FundTypeOrder>;
};

export enum InvoiceLineNextStatuses {
  Cleared = 'CLEARED',
  MatchConfirmed = 'MATCH_CONFIRMED',
  MatchNotFound = 'MATCH_NOT_FOUND',
  New = 'NEW',
  Pending = 'PENDING'
}

export type InvoiceLineNextUploadFileNode = {
  file: CompanyFileNode;
  id: Scalars['UUID'];
  invoice: InvoiceNextNode;
  invoiceLineColumnMapping: InvoiceLineNextColumnMappingNode;
};

export type InvoiceLineNode = {
  allowManualMatch: Scalars['Boolean'];
  amount: Scalars['Float'];
  appliedResolutionActivities: Array<ResolutionActivityNode>;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerNode>;
  date?: Maybe<Scalars['Date']>;
  fundTypeName?: Maybe<Scalars['String']>;
  hasResolutionHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  index: Scalars['Int'];
  invoice: InvoiceNode;
  invoiceLineType: InvoiceLineTypes;
  matchedPromotionLine?: Maybe<PromotionLineNode>;
  openAmount: Scalars['Float'];
  product?: Maybe<ProductNode>;
  productGroup?: Maybe<ProductGroupNode>;
  signedAmount: Scalars['Float'];
  spendRate?: Maybe<Scalars['Float']>;
  spendRatePct?: Maybe<Scalars['Float']>;
  status: InvoiceLineStatuses;
  suggestedFundType?: Maybe<FundTypeNode>;
};

export type InvoiceLineNodeInvoiceLinePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceLineNode>;
  summary: InvoiceLineSummary;
};

export type InvoiceLineOrder = {
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  date?: InputMaybe<Ordering>;
  index?: InputMaybe<Ordering>;
  invoiceLineType?: InputMaybe<Ordering>;
  matchedPromotionLine?: InputMaybe<PromotionLineOrder>;
  product?: InputMaybe<ProductOrder>;
  spendRate?: InputMaybe<Ordering>;
  spendRatePct?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
};

export type InvoiceLineReviseInput = {
  amount?: InputMaybe<Scalars['Float']>;
  customer?: InputMaybe<Scalars['UUID']>;
  date?: InputMaybe<Scalars['DateTime']>;
  index?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['UUID']>;
  invoiceLineType?: InputMaybe<InvoiceLineTypes>;
  matchedPromotionLine?: InputMaybe<Scalars['UUID']>;
  matchedPromotionLineKey?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  spendRate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<InvoiceLineStatuses>;
  suggestedFundType?: InputMaybe<Scalars['UUID']>;
};

export enum InvoiceLineStatuses {
  Cleared = 'CLEARED',
  Dispute = 'DISPUTE',
  MatchConfirmed = 'MATCH_CONFIRMED',
  MatchNotFound = 'MATCH_NOT_FOUND',
  New = 'NEW',
  Pending = 'PENDING',
  SalesReview = 'SALES_REVIEW'
}

export type InvoiceLineSummary = {
  allInvoiceLineIds: Array<Scalars['UUID']>;
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export type InvoiceLineSummaryNext = {
  allInvoiceLineIds: Array<Scalars['UUID']>;
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export enum InvoiceLineTypes {
  DeductionBillpay = 'DEDUCTION_BILLPAY',
  Repayment = 'REPAYMENT'
}

export type InvoiceLineUploadFileFilter = {
  AND?: InputMaybe<InvoiceLineUploadFileFilter>;
  NOT?: InputMaybe<InvoiceLineUploadFileFilter>;
  OR?: InputMaybe<InvoiceLineUploadFileFilter>;
  file?: InputMaybe<CompanyFileFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  invoice?: InputMaybe<InvoiceFilter>;
  invoiceLineColumnMapping?: InputMaybe<InvoiceLineColumnMappingFilter>;
};

export type InvoiceLineUploadFileNode = {
  file: CompanyFileNode;
  id: Scalars['UUID'];
  invoice: InvoiceNode;
  invoiceLineColumnMapping: InvoiceLineColumnMappingNode;
};

export type InvoiceLineUploadFileNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceLineUploadFileNode>;
};

export type InvoiceLineUploadFileOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export enum InvoiceLineUploadStatuses {
  AutoMatched = 'AUTO_MATCHED',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export type InvoiceLinesIntroduceInput = {
  amount: Scalars['Float'];
  customer?: InputMaybe<Scalars['UUID']>;
  date?: InputMaybe<Scalars['DateTime']>;
  index?: InputMaybe<Scalars['Int']>;
  invoice: Scalars['UUID'];
  invoiceLineType?: InputMaybe<InvoiceLineTypes>;
  matchedPromotionLine?: InputMaybe<Scalars['UUID']>;
  matchedPromotionLineKey?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  spendRate?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<InvoiceLineStatuses>;
  suggestedFundType?: InputMaybe<Scalars['UUID']>;
};

export enum InvoiceLinesNextUploadErrorEnum {
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  EmptyAmount = 'EMPTY_AMOUNT',
  EmptyCustomerName = 'EMPTY_CUSTOMER_NAME',
  EmptyDate = 'EMPTY_DATE',
  InvalidAmounts = 'INVALID_AMOUNTS',
  InvalidDates = 'INVALID_DATES',
  InvalidSpendRates = 'INVALID_SPEND_RATES',
  InvalidSpendRatePcts = 'INVALID_SPEND_RATE_PCTS'
}

export enum InvoiceLinesNextUploadWarningEnum {
  EmptyProductName = 'EMPTY_PRODUCT_NAME',
  MultipleCustomersMatched = 'MULTIPLE_CUSTOMERS_MATCHED',
  MultipleProductsMatched = 'MULTIPLE_PRODUCTS_MATCHED',
  ProductNotFound = 'PRODUCT_NOT_FOUND'
}

export enum InvoiceLinesUploadErrorEnum {
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  EmptyAmount = 'EMPTY_AMOUNT',
  EmptyCustomerName = 'EMPTY_CUSTOMER_NAME',
  EmptyDate = 'EMPTY_DATE',
  InvalidAmounts = 'INVALID_AMOUNTS',
  InvalidDates = 'INVALID_DATES'
}

export enum InvoiceLinesUploadWarningEnum {
  EmptyProductName = 'EMPTY_PRODUCT_NAME',
  ProductNotFound = 'PRODUCT_NOT_FOUND'
}

export type InvoiceNextFilter = {
  AND?: InputMaybe<InvoiceNextFilter>;
  NOT?: InputMaybe<InvoiceNextFilter>;
  OR?: InputMaybe<InvoiceNextFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  customerName?: InputMaybe<Scalars['String']>;
  drmV1Id?: InputMaybe<StrFilterLookup>;
  endDate?: InputMaybe<DateFilterLookup>;
  hasResolutionHistory?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  id_NotIn?: InputMaybe<Array<Scalars['UUID']>>;
  invoiceNumber?: InputMaybe<StrFilterLookup>;
  invoiceType?: InputMaybe<StrFilterLookup>;
  invoiceType_In?: InputMaybe<Array<InvoiceTypes>>;
  openAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  signedAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  sortAssignedUserNames?: InputMaybe<Ordering>;
  sortOpenAmount?: InputMaybe<Ordering>;
  sortSignedAmount?: InputMaybe<Ordering>;
  sortStatus?: InputMaybe<Ordering>;
  status?: InputMaybe<Scalars['String']>;
  suggestedFundTypes?: InputMaybe<FundTypeFilter>;
  transaction?: InputMaybe<TransactionNextFilter>;
  typeName?: InputMaybe<Scalars['String']>;
};

export type InvoiceNextNode = {
  amount: Scalars['Float'];
  appliedResolutionActivities: Array<ResolutionActivityNextNode>;
  backupFile?: Maybe<BackupFileNode>;
  backupSource?: Maybe<BackupSourceNode>;
  bulkScanIncomplete: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  checkNumber?: Maybe<Scalars['String']>;
  confidenceThreshold?: Maybe<Scalars['Int']>;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerNode>;
  drmV1Id?: Maybe<Scalars['String']>;
  drmV1InvoiceInvoiceNumber?: Maybe<Scalars['String']>;
  drmV1TransactionInvoiceNumber?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  endPage?: Maybe<Scalars['Int']>;
  enforceSourceMatch: Scalars['Boolean'];
  hasActiveResolutionHistory: Scalars['Boolean'];
  hasInvoiceLines: Scalars['Boolean'];
  hasResolutionHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  invoiceLineCount: Scalars['Int'];
  invoiceLines: Array<InvoiceLineNextNode>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceType: InvoiceTypes;
  openAmount: Scalars['Float'];
  scanResultFiles: Array<InvoiceNextScanResultFileNode>;
  signedAmount: Scalars['Float'];
  startPage?: Maybe<Scalars['Int']>;
  status: InvoiceStatuses;
  suggestedFundTypes: Array<FundTypeNode>;
  transaction: TransactionNextNode;
};


export type InvoiceNextNodeInvoiceLinesArgs = {
  filters?: InputMaybe<InvoiceLineNextFilter>;
};


export type InvoiceNextNodeScanResultFilesArgs = {
  filters?: InputMaybe<InvoiceNextScanResultFileFilter>;
};


export type InvoiceNextNodeSuggestedFundTypesArgs = {
  filters?: InputMaybe<FundTypeFilter>;
};

export type InvoiceNextNodeInvoicePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceNextNode>;
  summary: InvoiceNextSummary;
};

export type InvoiceNextOrder = {
  amount?: InputMaybe<Ordering>;
  backupSource?: InputMaybe<BackupSourceOrder>;
  checkNumber?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  endDate?: InputMaybe<Ordering>;
  invoiceNumber?: InputMaybe<Ordering>;
  invoiceType?: InputMaybe<Ordering>;
  needsInternalApproval?: InputMaybe<Ordering>;
  openAmount?: InputMaybe<Ordering>;
  originalFile?: InputMaybe<CompanyFileOrder>;
  signedAmount?: InputMaybe<Ordering>;
  startDate?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  transaction?: InputMaybe<TransactionNextOrder>;
  uploadSource?: InputMaybe<Ordering>;
};

export type InvoiceNextScanResultFileFilter = {
  AND?: InputMaybe<InvoiceNextScanResultFileFilter>;
  NOT?: InputMaybe<InvoiceNextScanResultFileFilter>;
  OR?: InputMaybe<InvoiceNextScanResultFileFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  file?: InputMaybe<CompanyFileFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  invoice?: InputMaybe<InvoiceNextFilter>;
};

export type InvoiceNextScanResultFileNode = {
  backupSource: BackupSourceNode;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  file: CompanyFileNode;
  id: Scalars['UUID'];
  invoice: InvoiceNextNode;
};

export type InvoiceNextSummary = {
  allInvoiceIds: Array<Scalars['UUID']>;
  ineligibleInvoiceIds: Array<Scalars['UUID']>;
  invalidBackupInvoiceIds: Array<Scalars['UUID']>;
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export type InvoiceNode = {
  amount: Scalars['Float'];
  appliedResolutionActivities: Array<ResolutionActivityNode>;
  assignedUsers: Array<UserNode>;
  backupSource?: Maybe<BackupSourceNode>;
  checkNumber?: Maybe<Scalars['String']>;
  company: CompanyNode;
  confidenceThreshold?: Maybe<Scalars['Int']>;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerNode>;
  endDate?: Maybe<Scalars['Date']>;
  endPage?: Maybe<Scalars['Int']>;
  enforceSourceMatch: Scalars['Boolean'];
  hasAtLeastOneLinkedTransaction: Scalars['Boolean'];
  hasInvoiceLines: Scalars['Boolean'];
  hasResolutionHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  invoiceLineCount: Scalars['Int'];
  invoiceLineUploadFiles: Array<InvoiceLineUploadFileNode>;
  invoiceLines: Array<InvoiceLineNode>;
  invoiceNumber: Scalars['String'];
  invoiceType: InvoiceTypes;
  linkedTransactions: Array<TransactionNode>;
  needsInternalApproval: Scalars['Boolean'];
  openAmount: Scalars['Float'];
  originalFile?: Maybe<CompanyFileNode>;
  scanResultFiles: Array<InvoiceScanResultFileNode>;
  signedAmount: Scalars['Float'];
  startDate?: Maybe<Scalars['Date']>;
  startPage?: Maybe<Scalars['Int']>;
  status: InvoiceStatuses;
  suggestedFundTypes: Array<FundTypeNode>;
  uploadSource: InvoiceUploadSources;
};


export type InvoiceNodeAssignedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};


export type InvoiceNodeInvoiceLineUploadFilesArgs = {
  filters?: InputMaybe<InvoiceLineUploadFileFilter>;
};


export type InvoiceNodeInvoiceLinesArgs = {
  filters?: InputMaybe<InvoiceLineFilter>;
};


export type InvoiceNodeLinkedTransactionsArgs = {
  filters?: InputMaybe<TransactionFilter>;
};


export type InvoiceNodeScanResultFilesArgs = {
  filters?: InputMaybe<InvoiceScanResultFileFilter>;
};


export type InvoiceNodeSuggestedFundTypesArgs = {
  filters?: InputMaybe<FundTypeFilter>;
};

export type InvoiceNodeInvoicePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceNode>;
  summary: InvoiceSummary;
};

export type InvoiceOrder = {
  amount?: InputMaybe<Ordering>;
  backupSource?: InputMaybe<BackupSourceOrder>;
  checkNumber?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  endDate?: InputMaybe<Ordering>;
  hasAtLeastOneLinkedTransaction?: InputMaybe<Ordering>;
  invoiceNumber?: InputMaybe<Ordering>;
  invoiceType?: InputMaybe<Ordering>;
  needsInternalApproval?: InputMaybe<Ordering>;
  originalFile?: InputMaybe<CompanyFileOrder>;
  startDate?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  uploadSource?: InputMaybe<Ordering>;
};

export type InvoiceReviseInput = {
  amount?: InputMaybe<Scalars['Float']>;
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  backupSource?: InputMaybe<Scalars['UUID']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['UUID']>;
  customer?: InputMaybe<Scalars['UUID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  endPage?: InputMaybe<Scalars['Int']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<InvoiceTypes>;
  linkedTransactions?: InputMaybe<Array<Scalars['UUID']>>;
  needsInternalApproval?: InputMaybe<Scalars['Boolean']>;
  originalFile?: InputMaybe<Scalars['UUID']>;
  signedAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['Date']>;
  startPage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<InvoiceStatuses>;
  suggestedFundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  uploadSource?: InputMaybe<InvoiceUploadSources>;
};

export type InvoiceReviseInputNext = {
  amount?: InputMaybe<Scalars['Float']>;
  backupSource?: InputMaybe<Scalars['UUID']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['UUID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  endPage?: InputMaybe<Scalars['Int']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  invoiceType?: InputMaybe<InvoiceTypes>;
  originalFile?: InputMaybe<Scalars['UUID']>;
  signedAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['Date']>;
  startPage?: InputMaybe<Scalars['Int']>;
  suggestedFundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  transactionId?: InputMaybe<Scalars['UUID']>;
};

export type InvoiceScanResultFileFilter = {
  AND?: InputMaybe<InvoiceScanResultFileFilter>;
  NOT?: InputMaybe<InvoiceScanResultFileFilter>;
  OR?: InputMaybe<InvoiceScanResultFileFilter>;
  backupSource?: InputMaybe<BackupSourceFilter>;
  file?: InputMaybe<CompanyFileFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  invoice?: InputMaybe<InvoiceFilter>;
};

export type InvoiceScanResultFileNode = {
  backupSource: BackupSourceNode;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  file: CompanyFileNode;
  id: Scalars['UUID'];
  invoice: InvoiceNode;
};

export type InvoiceScanResultFileNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<InvoiceScanResultFileNode>;
};

export type InvoiceScanResultFileOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export enum InvoiceStatuses {
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  NeedsAttention = 'NEEDS_ATTENTION',
  New = 'NEW',
  Resolved = 'RESOLVED'
}

export type InvoiceSummary = {
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export enum InvoiceTypes {
  Billpay = 'BILLPAY',
  NonTradeDeduction = 'NON_TRADE_DEDUCTION',
  Repayment = 'REPAYMENT',
  TradeDeduction = 'TRADE_DEDUCTION'
}

export enum InvoiceUploadSources {
  Manual = 'MANUAL',
  WebScraper = 'WEB_SCRAPER'
}

export type LatestEstimateColumnMapping = {
  customerName: Scalars['String'];
  date: Scalars['String'];
  productName: Scalars['String'];
  revenueLatestEstimate: Scalars['String'];
};

export type LatestEstimateFileData = {
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<Maybe<Scalars['String']>>;
  revenueLatestEstimate: Array<Scalars['String']>;
};

export type LatestEstimateFileDataInput = {
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<InputMaybe<Scalars['String']>>;
  revenueLatestEstimate: Array<Scalars['String']>;
};

export type LatestEstimateFileDataOutput = LatestEstimateFileData & {
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<Maybe<Scalars['String']>>;
  revenueLatestEstimate: Array<Scalars['String']>;
};

export type LatestEstimateFilter = {
  AND?: InputMaybe<LatestEstimateFilter>;
  NOT?: InputMaybe<LatestEstimateFilter>;
  OR?: InputMaybe<LatestEstimateFilter>;
  company?: InputMaybe<CompanyFilter>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DatetimeFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  product?: InputMaybe<ProductFilter>;
};

export type LatestEstimateGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: LatestEstimateUploadWarningEnum;
};

export type LatestEstimateMappedFileResponse = {
  columnMapping: LatestEstimateColumnMapping;
  fileData: LatestEstimateFileDataOutput;
  validationResults: LatestEstimateUploadValidationResponse;
};

export type LatestEstimateNode = {
  company: CompanyNode;
  customer: CustomerNode;
  date: Scalars['DateTime'];
  id: Scalars['UUID'];
  product?: Maybe<ProductNode>;
  revenueLatestEstimate: Scalars['Float'];
};

export type LatestEstimateUploadErrors = {
  aobEntryFound?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  productNotFound?: Maybe<Array<Scalars['Int']>>;
};

export type LatestEstimateUploadValidationResponse = {
  errors?: Maybe<LatestEstimateUploadErrors>;
  warnings?: Maybe<LatestEstimateUploadWarnings>;
};

export enum LatestEstimateUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type LatestEstimateUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: LatestEstimateGroupedIndiceWarning;
};

export type LiftBucketFilter = {
  AND?: InputMaybe<LiftBucketFilter>;
  NOT?: InputMaybe<LiftBucketFilter>;
  OR?: InputMaybe<LiftBucketFilter>;
  company?: InputMaybe<CompanyFilter>;
  fundTypes?: InputMaybe<FundTypeFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type LiftBucketNode = {
  company: CompanyNode;
  fundTypes: Array<FundTypeNode>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type LiftBucketNodeFundTypesArgs = {
  filters?: InputMaybe<FundTypeFilter>;
};

export type LiftColumnMapping = {
  customerName: Scalars['String'];
  liftBucketName: Scalars['String'];
  liftPercent: Scalars['String'];
  productGroupName: Scalars['String'];
};

export type LiftFileData = {
  customerName: Array<Scalars['String']>;
  liftBucketName: Array<Scalars['String']>;
  liftPercent: Array<Scalars['String']>;
  productGroupName: Array<Scalars['String']>;
};

export type LiftFileDataInput = {
  customerName: Array<Scalars['String']>;
  liftBucketName: Array<Scalars['String']>;
  liftPercent: Array<Scalars['String']>;
  productGroupName: Array<Scalars['String']>;
};

export type LiftFileDataOutput = LiftFileData & {
  customerName: Array<Scalars['String']>;
  liftBucketName: Array<Scalars['String']>;
  liftPercent: Array<Scalars['String']>;
  productGroupName: Array<Scalars['String']>;
};

export type LiftFilter = {
  AND?: InputMaybe<LiftFilter>;
  NOT?: InputMaybe<LiftFilter>;
  OR?: InputMaybe<LiftFilter>;
  company?: InputMaybe<CompanyFilter>;
  customer?: InputMaybe<CustomerFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  liftBucket?: InputMaybe<LiftBucketFilter>;
  productGroup?: InputMaybe<ProductGroupFilter>;
};

export type LiftGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: LiftUploadWarningEnum;
};

export type LiftMappedFileResponse = {
  columnMapping: LiftColumnMapping;
  fileData: LiftFileDataOutput;
  validationResults: LiftUploadValidationResponse;
};

export type LiftNode = {
  company: CompanyNode;
  customer: CustomerNode;
  id: Scalars['UUID'];
  liftBucket: LiftBucketNode;
  liftValue: Scalars['Float'];
  productGroup: ProductGroupNode;
};

export type LiftNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<LiftNode>;
};

export type LiftOrder = {
  customer?: InputMaybe<Ordering>;
  liftBucket?: InputMaybe<Ordering>;
  liftValue?: InputMaybe<Ordering>;
  productGroup?: InputMaybe<Ordering>;
};

export type LiftUploadErrors = {
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  liftBucketNotFound?: Maybe<Array<Scalars['Int']>>;
  productGroupNotFound?: Maybe<Array<Scalars['Int']>>;
};

export type LiftUploadValidationResponse = {
  errors?: Maybe<LiftUploadErrors>;
  warnings?: Maybe<LiftUploadWarnings>;
};

export enum LiftUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type LiftUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: LiftGroupedIndiceWarning;
};

export type LinkInvoiceTransactionInput = {
  invoiceIds: Array<Scalars['UUID']>;
  transactionId: Scalars['UUID'];
};

export type ManyToManyInput = {
  add?: InputMaybe<Array<Scalars['ID']>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
  set?: InputMaybe<Array<Scalars['ID']>>;
};

export type MatchDeductionsAccumulatedRowResultNode = {
  accumulatedAmount: Array<Scalars['String']>;
  accumulatedCustomerName: Array<Scalars['String']>;
  accumulatedCustomerNameAcc?: Maybe<Array<Scalars['String']>>;
  accumulatedDate: Array<Scalars['String']>;
  accumulatedFundTypeNames: Array<Scalars['String']>;
  accumulatedProductName: Array<Scalars['String']>;
  accumulatedRows: Array<Scalars['Int']>;
  accumulatedSpendRate: Array<Scalars['String']>;
  accumulatedValidatedAmount?: Maybe<Scalars['Float']>;
  index: Scalars['Int'];
  matchedCustomer?: Maybe<BasicNode>;
  matchedFundTypes: Array<Maybe<BasicNode>>;
  matchedProduct?: Maybe<BasicNode>;
  matchedPromotionLines: Array<MatchDeductionsPromotionLineMatchNode>;
  validatedMonthYear?: Maybe<Scalars['Date']>;
  validatedSpendRate?: Maybe<Scalars['Float']>;
};

export type MatchDeductionsErrorDataNode = {
  atLeastOneFundTypeNotFound: Array<Scalars['Int']>;
  customerNotFound: Array<Scalars['Int']>;
  invalidAmount: Array<Scalars['Int']>;
  invalidMonthYear: Array<Scalars['Int']>;
  invalidSpendRate: Array<Scalars['Int']>;
  noPromotionLineMatches: Array<Scalars['Int']>;
  productNotFound: Array<Scalars['Int']>;
};

export type MatchDeductionsInput = {
  deductionMatchingInstanceId: Scalars['UUID'];
};

export type MatchDeductionsPromotionLineMatchNode = {
  promotionLine: BasicPromotionLineNode;
  spendRateDifference?: Maybe<Scalars['Float']>;
};

export type MatchDeductionsResultNode = {
  accumulatedErrors: MatchDeductionsErrorDataNode;
  accumulatedResultData: Array<MatchDeductionsAccumulatedRowResultNode>;
  errors: MatchDeductionsErrorDataNode;
  resultData: Array<MatchDeductionsRowResultNode>;
};

export type MatchDeductionsRowResultNode = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  customerNameAcc?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  flagged?: Maybe<InvoiceLineFlags>;
  fundTypeNames?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  invoiceLineId?: Maybe<Scalars['String']>;
  matchedCustomer?: Maybe<BasicNode>;
  matchedFundTypes: Array<Maybe<BasicNode>>;
  matchedProduct?: Maybe<BasicNode>;
  matchedPromotionLines: Array<MatchDeductionsPromotionLineMatchNode>;
  productGroupName?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  spendRate: Scalars['String'];
  validatedAmount?: Maybe<Scalars['Float']>;
  validatedMonthYear?: Maybe<Scalars['Date']>;
  validatedSpendRate?: Maybe<Scalars['Float']>;
};

export type MissLogExportNode = {
  erpTransactionId: Scalars['String'];
  id: Scalars['UUID'];
  invoiceBackupAttached: Scalars['Boolean'];
  invoiceEndDate?: Maybe<Scalars['Date']>;
  invoiceLineAssignedUsers?: Maybe<Scalars['String']>;
  invoiceLineComment?: Maybe<Scalars['String']>;
  invoiceLineCustomerName?: Maybe<Scalars['String']>;
  invoiceLineFlagReporter?: Maybe<Scalars['String']>;
  invoiceLineFlagged?: Maybe<InvoiceLineFlags>;
  invoiceLineFundTypeName?: Maybe<Scalars['String']>;
  invoiceLineMatchedPromotionLine?: Maybe<Scalars['String']>;
  invoiceLineOpenAmount: Scalars['Float'];
  invoiceLineProductGroupName?: Maybe<Scalars['String']>;
  invoiceLineProductName?: Maybe<Scalars['String']>;
  invoiceLineStatus?: Maybe<InvoiceLineNextStatuses>;
  invoiceLineTotalAmount: Scalars['Float'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceOpenAmount: Scalars['Float'];
  invoiceStatus?: Maybe<InvoiceStatuses>;
  invoiceTotalAmount: Scalars['Float'];
  transactionBackupAttached: Scalars['Boolean'];
  transactionCheckNumber?: Maybe<Scalars['String']>;
  transactionCustomerName: Scalars['String'];
  transactionDate: Scalars['Date'];
  transactionDaysOutstanding: Scalars['Int'];
  transactionImportDate: Scalars['String'];
  transactionOpenAmount: Scalars['Float'];
  transactionProcessor?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatuses;
  transactionTotalAmount: Scalars['Float'];
  transactionType: TransactionTypes;
};

export enum Months {
  Apr = 'APR',
  Aug = 'AUG',
  Dec = 'DEC',
  Feb = 'FEB',
  Jan = 'JAN',
  Jul = 'JUL',
  Jun = 'JUN',
  Mar = 'MAR',
  May = 'MAY',
  Nov = 'NOV',
  Oct = 'OCT',
  Sep = 'SEP'
}

export type MostRecentLine = {
  id: Scalars['UUID'];
  openAmount: Scalars['Float'];
  salesReviewDate: Scalars['String'];
  transactionId: Scalars['UUID'];
  vividlyCustomerName: Scalars['String'];
};

export type MoveProductHierarchyErrors = {
  /** Data error occurred. */
  dataError?: Maybe<Scalars['String']>;
  /** The destination product group doesn't exist. */
  destinationPgDoesNotExistInHierarchy?: Maybe<Scalars['Boolean']>;
  /** The selected entries don't have a common source product group.                      All selected nodes for move should have the same parent PG, referred to as source PG. */
  selectedEntriesDontHaveCommonSourcePg?: Maybe<Scalars['Boolean']>;
  /** Selected items cannot be parent to destination. */
  selectionCannotBeParentToDestination?: Maybe<Scalars['Boolean']>;
  /** Cannot move a product group to itself. */
  selfReferenceError?: Maybe<Scalars['Boolean']>;
  /** Some of the selected items don't have hierarchy entries */
  someIdsDontHaveHierarchyEntries?: Maybe<Scalars['Boolean']>;
};

export type MoveProductHierarchyResponse = {
  data?: Maybe<MoveProductHierarchySuccess>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<MoveProductHierarchyErrors>;
};

export type MoveProductHierarchySuccess = {
  /** Number of relations changed in the move */
  relationsChanged: Scalars['Int'];
};

export type MoveProductInput = {
  companyId: Scalars['UUID'];
  destinationPg: Scalars['UUID'];
  itemIds: Array<Scalars['UUID']>;
};

export type Mutation = {
  addBackupSourcesToBackupSourceGroup: BackupSourceGroupNode;
  addFlagBulkInvoiceLineNext: BulkResolutionActivityResponseNode;
  addFlagSingleInvoiceLineNext: SingleResolutionActivityResponseNode;
  addFundTypeToPassThroughTypes?: Maybe<PassThroughTypesNode>;
  addPricingTimeFramesSerializerValidation: Array<PricingTimeFrameNode>;
  addPromotionAttachments: Array<Scalars['UUID']>;
  addPromotionComment: PromotionNode;
  addPromotionTagToPromotion: PromotionTagNode;
  addUsersToCustomer: Scalars['UUID'];
  assignBackupFileToInvoiceNext: InvoiceNextNode;
  assignBackupFileToMultipleInvoiceNext: Array<InvoiceNextNode>;
  assignFundTypeToInvoice: InvoiceNode;
  assignFundTypeToInvoiceNext: InvoiceNextNode;
  assignToStaff?: Maybe<UserNode>;
  assignToSuperuser?: Maybe<UserNode>;
  assignUserToCompany?: Maybe<UserNode>;
  assignUserToInvoice: InvoiceNode;
  attachBackupsToTransaction: TransactionNextNode;
  autoFillInvoiceLinesProductGroups: InvoiceLineNextBatchIntroduceResponse;
  batchAddPromTag: Array<PromotionNode>;
  batchCreateBackupFile: BatchIntroduceBackupFileNode;
  batchCreateInvoicesNext: Array<IntroduceInvoiceNextNode>;
  batchDeletePos: Scalars['Boolean'];
  batchDeleteRevenue: Scalars['Boolean'];
  batchRemoveFlagInvoiceLineNext: Array<SingleResolutionActivityResponseNode>;
  batchUpdatePromStatus: Array<PromotionNode>;
  batchUpdatePromotionLineStatus: Array<PromotionLineNode>;
  bulkCancelSalesReview: Array<InvoiceLineNextNode>;
  bulkCreateResolutionActivities: Array<ResolutionActivityNode>;
  cancelInvoiceNext: UpdateInvoiceResponseNext;
  cancelInvoicesAttachedToBackup: Array<UpdateInvoiceResponseNext>;
  cancelPayments: Scalars['Boolean'];
  cancelRemits: Scalars['Boolean'];
  cancelTransactionNext: UpdateTransactionResponseNext;
  checkClearableInvoiceLines: CheckClearableInvoiceLinesResponse;
  checkIfTransactionsCanBeQuickCleared: QuickClearDependencyMutationResponse;
  checkNonVoidedClearWriteoffResolutionLines: Array<Scalars['UUID']>;
  checkPromotionHasDeductionsAttached: Scalars['Boolean'];
  checkPromotionLineDependency: CheckPromotionLineDependencyResponse;
  checkPromotionNameConflict: Array<Scalars['String']>;
  checkResolvableInvoiceLines: Array<Scalars['UUID']>;
  clearExactAmounts: Array<ResolutionActivityNode>;
  clearExactAmountsMissLog: Array<ClearExactAmountsResponseNode>;
  clearExactAmountsNext: ClearExactAmountsResponseNode;
  clearWriteoffBulkInvoiceLinesNext: BulkResolutionActivityResponseNode;
  clearWriteoffSingleInvoiceLineNext: SingleResolutionActivityResponseNode;
  copyForecastVersion: Scalars['Int'];
  createAccount: AccountNode;
  createAccounts: Array<AccountNode>;
  createAdminActivityLog: AdminActivityLogNode;
  createBackupFile: IntroduceBackupFileNode;
  createBackupRetrieval: BackupRetrievalNode;
  createBackupSource: BackupSourceNode;
  createBackupSourceGroup: BackupSourceGroupNode;
  createBackupSourceSettingsOverride: BackupSourceSettingsOverrideNode;
  createBackupSourceTemplateFile: BackupSourceTemplateFileNode;
  createBudgetDefaultView: BudgetDefaultViewNode;
  createBuyInWindow: BuyInWindowNode;
  createBuyInWindows: Array<BuyInWindowNode>;
  createComment: Scalars['UUID'];
  createCompanies: Array<CompanyNode>;
  createCompany: CompanyNode;
  createCompanyBackupSourceRelationship: CompanyBackupSourceRelationshipNode;
  createCompanyDefaultApprovalLimit: CompanyDefaultApprovalLimitNode;
  createCompanyFeatureGate: CompanyFeatureGateNode;
  createCompanyFeatureGates: Array<CompanyFeatureGateNode>;
  createCompanyFeatureRelationship: CompanyFeatureNode;
  createCompanyFeatures: Array<CompanyFeatureNode>;
  createCompanyFile: CompanyFileNode;
  createCompanyFiles: Array<IntroduceCompanyFileNode>;
  createCompanyProvidedService: CompanyProvidedServiceNode;
  createCompanyProvidedServices: Array<CompanyProvidedServiceNode>;
  createCompanyScanningSetting: CompanyScanningSettingNode;
  createCompanyScanningSettings: Array<CompanyScanningSettingNode>;
  createCompanyUser: UserNode;
  createContact: ContactNode;
  createContacts: Array<ContactNode>;
  createCustomer: CustomerNode;
  createCustomerAlternativeNameTags: Array<CustomerAlternativeNameTagNode>;
  createCustomerAlternativeNames: Array<CustomerAlternativeNameNode>;
  createCustomerBucketRelationship: CustomerBucketRelationshipNode;
  createCustomerTimeFrame: CustomerTimeFrameNode;
  createCustomerTimeFrames: Array<CustomerTimeFrameNode>;
  createCustomerTimeFramesFromExisting: Array<CustomerTimeFrameNode>;
  createCustomers: Array<CustomerNode>;
  createDeductionMatchingColumnMapping: DeductionMatchingColumnMappingNode;
  createDeductionMatchingColumnOrder: DeductionMatchingColumnOrderNode;
  createDeductionMatchingInstance: DeductionMatchingInstanceNode;
  createDeductionScan: DeductionScanNode;
  createDiscountCutoff?: Maybe<DiscountCutoffNode>;
  createDrmEvent: DrmEventNode;
  createDrmEventCommentTag: DrmEventCommentTagNode;
  createDrmEventCommentTagNext: DrmEventCommentTagNextNode;
  createDrmEventCommentTags: Array<DrmEventCommentTagNode>;
  createDrmEventCommentTagsNext: Array<DrmEventCommentTagNextNode>;
  createDrmEventNext: DrmEventNextNode;
  createDrmEvents: Array<DrmEventNode>;
  createDrmEventsNext: Array<DrmEventNextNode>;
  createEmailDomain: EmailDomainNode;
  createForecastSeasonalityBucket: ForecastSeasonalityBucketNode;
  createForecastSeasonalityBuckets: Array<ForecastSeasonalityBucketNode>;
  createForecastSeasonalityIndex: ForecastSeasonalityIndexNode;
  createForecastSeasonalityIndexes: Array<ForecastSeasonalityIndexNode>;
  createForecastVersion: Scalars['Int'];
  createFreeTrial: DsmFreeTrialNode;
  createFundType: FundTypeNode;
  createFundTypeField: FundTypeFieldNode;
  createFundTypeFields: Array<FundTypeFieldNode>;
  createFundTypes: Array<FundTypeNode>;
  createHierarchyNextCustomer: CustomerNextCreateResponse;
  createHierarchyNextCustomerGroup: CustomerGroupNextCreateResponse;
  createHierarchyNextProduct: ProductNextCreateResponse;
  createHierarchyNextProductGroup: ProductGroupNextCreateResponse;
  createInternalFile: InternalFileNode;
  createInvoice: InvoiceNode;
  createInvoiceLine: InvoiceLineNode;
  createInvoiceLineComment: Array<DrmEventNextNode>;
  createInvoiceLines: Array<UpdateInvoiceLineResponse>;
  createInvoiceLinesFromFileData: InvoiceNode;
  createInvoiceLinesNext: InvoiceLineNextBatchIntroduceResponse;
  createInvoiceLinesNextFromFileData: InvoiceNextNode;
  createInvoices: Array<IntroduceInvoiceNode>;
  createInvoicesNext: Array<IntroduceInvoiceNextNode>;
  createLift: LiftNode;
  createLiftBucket: LiftBucketNode;
  createOrUpdateUserSetting: UserSettingsNode;
  createOriginalFileColumnHeader: OriginalFileColumnHeaderNode;
  createPassThroughTypes: PassThroughTypesNode;
  createPayment: PaymentNode;
  createPermission: PermissionNode;
  createPnlGroups: Array<PnlGroupNode>;
  createPresetCustomer: PresetCustomerNode;
  createPresetCustomerAlternativeName: PresetCustomerAlternativeNameNode;
  createPresetCustomerAlternativeNames: Array<PresetCustomerAlternativeNameNode>;
  createPresetCustomers: Array<PresetCustomerNode>;
  createProduct: ProductNode;
  createProductBucketRelationship: ProductBucketRelationshipNode;
  createProductCode: ProductCodeNode;
  createProductCodes: CreateProductCodes;
  createProductGroup: CreateProductGroup;
  createProductGroupAlternativeName: ProductGroupAlternativeNameNode;
  createProductGroupProductRelationship: CreateProductGroupProductRelationship;
  createProducts: Array<ProductNode>;
  createPromotion: PromotionNode;
  createPromotionApprovalLimit: PromotionApprovalLimitNode;
  createPromotionApprovalLimits: Array<PromotionApprovalLimitNode>;
  createPromotionBatchWithLines: Array<PromotionNode>;
  createPromotionLine: PromotionLineNode;
  createPromotionTag: PromotionTagNode;
  createPromotionWithLines: PromotionNode;
  createRemitTestData: Scalars['Boolean'];
  createResolutionActivity: ResolutionActivityNode;
  createRevenueEntries: Array<RevenueNode>;
  createRevenueSource?: Maybe<RevenueSourceNode>;
  createRole: RoleNode;
  createRolePromotionFilterSettings: RolePromotionFilterSettingsNode;
  createSpend: SpendNode;
  createSpends: Array<SpendNode>;
  createToken: TokenNodeWithKey;
  createTransaction: IntroduceTransactionNode;
  createTransactionNext: IntroduceTransactionNextNode;
  createUploadFileAndGetHeaders: ScanInvoiceResponseNode;
  createUploadFileNextAndGetHeaders: ScanInvoiceNextResponseNode;
  createUser: UserNode;
  createUserSetting: UserSettingsNode;
  createUserSettings: Array<UserSettingsNode>;
  createUsers: Array<UserNode>;
  deleteAccount: DeleteAccountReturn;
  deleteAllInvoiceLines: Scalars['Boolean'];
  deleteAllInvoiceLinesNext: Scalars['Boolean'];
  deleteAllLatestEstimate: Array<LatestEstimateNode>;
  deleteAllPricingTimeFrames: Scalars['UUID'];
  deleteAnyway?: Maybe<Scalars['Boolean']>;
  deleteBackupFile: AttachedTransactionsAndInvoices;
  deleteBackupRetrievals: Array<BackupRetrievalNode>;
  deleteBackupSourceGroups: Array<BackupSourceGroupNode>;
  deleteBackupSourceSettingsOverride: Array<BackupSourceSettingsOverrideNode>;
  deleteBackupSourceTemplateFiles: Array<BackupSourceTemplateFileNode>;
  deleteBackupSources: Array<BackupSourceNode>;
  deleteBudgetForYears: Array<BudgetNode>;
  deleteBumpChartDatas: Array<BumpChartDataNode>;
  deleteBuyInWindows: Array<BuyInWindowNode>;
  deleteComment: Scalars['UUID'];
  deleteCompanyBackupSourceRelationships: Scalars['Boolean'];
  deleteCompanyDefaultApprovalLimit: CompanyDefaultApprovalLimitNode;
  deleteCompanyFeatureGateRelationships: Array<CompanyFeatureGateNode>;
  deleteCompanyFeatureRelationships: Array<CompanyFeatureNode>;
  deleteCompanyFiles: Array<CompanyFileNode>;
  deleteCompanyProvidedServiceRelationships: Array<CompanyProvidedServiceNode>;
  deleteCompanyScanningSettings: Array<CompanyScanningSettingNode>;
  deleteContacts: Array<ContactNode>;
  deleteCustomerAlternativeNameTags: Array<CustomerAlternativeNameTagNode>;
  deleteCustomerAlternativeNames: Array<Scalars['UUID']>;
  deleteCustomerBucketRelationships: Array<CustomerBucketRelationshipNode>;
  deleteCustomerTimeFrames: Array<Scalars['UUID']>;
  deleteCustomerTimeFramesStrict: Array<Scalars['UUID']>;
  deleteCustomers: Array<CustomerNode>;
  deleteDeductionMatchingColumnOrder: Array<DeductionMatchingColumnOrderNode>;
  deleteDeductionScans: Array<DeductionScanNode>;
  deleteDiscountCutoff: Scalars['Boolean'];
  deleteEmailDomain: Array<EmailDomainNode>;
  deleteFilteredPos: Scalars['Int'];
  deleteFilteredRevenue: Scalars['Int'];
  deleteForecastOverride?: Maybe<Scalars['UUID']>;
  deleteForecastSeasonalityBucket: Scalars['UUID'];
  deleteForecastSeasonalityIndex: Scalars['UUID'];
  deleteForecastVersion: Scalars['Int'];
  deleteFreeTrial: Array<DsmFreeTrialNode>;
  deleteFundType: Scalars['UUID'];
  deleteFundTypeField: Scalars['UUID'];
  deleteInternalFiles: Array<InternalFileNode>;
  deleteInvoiceLines: Array<Scalars['UUID']>;
  deleteInvoiceLinesNext: Array<Scalars['UUID']>;
  deleteLatestEstimateForYears: Array<LatestEstimateNode>;
  deleteLift: ExpungeLiftReturn;
  deleteLiftBuckets?: Maybe<Array<LiftBucketNode>>;
  deletePermissions: Array<PermissionNode>;
  deletePnlGroups: Scalars['Boolean'];
  deletePresetCustomer: Array<PresetCustomerNode>;
  deletePresetCustomerAlternativeNames: Array<PresetCustomerAlternativeNameNode>;
  deletePricingTimeFrames: Array<Scalars['UUID']>;
  deleteProductBucketRelationships: Array<ProductBucketRelationshipNode>;
  deleteProductCodes: Array<ProductCodeNode>;
  deleteProductGroupAlternativeNames: Array<ProductGroupAlternativeNameNode>;
  deleteProductGroupProductRelationship: DeleteProductGroupProductRelationship;
  deleteProductGroupProductRelationships: Array<DeleteProductGroupProductRelationship>;
  deleteProductGroups: DeleteProductGroup;
  deleteProducts: DeleteProduct;
  deletePromotion: Scalars['UUID'];
  deletePromotionApprovalLimit: Scalars['UUID'];
  deletePromotionAttachments: Array<Scalars['UUID']>;
  deletePromotionLine: Array<PromotionLineNode>;
  deletePromotionTag: DeletePromotionTagReturn;
  deleteRevenueSource?: Maybe<Scalars['UUID']>;
  deleteRole: RoleNode;
  deleteRolePromotionFilterSettings: RolePromotionFilterSettingsNode;
  deleteSpends: Array<SpendNode>;
  deleteTokens: Array<TokenNode>;
  deleteUsers: Array<UserNode>;
  downloadMissLogExport: Array<MissLogExportNode>;
  downloadOpenDeductionsReport: Array<OpenDeductionsReportNode>;
  downloadOpenDeductionsReportNext: Array<OpenDeductionsReportNextNode>;
  downloadSettlementReport: Array<SettlementReportRowNode>;
  downloadSettlementReportNext: Array<SettlementReportRowNextNode>;
  duplicateCodes: Array<Scalars['String']>;
  exportPromotion: PromotionExportStatus;
  exportSqlInstance: GcpSqlExportResponse;
  generateBumpChartMappedFileData: BumpChartMappedFileResponse;
  generateContactMappedFileData: ContactMappedFileResponse;
  generateLatestEstimateMappedFileData: LatestEstimateMappedFileResponse;
  generateLiftMappedFileData: LiftMappedFileResponse;
  generatePointOfSaleMappedFileData: PointOfSaleMappedFileResponse;
  generatePriceElasticityData: PriceElasticityOutput;
  generatePricingMappedFileData: PricingMappedFileResponse;
  generateProductMappedFileData: ProductMappedFileResponse;
  generatePromLineLiftPrediction: GenerateLiftPredictionsOutput;
  generatePromotionMappedFileData: PromotionMappedFileResponse;
  generateRevenueBudgetMappedFileData: RevenueBudgetMappedFileResponse;
  generateRevenueMappedFileData: RevenueMappedFileResponse;
  generateSignedUrl: Scalars['String'];
  generateSpendMappedFileData: SpendMappedFileResponse;
  generateTransactionMappedFileData: TransactionMappedFileResponse;
  generateTransactionNextMappedFileData: TransactionMappedFileResponse;
  getPosCount: Scalars['Int'];
  getRevenueCount: Scalars['Int'];
  getUploadSignedUrl: UploadSignedUrlResponse;
  /** Initiates auto-adding backup sources to the listed companies by adding them to BackupSourceSettingOverride.            If UpdateAll is True, this will update all companies. */
  initAutoAddBackupToCompanies?: Maybe<Array<Scalars['UUID']>>;
  /** Initiates the db copy process. */
  initDbCopy: InitDbCopyOutput;
  /** Initiates the db copy process. */
  initInternalDbCopy: InitDbCopyOutput;
  linkInvoicesToTransaction: TransactionNode;
  matchDeductions: MatchDeductionsResultNode;
  matchInvoiceLines: MatchDeductionsResultNode;
  matchInvoiceLinesNext: MatchDeductionsResultNode;
  moveProductHierarchy: MoveProductHierarchyResponse;
  offsetInvoiceLineNext: BulkResolutionActivityResponseNode;
  parseOriginalColumnHeaders: Array<UploadColumnHeader>;
  product: ProductNode;
  productCodes: Array<ProductCodeNode>;
  productGroup: ProductGroupNode;
  quickClearInvoices: QuickClearInvoiceMutationResponse;
  quickClearTransactionsByThreshold: QuickClearMutationResponse;
  quickWriteOffInvoices: QuickClearInvoiceMutationResponse;
  quickWriteOffTransactionsByThreshold: QuickClearMutationResponse;
  /** Used to refresh the data for a particular dashboard */
  refreshDashboardMetrics: RefreshDashboardMetricsResponse;
  registerUploadedFile: UploadFileRegisteredResponse;
  removeBackupSourcesFromBackupSourceGroup: BackupSourceGroupNode;
  removeFlagSingleInvoiceLineNext: SingleResolutionActivityResponseNode;
  removeFundTypeFromPassThroughTypes?: Maybe<PassThroughTypesNode>;
  removePromotionTagFromPromotion: PromotionTagNode;
  removeUsersFromCustomer: Scalars['UUID'];
  renameForecastVersion: Scalars['Int'];
  revisePricingTimeFramesSerializerValidation: Array<PricingTimeFrameNode>;
  saveEditedInvoiceLinesNext: Scalars['Boolean'];
  scanInvoiceNextOriginalFile: ScanInvoiceNextResponseNode;
  scanInvoiceOriginalFile: ScanInvoiceResponseNode;
  searchCustomerNamesAndAltNames: NamesAndAltNames;
  searchPresetCustomerNamesAndAltNames: Scalars['Boolean'];
  setBudgetRevToZero: Scalars['Int'];
  setBudgetSpendToZero: Scalars['Int'];
  setIsContract: Scalars['Boolean'];
  setLineAttachmentsForPromotion: Array<Scalars['UUID']>;
  setLineIsContract: Scalars['Boolean'];
  startProcessing: Scalars['Boolean'];
  startScraping: Scalars['Boolean'];
  submitBudgetData: SubmitBudgetResponse;
  submitBumpChartData: SubmitBumpChartResponse;
  submitContactData: SubmitContactResponse;
  submitLatestEstimateData: SubmitLatestEstimateResponse;
  submitLiftData: SubmitLiftResponse;
  submitPointOfSaleData: SubmitPointOfSaleResponse;
  submitPricingData: SubmitPricingResponse;
  submitProductData: SubmitProductResponse;
  submitPromotionData: SubmitPromotionResponse;
  submitRevenueData: SubmitRevenueResponse;
  submitSpendData: SubmitSpendResponse;
  submitTransactionData: SubmitTransactionResponse;
  submitTransactionNextData: SubmitTransactionNextResponse;
  testLiftPredictionModel: TestLiftPredictionModelsOutput;
  toggleBackupNotAvailable: TransactionNextNode;
  trainLiftPredictionModel: TrainLiftPredictionModelsOutput;
  transferStorageObjects: StorageTransferResponse;
  unassignBackupFileFromInvoiceNext: InvoiceNextNode;
  unassignFromStaff?: Maybe<UserNode>;
  unassignFundTypeFromInvoice: InvoiceNode;
  unassignFundTypeFromInvoiceNext: InvoiceNextNode;
  unassignUser?: Maybe<UserNode>;
  unassignUserFromInvoice: InvoiceNode;
  unlinkInvoiceFromTransaction: TransactionNode;
  updateAllInvoiceLineStatusesForInvoices: UpdateBulkInvoiceLineStatusesForInvoiceResponse;
  updateAssignedUsers: UpdateTransactionResponseNext;
  updateBackupRetrieval: BackupRetrievalNode;
  updateBackupSource: BackupSourceNode;
  updateBackupSourceGroup: BackupSourceGroupNode;
  updateBackupSourceSettingsOverride: BackupSourceSettingsOverrideNode;
  updateBackupSourceTemplateFile: BackupSourceTemplateFileNode;
  updateBudgetDefaultView: Array<BudgetDefaultViewNode>;
  updateBudgetDiffThreshold: BudgetDiffThresholdNode;
  updateBuyInWindows: Array<BuyInWindowNode>;
  updateCompany: CompanyNode;
  updateCompanyDefaultApprovalLimit: CompanyDefaultApprovalLimitNode;
  updateCompanyScanningSettings: Array<CompanyScanningSettingNode>;
  updateContact: ContactNode;
  updateContacts: Array<ContactNode>;
  updateCustomer: CustomerNode;
  updateCustomerAlternativeNames: Array<CustomerAlternativeNameNode>;
  updateCustomerTimeFrames: Array<CustomerTimeFrameNode>;
  updateCustomers: Array<CustomerNode>;
  updateDeductionMatchingColumnMapping: DeductionMatchingColumnMappingNode;
  updateDeductionMatchingColumnOrder: DeductionMatchingColumnOrderNode;
  updateDeductionMatchingInstance: DeductionMatchingInstanceNode;
  updateDeductionScan: DeductionScanNode;
  updateDeductionScanFiles: Array<DeductionScanFileNode>;
  updateDiscountCutoff?: Maybe<DiscountCutoffNode>;
  updateDrmEventCommentTags: Array<DrmEventCommentTagNode>;
  updateDrmEventCommentTagsNext: Array<DrmEventCommentTagNextNode>;
  updateForecastOverride: ForecastOverrideNode;
  updateForecastOverrides: Array<ForecastOverrideNode>;
  updateForecastSeasonalityBucket: UpdateSeasonalityResult;
  updateForecastSeasonalityIndexes: Array<ForecastSeasonalityIndexNode>;
  updateFreeTrial: Array<DsmFreeTrialNode>;
  updateFundType: FundTypeNode;
  updateInternalFile: InternalFileNode;
  updateInvoice: UpdateInvoiceResponse;
  updateInvoiceLine: InvoiceLineNode;
  updateInvoiceLineMatches: Array<InvoiceLineNode>;
  updateInvoiceLineMatchesNext: Array<InvoiceLineNextNode>;
  updateInvoiceMapAndGenerateFileData: InvoiceLineMappedFileResponse;
  updateInvoiceNext: UpdateInvoiceResponseNext;
  updateInvoiceNextMapAndGenerateFileData: InvoiceLineNextMappedFileResponse;
  updateInvoicesNext: Scalars['Boolean'];
  updateLiftBuckets: LiftBucketNode;
  updateLifts: LiftNode;
  updateLineActualLifts: UpdateLineActualLiftsCronOutput;
  updatePayment: PaymentNode;
  updatePayments: Scalars['Boolean'];
  updatePermissions: Array<PermissionNode>;
  updatePnlGroups: Array<PnlGroupNode>;
  updatePnlGroupsNames: Array<PnlGroupNode>;
  updatePresetCustomer: PresetCustomerNode;
  updatePresetCustomerAlternativeNames: Array<PresetCustomerAlternativeNameNode>;
  updateProduct: ProductNode;
  updateProductGroupAlternateNameCustom: ProductGroupAlternativeNameNode;
  updateProductGroups: ProductGroupNode;
  updatePromotionApprovalLimits: Array<PromotionApprovalLimitNode>;
  updatePromotionLine: Array<PromotionLineNode>;
  updatePromotionLineLift: PromotionLineNode;
  updatePromotionNotificationSetting: PromotionNotificationSettingNode;
  updatePromotionStar: PromotionNode;
  updatePromotionStatus: PromotionNode;
  updatePromotionTag: PromotionTagNode;
  updatePromotionTags: PromotionNode;
  updatePromotionWithLines: PromotionNode;
  updateQuickClearThreshold: Scalars['Boolean'];
  updateRemit: RemitNode;
  updateRemits: Scalars['Boolean'];
  updateResolutionActivity: ResolutionActivityNode;
  updateRevenueSource: RevenueSourceUpdateResponse;
  updateRole: RoleNode;
  updateRolePromotionFilterSettings: RolePromotionFilterSettingsNode;
  updateSpendSplits: Scalars['Boolean'];
  updateTransaction: UpdateTransactionResponse;
  updateTransactionNext: UpdateTransactionResponseNext;
  updateTransactionNextBackups: UpdateTransactionNextBackupsResponse;
  updateUserSettings: UserSettingsNode;
  updateUsers: Array<UserNode>;
  uploadErpTransactions: ErpTransactionUploadResponse;
  uploadErpTransactionsFlat: ErpTransactionUploadResponse;
  uploadPromotionTag: Scalars['String'];
  upsertSalesReviewNotificationSettings: Scalars['Boolean'];
  viewAllEventCommentTags?: Maybe<Scalars['Void']>;
  viewAllEventCommentTagsNext?: Maybe<Scalars['Void']>;
  voidClearWriteoffResolutionLines: VoidResolutionActivitiesResponseNode;
};


export type MutationAddBackupSourcesToBackupSourceGroupArgs = {
  data: BackupSourceToBackupSourceGroupInput;
};


export type MutationAddFlagBulkInvoiceLineNextArgs = {
  input: BulkResolutionActivityFlagInput;
};


export type MutationAddFlagSingleInvoiceLineNextArgs = {
  input: SingleResolutionActivityFlagInput;
};


export type MutationAddFundTypeToPassThroughTypesArgs = {
  fundTypeId: Scalars['UUID'];
  id: Scalars['UUID'];
};


export type MutationAddPricingTimeFramesSerializerValidationArgs = {
  data: Array<PricingTimeFrameInput>;
};


export type MutationAddPromotionAttachmentsArgs = {
  data: AddPromotionAttachmentsInput;
};


export type MutationAddPromotionCommentArgs = {
  data: CreateCommentInput;
};


export type MutationAddPromotionTagToPromotionArgs = {
  input: AddPromotionTagToPromotionInput;
};


export type MutationAddUsersToCustomerArgs = {
  data: UserToCustomerInput;
};


export type MutationAssignBackupFileToInvoiceNextArgs = {
  backupFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  id: Scalars['UUID'];
  skipUpdateInvoiceNumber?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssignBackupFileToMultipleInvoiceNextArgs = {
  companyId: Scalars['UUID'];
  input: Array<BulkInvoiceBackupLinkingInput>;
  skipUpdateInvoiceNumber?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAssignFundTypeToInvoiceArgs = {
  fundType: Scalars['UUID'];
  id: Scalars['UUID'];
};


export type MutationAssignFundTypeToInvoiceNextArgs = {
  fundType: Scalars['UUID'];
  id: Scalars['UUID'];
};


export type MutationAssignToStaffArgs = {
  userId: Scalars['UUID'];
};


export type MutationAssignToSuperuserArgs = {
  userId: Scalars['UUID'];
};


export type MutationAssignUserToCompanyArgs = {
  data: CompanyUserRelationshipInput;
};


export type MutationAssignUserToInvoiceArgs = {
  id: Scalars['UUID'];
  user: Scalars['UUID'];
};


export type MutationAttachBackupsToTransactionArgs = {
  input: AttachBackupsInput;
};


export type MutationAutoFillInvoiceLinesProductGroupsArgs = {
  transactionId: Scalars['UUID'];
};


export type MutationBatchAddPromTagArgs = {
  data: BatchAddPromTagsInput;
};


export type MutationBatchCreateBackupFileArgs = {
  ignoreWarnings?: Scalars['Boolean'];
  input: BatchIntroduceBackupFileInput;
};


export type MutationBatchCreateInvoicesNextArgs = {
  companyId: Scalars['UUID'];
  invoices: Array<BulkInvoiceIntroduceInputNext>;
};


export type MutationBatchDeletePosArgs = {
  data: BatchDeletePosInput;
};


export type MutationBatchDeleteRevenueArgs = {
  data: BatchDeleteRevenueInput;
};


export type MutationBatchRemoveFlagInvoiceLineNextArgs = {
  inputs: Array<SingleResolutionActivityFlagInput>;
};


export type MutationBatchUpdatePromStatusArgs = {
  data: BatchUpdatePromStatusInput;
};


export type MutationBatchUpdatePromotionLineStatusArgs = {
  data: UpdatePromotionLineStatusInput;
};


export type MutationBulkCancelSalesReviewArgs = {
  comment: Scalars['String'];
  companyId: Scalars['UUID'];
  lineIds: Array<Scalars['UUID']>;
  tags: Array<CancelSalesReviewTagInput>;
};


export type MutationBulkCreateResolutionActivitiesArgs = {
  input: BulkResolutionActivityIntroduceInput;
};


export type MutationCancelInvoiceNextArgs = {
  invoiceId: Scalars['UUID'];
};


export type MutationCancelInvoicesAttachedToBackupArgs = {
  input: Array<BulkCancelInvoicesAttachedToBackupInput>;
};


export type MutationCancelPaymentsArgs = {
  paymentIds: Array<Scalars['UUID']>;
};


export type MutationCancelRemitsArgs = {
  remitIds: Array<Scalars['UUID']>;
};


export type MutationCancelTransactionNextArgs = {
  input: CancelTransactionNextInput;
};


export type MutationCheckClearableInvoiceLinesArgs = {
  input: Array<Scalars['UUID']>;
};


export type MutationCheckIfTransactionsCanBeQuickClearedArgs = {
  input: QuickClearInput;
};


export type MutationCheckNonVoidedClearWriteoffResolutionLinesArgs = {
  input: Array<Scalars['UUID']>;
};


export type MutationCheckPromotionHasDeductionsAttachedArgs = {
  data: CheckPromotionHasDeductionsInput;
};


export type MutationCheckPromotionLineDependencyArgs = {
  data: CheckPromotionLineDependencyInput;
};


export type MutationCheckPromotionNameConflictArgs = {
  data: CheckPromotionNameConflictInput;
};


export type MutationCheckResolvableInvoiceLinesArgs = {
  input: Array<Scalars['UUID']>;
};


export type MutationClearExactAmountsArgs = {
  invoiceLineIds: Array<Scalars['UUID']>;
  transactionId: Scalars['UUID'];
};


export type MutationClearExactAmountsMissLogArgs = {
  invoiceLineIds: Array<Scalars['UUID']>;
};


export type MutationClearExactAmountsNextArgs = {
  invoiceLineIds: Array<Scalars['UUID']>;
};


export type MutationClearWriteoffBulkInvoiceLinesNextArgs = {
  input: BulkResolutionClearWriteOffInput;
};


export type MutationClearWriteoffSingleInvoiceLineNextArgs = {
  input: SingleResolutionClearWriteOffInput;
};


export type MutationCopyForecastVersionArgs = {
  companyId: Scalars['UUID'];
  versionNum: Scalars['Int'];
};


export type MutationCreateAccountArgs = {
  data: AccountInput;
};


export type MutationCreateAccountsArgs = {
  data: Array<AccountInput>;
};


export type MutationCreateAdminActivityLogArgs = {
  input: CreateAdminActivityLogInput;
};


export type MutationCreateBackupFileArgs = {
  ignoreWarnings?: Scalars['Boolean'];
  input: IntroduceBackupFileInputForManualAdd;
};


export type MutationCreateBackupRetrievalArgs = {
  data: IntroduceBackupRetrievalInput;
};


export type MutationCreateBackupSourceArgs = {
  input: CreateBackupSourcePartialInput;
};


export type MutationCreateBackupSourceGroupArgs = {
  input: CreateBackupSourceGroupInput;
};


export type MutationCreateBackupSourceSettingsOverrideArgs = {
  input: CreateBackupSourceSettingsOverrideInput;
};


export type MutationCreateBackupSourceTemplateFileArgs = {
  input: CreateBackupSourceTemplateFileInput;
};


export type MutationCreateBudgetDefaultViewArgs = {
  data: CreateBudgetDefaultViewInput;
};


export type MutationCreateBuyInWindowArgs = {
  data: BuyInWindowInput;
};


export type MutationCreateBuyInWindowsArgs = {
  data: Array<BuyInWindowInput>;
  filters?: InputMaybe<BuyInWindowFilter>;
};


export type MutationCreateCommentArgs = {
  data: CreateCommentInput;
};


export type MutationCreateCompaniesArgs = {
  data: Array<CompanyInput>;
};


export type MutationCreateCompanyArgs = {
  data: CompanyInput;
};


export type MutationCreateCompanyBackupSourceRelationshipArgs = {
  data: CompanyBackupSourceRelationshipInput;
};


export type MutationCreateCompanyDefaultApprovalLimitArgs = {
  data: CompanyDefaultApprovalLimitInput;
};


export type MutationCreateCompanyFeatureGateArgs = {
  data: CompanyFeatureGateRelationshipInput;
};


export type MutationCreateCompanyFeatureGatesArgs = {
  data: Array<CompanyFeatureGateRelationshipInput>;
  filters?: InputMaybe<CompanyFeatureGateFilter>;
};


export type MutationCreateCompanyFeatureRelationshipArgs = {
  data: CompanyFeatureRelationshipInput;
};


export type MutationCreateCompanyFeaturesArgs = {
  data: Array<CompanyFeatureRelationshipInput>;
  filters?: InputMaybe<CompanyFeatureFilter>;
};


export type MutationCreateCompanyFileArgs = {
  data: CreateCompanyFileInput;
};


export type MutationCreateCompanyFilesArgs = {
  data: Array<CreateCompanyFileInput>;
};


export type MutationCreateCompanyProvidedServiceArgs = {
  data: CompanyProvidedServiceRelationshipInput;
};


export type MutationCreateCompanyProvidedServicesArgs = {
  data: Array<CompanyProvidedServiceRelationshipInput>;
  filters?: InputMaybe<CompanyProvidedServiceFilter>;
};


export type MutationCreateCompanyScanningSettingArgs = {
  data: CompanyScanningSettingInput;
};


export type MutationCreateCompanyScanningSettingsArgs = {
  data: Array<CompanyScanningSettingInput>;
};


export type MutationCreateCompanyUserArgs = {
  data: UserInput;
};


export type MutationCreateContactArgs = {
  data: ContactInput;
};


export type MutationCreateContactsArgs = {
  data: Array<ContactInput>;
  filters?: InputMaybe<ContactFilter>;
};


export type MutationCreateCustomerArgs = {
  data: CustomerInput;
};


export type MutationCreateCustomerAlternativeNameTagsArgs = {
  data: Array<CustomerAlternativeNameTagInput>;
  filters?: InputMaybe<CustomerAlternativeNameTagFilter>;
};


export type MutationCreateCustomerAlternativeNamesArgs = {
  data: Array<CustomerAlternativeNameInput>;
};


export type MutationCreateCustomerBucketRelationshipArgs = {
  data: CustomerBucketInput;
};


export type MutationCreateCustomerTimeFrameArgs = {
  data: CustomerTimeFramePartialInput;
};


export type MutationCreateCustomerTimeFramesArgs = {
  data: Array<CustomerTimeFramePartialInput>;
};


export type MutationCreateCustomerTimeFramesFromExistingArgs = {
  data: Array<CustomerTimeFrameDuplicationInput>;
};


export type MutationCreateCustomersArgs = {
  data: Array<CustomerInput>;
};


export type MutationCreateDeductionMatchingColumnMappingArgs = {
  input: CreateDeductionMatchingColumnMappingInput;
};


export type MutationCreateDeductionMatchingColumnOrderArgs = {
  input: CreateDeductionMatchingColumnOrderInput;
};


export type MutationCreateDeductionMatchingInstanceArgs = {
  data: CreateDeductionMatchingInstanceInput;
};


export type MutationCreateDeductionScanArgs = {
  input: CreateDeductionScanInput;
};


export type MutationCreateDiscountCutoffArgs = {
  backupSourceName: Scalars['String'];
  input: CreateDiscountCutoffInput;
};


export type MutationCreateDrmEventArgs = {
  data: IntroduceDrmEventInput;
};


export type MutationCreateDrmEventCommentTagArgs = {
  data: IntroduceDrmEventCommentTagInput;
};


export type MutationCreateDrmEventCommentTagNextArgs = {
  data: IntroduceDrmEventCommentTagNextInput;
};


export type MutationCreateDrmEventCommentTagsArgs = {
  data: Array<IntroduceDrmEventCommentTagInput>;
};


export type MutationCreateDrmEventCommentTagsNextArgs = {
  data: Array<IntroduceDrmEventCommentTagNextInput>;
};


export type MutationCreateDrmEventNextArgs = {
  data: IntroduceDrmEventNextInput;
};


export type MutationCreateDrmEventsArgs = {
  data: Array<IntroduceDrmEventInput>;
  filters?: InputMaybe<DrmEventFilter>;
};


export type MutationCreateDrmEventsNextArgs = {
  data: Array<IntroduceDrmEventNextInput>;
  filters?: InputMaybe<DrmEventNextFilter>;
};


export type MutationCreateEmailDomainArgs = {
  data: IntroduceEmailDomainInput;
};


export type MutationCreateForecastSeasonalityBucketArgs = {
  data: ForecastSeasonalityBucketIntroduceInput;
};


export type MutationCreateForecastSeasonalityBucketsArgs = {
  data: Array<ForecastSeasonalityBucketIntroduceInput>;
};


export type MutationCreateForecastSeasonalityIndexArgs = {
  data: ForecastSeasonalityIndexInput;
};


export type MutationCreateForecastSeasonalityIndexesArgs = {
  data: Array<ForecastSeasonalityIndexInput>;
  filters?: InputMaybe<ForecastSeasonalityIndexFilter>;
};


export type MutationCreateForecastVersionArgs = {
  companyId: Scalars['UUID'];
  overrideVersion?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateFreeTrialArgs = {
  data: IntroduceDsmFreeTrialInput;
};


export type MutationCreateFundTypeArgs = {
  data: FundTypeIntroduceInput;
};


export type MutationCreateFundTypeFieldArgs = {
  data: FundTypeFieldIntroduceInput;
};


export type MutationCreateFundTypeFieldsArgs = {
  data: Array<FundTypeFieldIntroduceInput>;
};


export type MutationCreateFundTypesArgs = {
  data: Array<FundTypeIntroduceInput>;
  filters?: InputMaybe<FundTypeFilter>;
};


export type MutationCreateHierarchyNextCustomerArgs = {
  data: CustomerNextInput;
};


export type MutationCreateHierarchyNextCustomerGroupArgs = {
  data: CustomerGroupNextInput;
};


export type MutationCreateHierarchyNextProductArgs = {
  data: ProductNextInput;
};


export type MutationCreateHierarchyNextProductGroupArgs = {
  data: ProductGroupNextInput;
};


export type MutationCreateInternalFileArgs = {
  data: CreateInternalFileInput;
};


export type MutationCreateInvoiceArgs = {
  data: InvoiceIntroduceInput;
};


export type MutationCreateInvoiceLineArgs = {
  data: InvoiceLineIntroduceInput;
};


export type MutationCreateInvoiceLineCommentArgs = {
  comment: Scalars['String'];
  invoiceLineIds: Array<Scalars['UUID']>;
};


export type MutationCreateInvoiceLinesArgs = {
  invoiceLines: Array<InvoiceLinesIntroduceInput>;
};


export type MutationCreateInvoiceLinesFromFileDataArgs = {
  columnMappingId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
  lineData: Array<AddUploadedLinesInput>;
};


export type MutationCreateInvoiceLinesNextArgs = {
  input: InvoiceLineNextBatchIntroduceInput;
};


export type MutationCreateInvoiceLinesNextFromFileDataArgs = {
  columnMappingId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
  lineData: Array<AddUploadedLinesInputNext>;
};


export type MutationCreateInvoicesArgs = {
  companyId: Scalars['UUID'];
  invoices: Array<InvoiceIntroduceInput>;
};


export type MutationCreateInvoicesNextArgs = {
  companyId: Scalars['UUID'];
  invoiceCount: Scalars['Int'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['UUID'];
};


export type MutationCreateLiftArgs = {
  data: CreateLiftInput;
};


export type MutationCreateLiftBucketArgs = {
  data: CreateLiftBucketInput;
};


export type MutationCreateOrUpdateUserSettingArgs = {
  settingsType: UserSettingsTypes;
  value: Scalars['Boolean'];
};


export type MutationCreateOriginalFileColumnHeaderArgs = {
  input: CreateOriginalFileColumnHeaderInput;
};


export type MutationCreatePassThroughTypesArgs = {
  data: IntroducePassThroughTypesInput;
};


export type MutationCreatePaymentArgs = {
  input: PaymentIntroduceInput;
};


export type MutationCreatePermissionArgs = {
  data: PermissionInput;
};


export type MutationCreatePnlGroupsArgs = {
  data: Array<PnlGroupInput>;
  filters?: InputMaybe<PnlGroupFilter>;
};


export type MutationCreatePresetCustomerArgs = {
  data: PresetCustomerInput;
};


export type MutationCreatePresetCustomerAlternativeNameArgs = {
  data: PresetCustomerAlternativeNameInput;
};


export type MutationCreatePresetCustomerAlternativeNamesArgs = {
  data: Array<PresetCustomerAlternativeNameInput>;
  filters?: InputMaybe<PresetCustomerAlternativeNameFilter>;
};


export type MutationCreatePresetCustomersArgs = {
  data: Array<PresetCustomerInput>;
  filters?: InputMaybe<PresetCustomerFilter>;
};


export type MutationCreateProductArgs = {
  data: ProductInput;
};


export type MutationCreateProductBucketRelationshipArgs = {
  data: ProductBucketInput;
};


export type MutationCreateProductCodeArgs = {
  data: ProductCodeInput;
};


export type MutationCreateProductCodesArgs = {
  data: CreateProductCodesInput;
};


export type MutationCreateProductGroupArgs = {
  data: ProductGroupPartialInput;
};


export type MutationCreateProductGroupAlternativeNameArgs = {
  data: ProductGroupAlternativeNameInput;
};


export type MutationCreateProductGroupProductRelationshipArgs = {
  data: ProductGroupProductRelationshipInput;
};


export type MutationCreateProductsArgs = {
  data: Array<ProductInput>;
  filters?: InputMaybe<ProductFilter>;
};


export type MutationCreatePromotionArgs = {
  data: PromotionInput;
};


export type MutationCreatePromotionApprovalLimitArgs = {
  data: PromotionApprovalLimitInput;
};


export type MutationCreatePromotionApprovalLimitsArgs = {
  data: Array<PromotionApprovalLimitInput>;
  filters?: InputMaybe<PromotionApprovalLimitFilter>;
};


export type MutationCreatePromotionBatchWithLinesArgs = {
  data: Array<PromotionInput>;
};


export type MutationCreatePromotionLineArgs = {
  data: PromotionLineInput;
};


export type MutationCreatePromotionTagArgs = {
  data: CreatePromotionTagInput;
};


export type MutationCreatePromotionWithLinesArgs = {
  data: PromotionInput;
};


export type MutationCreateRemitTestDataArgs = {
  companyId: Scalars['UUID'];
};


export type MutationCreateResolutionActivityArgs = {
  input: ResolutionActivityIntroduceInput;
};


export type MutationCreateRevenueEntriesArgs = {
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  dataSourceId?: InputMaybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  revenueRecords: Array<RevenueEntryArgument>;
};


export type MutationCreateRevenueSourceArgs = {
  revSourceInput: IntroduceRevenueSourceInput;
};


export type MutationCreateRoleArgs = {
  data: CreateRoleInput;
};


export type MutationCreateRolePromotionFilterSettingsArgs = {
  data: RolePromotionFilterSettingsInput;
};


export type MutationCreateSpendArgs = {
  data: SpendInput;
};


export type MutationCreateSpendsArgs = {
  data: Array<SpendInput>;
  filters?: InputMaybe<SpendFilter>;
};


export type MutationCreateTokenArgs = {
  data: TokenInput;
};


export type MutationCreateTransactionArgs = {
  ignoreWarnings?: Scalars['Boolean'];
  input: TransactionIntroduceInput;
};


export type MutationCreateTransactionNextArgs = {
  ignoreWarnings?: Scalars['Boolean'];
  input: TransactionNextIntroduceInput;
};


export type MutationCreateUploadFileAndGetHeadersArgs = {
  companyFileId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
};


export type MutationCreateUploadFileNextAndGetHeadersArgs = {
  companyFileId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
};


export type MutationCreateUserArgs = {
  data: UserInput;
};


export type MutationCreateUserSettingArgs = {
  data: UserSettingsInput;
};


export type MutationCreateUserSettingsArgs = {
  data: Array<UserSettingsInput>;
};


export type MutationCreateUsersArgs = {
  data: Array<UserInput>;
  filters?: InputMaybe<UserFilter>;
};


export type MutationDeleteAccountArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAllInvoiceLinesArgs = {
  invoiceId: Scalars['UUID'];
};


export type MutationDeleteAllInvoiceLinesNextArgs = {
  invoiceId: Scalars['UUID'];
};


export type MutationDeleteAllLatestEstimateArgs = {
  companyId: Scalars['UUID'];
};


export type MutationDeleteAllPricingTimeFramesArgs = {
  companyId: Scalars['UUID'];
};


export type MutationDeleteBackupFileArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteBackupRetrievalsArgs = {
  filters?: InputMaybe<BackupRetrievalFilter>;
};


export type MutationDeleteBackupSourceGroupsArgs = {
  filters?: InputMaybe<DeleteBackupSourceGroupFilter>;
};


export type MutationDeleteBackupSourceSettingsOverrideArgs = {
  filters?: InputMaybe<BackupSourceSettingsOverrideFilter>;
};


export type MutationDeleteBackupSourceTemplateFilesArgs = {
  filters?: InputMaybe<DeleteBackupSourceTemplateFileFilter>;
};


export type MutationDeleteBackupSourcesArgs = {
  filters?: InputMaybe<DeleteBackupSourceFilter>;
};


export type MutationDeleteBudgetForYearsArgs = {
  columnToDelete: BudgetColumnToDelete;
  companyId: Scalars['UUID'];
  years: Array<Scalars['Int']>;
};


export type MutationDeleteBumpChartDatasArgs = {
  filters?: InputMaybe<BumpChartDataFilter>;
};


export type MutationDeleteBuyInWindowsArgs = {
  filters?: InputMaybe<BuyInWindowFilter>;
};


export type MutationDeleteCommentArgs = {
  data: DeleteCommentInput;
};


export type MutationDeleteCompanyBackupSourceRelationshipsArgs = {
  backupSourceId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationDeleteCompanyDefaultApprovalLimitArgs = {
  data: CompanyDefaultApprovalLimitPartialInput;
};


export type MutationDeleteCompanyFeatureGateRelationshipsArgs = {
  filters?: InputMaybe<CompanyFeatureGateFilter>;
};


export type MutationDeleteCompanyFeatureRelationshipsArgs = {
  filters?: InputMaybe<CompanyFeatureFilter>;
};


export type MutationDeleteCompanyFilesArgs = {
  filters?: InputMaybe<CompanyFileFilter>;
};


export type MutationDeleteCompanyProvidedServiceRelationshipsArgs = {
  filters?: InputMaybe<CompanyProvidedServiceFilter>;
};


export type MutationDeleteContactsArgs = {
  filters?: InputMaybe<ContactFilter>;
};


export type MutationDeleteCustomerAlternativeNameTagsArgs = {
  filters?: InputMaybe<CustomerAlternativeNameTagFilter>;
};


export type MutationDeleteCustomerAlternativeNamesArgs = {
  data: Array<Scalars['UUID']>;
};


export type MutationDeleteCustomerBucketRelationshipsArgs = {
  bucketId: Scalars['UUID'];
};


export type MutationDeleteCustomerTimeFramesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCustomerTimeFramesStrictArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCustomersArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeductionMatchingColumnOrderArgs = {
  filters?: InputMaybe<DeductionMatchingColumnOrderFilter>;
};


export type MutationDeleteDeductionScansArgs = {
  filters?: InputMaybe<DeductionScanFilter>;
};


export type MutationDeleteDiscountCutoffArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteEmailDomainArgs = {
  filters?: InputMaybe<EmailDomainFilter>;
};


export type MutationDeleteFilteredPosArgs = {
  data: DeleteFilteredPosInput;
};


export type MutationDeleteFilteredRevenueArgs = {
  data: DeleteFilteredRevenuesInput;
};


export type MutationDeleteForecastOverrideArgs = {
  companyId: Scalars['UUID'];
  customer: Scalars['UUID'];
  overrideType: OverrideType;
  product: Scalars['UUID'];
  version: Scalars['Int'];
  week: Scalars['Date'];
};


export type MutationDeleteForecastSeasonalityBucketArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteForecastSeasonalityIndexArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteForecastVersionArgs = {
  companyId: Scalars['UUID'];
  versionNum: Scalars['Int'];
};


export type MutationDeleteFreeTrialArgs = {
  filters?: InputMaybe<DsmFreeTrialFilter>;
};


export type MutationDeleteFundTypeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteFundTypeFieldArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteInternalFilesArgs = {
  filters?: InputMaybe<InternalFileFilter>;
};


export type MutationDeleteInvoiceLinesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteInvoiceLinesNextArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteLatestEstimateForYearsArgs = {
  companyId: Scalars['UUID'];
  years: Array<Scalars['Int']>;
};


export type MutationDeleteLiftArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteLiftBucketsArgs = {
  filters?: InputMaybe<LiftBucketFilter>;
};


export type MutationDeletePermissionsArgs = {
  data: PermissionFilter;
  filters?: InputMaybe<PermissionFilter>;
};


export type MutationDeletePnlGroupsArgs = {
  data: Array<Scalars['UUID']>;
};


export type MutationDeletePresetCustomerArgs = {
  filters?: InputMaybe<PresetCustomerFilter>;
};


export type MutationDeletePresetCustomerAlternativeNamesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeletePricingTimeFramesArgs = {
  input: Array<Scalars['UUID']>;
};


export type MutationDeleteProductBucketRelationshipsArgs = {
  forecastBucketId: Scalars['UUID'];
};


export type MutationDeleteProductCodesArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteProductGroupAlternativeNamesArgs = {
  filters?: InputMaybe<ProductGroupAlternativeNameFilter>;
};


export type MutationDeleteProductGroupProductRelationshipArgs = {
  data: ProductGroupProductRelationshipInput;
};


export type MutationDeleteProductGroupProductRelationshipsArgs = {
  data: BulkProductGroupProductRelationshipInput;
};


export type MutationDeleteProductGroupsArgs = {
  data: ProductGroupDeleteInput;
};


export type MutationDeleteProductsArgs = {
  data: ProductDeleteInput;
};


export type MutationDeletePromotionArgs = {
  data: DeletePromotionInput;
};


export type MutationDeletePromotionApprovalLimitArgs = {
  id: Scalars['UUID'];
};


export type MutationDeletePromotionAttachmentsArgs = {
  data: DeletePromotionAttachmentsInput;
};


export type MutationDeletePromotionLineArgs = {
  data: PromotionLineFilter;
  filters?: InputMaybe<PromotionLineFilter>;
};


export type MutationDeletePromotionTagArgs = {
  input: DeletePromotionTagInput;
};


export type MutationDeleteRevenueSourceArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRolePromotionFilterSettingsArgs = {
  data: RolePromotionFilterSettingsPartialInput;
};


export type MutationDeleteSpendsArgs = {
  filters?: InputMaybe<SpendFilter>;
};


export type MutationDeleteTokensArgs = {
  filters?: InputMaybe<TokenFilter>;
};


export type MutationDeleteUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};


export type MutationDownloadMissLogExportArgs = {
  companyId: Scalars['UUID'];
};


export type MutationDownloadOpenDeductionsReportArgs = {
  companyId: Scalars['UUID'];
};


export type MutationDownloadOpenDeductionsReportNextArgs = {
  companyId: Scalars['UUID'];
};


export type MutationDownloadSettlementReportArgs = {
  companyId: Scalars['UUID'];
};


export type MutationDownloadSettlementReportNextArgs = {
  input: SettlementReportInput;
};


export type MutationExportPromotionArgs = {
  data: ExportPromotionInput;
};


export type MutationExportSqlInstanceArgs = {
  data: ExportSqlInstanceRequest;
};


export type MutationGenerateBumpChartMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGenerateContactMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGenerateLatestEstimateMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGenerateLiftMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGeneratePointOfSaleMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  selectedRevenueSource: Scalars['UUID'];
};


export type MutationGeneratePriceElasticityDataArgs = {
  basePrice: Scalars['Float'];
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  endDate: Scalars['DateTime'];
  lineId: Scalars['UUID'];
  productIds: Array<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
};


export type MutationGeneratePricingMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  pricingUploadDate: Scalars['Date'];
};


export type MutationGenerateProductMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGeneratePromLineLiftPredictionArgs = {
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  endDate: Scalars['DateTime'];
  lineId: Scalars['UUID'];
  productIds: Array<Scalars['UUID']>;
  retailPrice: Scalars['Float'];
  startDate: Scalars['DateTime'];
};


export type MutationGeneratePromotionMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  isUpdateFlow?: InputMaybe<Scalars['Boolean']>;
};


export type MutationGenerateRevenueBudgetMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGenerateRevenueMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  selectedRevenueSource: Scalars['UUID'];
};


export type MutationGenerateSignedUrlArgs = {
  data: GenerateSignedUrlInput;
};


export type MutationGenerateSpendMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
};


export type MutationGenerateTransactionMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  selectedAccountingSource: Scalars['UUID'];
};


export type MutationGenerateTransactionNextMappedFileDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  selectedAccountingSource: Scalars['UUID'];
};


export type MutationGetPosCountArgs = {
  companyId: Scalars['UUID'];
};


export type MutationGetRevenueCountArgs = {
  companyId: Scalars['UUID'];
};


export type MutationGetUploadSignedUrlArgs = {
  companyId: Scalars['UUID'];
  fileMimeType: Scalars['String'];
};


export type MutationInitAutoAddBackupToCompaniesArgs = {
  autoAddBackupInput: AutoAddBackupInput;
};


export type MutationInitDbCopyArgs = {
  dbCopyInput: InitDbCopyInput;
};


export type MutationInitInternalDbCopyArgs = {
  dbCopyInput: InitInternalDbCopyInput;
};


export type MutationLinkInvoicesToTransactionArgs = {
  input: LinkInvoiceTransactionInput;
};


export type MutationMatchDeductionsArgs = {
  data: MatchDeductionsInput;
};


export type MutationMatchInvoiceLinesArgs = {
  companyId: Scalars['UUID'];
  invoiceId: Scalars['UUID'];
};


export type MutationMatchInvoiceLinesNextArgs = {
  companyId: Scalars['UUID'];
  fundTypesIds?: InputMaybe<Array<Scalars['UUID']>>;
  invoiceLineIds: Array<Scalars['UUID']>;
};


export type MutationMoveProductHierarchyArgs = {
  iput: MoveProductInput;
};


export type MutationOffsetInvoiceLineNextArgs = {
  input: SingleResolutionOffsetInput;
};


export type MutationParseOriginalColumnHeadersArgs = {
  companyFileId: Scalars['UUID'];
  companyId: Scalars['UUID'];
  readFirstSheetOnly?: Scalars['Boolean'];
  uploadFileType: CompanyFileCategories;
};


export type MutationQuickClearInvoicesArgs = {
  input: QuickClearByInvoicesInputWithPromotionLineId;
};


export type MutationQuickClearTransactionsByThresholdArgs = {
  input: QuickClearInputWithPromotionLineId;
};


export type MutationQuickWriteOffInvoicesArgs = {
  input: QuickWriteOffByInvoicesInput;
};


export type MutationQuickWriteOffTransactionsByThresholdArgs = {
  input: QuickWriteOffInput;
};


export type MutationRefreshDashboardMetricsArgs = {
  input: RefreshDashboardMetricsInput;
};


export type MutationRegisterUploadedFileArgs = {
  companyId: Scalars['UUID'];
  fileCategory?: InputMaybe<CompanyFileCategories>;
  fileMimeType: Scalars['String'];
  tempFileId: Scalars['UUID'];
  visibleName: Scalars['String'];
};


export type MutationRemoveBackupSourcesFromBackupSourceGroupArgs = {
  data: BackupSourceToBackupSourceGroupInput;
};


export type MutationRemoveFlagSingleInvoiceLineNextArgs = {
  input: SingleResolutionActivityFlagInput;
};


export type MutationRemoveFundTypeFromPassThroughTypesArgs = {
  fundTypeId: Scalars['UUID'];
  id: Scalars['UUID'];
};


export type MutationRemovePromotionTagFromPromotionArgs = {
  input: AddPromotionTagToPromotionInput;
};


export type MutationRemoveUsersFromCustomerArgs = {
  data: UserToCustomerInput;
};


export type MutationRenameForecastVersionArgs = {
  companyId: Scalars['UUID'];
  newName: Scalars['String'];
  versionNum: Scalars['Int'];
};


export type MutationRevisePricingTimeFramesSerializerValidationArgs = {
  data: Array<PartialPricingTimeFrameInput>;
};


export type MutationSaveEditedInvoiceLinesNextArgs = {
  input: Array<SaveEditedInvoiceLineNextInput>;
};


export type MutationScanInvoiceNextOriginalFileArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type MutationScanInvoiceOriginalFileArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type MutationSearchCustomerNamesAndAltNamesArgs = {
  companyId: Scalars['UUID'];
  customerUuidsToExclude?: InputMaybe<Array<Scalars['UUID']>>;
  searchNames: Array<Scalars['String']>;
};


export type MutationSearchPresetCustomerNamesAndAltNamesArgs = {
  searchNames: Array<Scalars['String']>;
};


export type MutationSetBudgetRevToZeroArgs = {
  companyId: Scalars['UUID'];
};


export type MutationSetBudgetSpendToZeroArgs = {
  companyId: Scalars['UUID'];
};


export type MutationSetIsContractArgs = {
  data: SetIsContractInput;
};


export type MutationSetLineAttachmentsForPromotionArgs = {
  data: SetLineAttachmentsForPromotionInput;
};


export type MutationSetLineIsContractArgs = {
  data: SetLineIsContractInput;
};


export type MutationStartProcessingArgs = {
  id: Scalars['UUID'];
};


export type MutationStartScrapingArgs = {
  id: Scalars['UUID'];
};


export type MutationSubmitBudgetDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: RevenueBudgetFileDataInput;
  ignoreWarnings?: Array<BudgetUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitBumpChartDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: BumpChartFileDataInput;
  ignoreWarnings?: Array<BumpChartUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitContactDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: ContactFileDataInput;
  ignoreWarnings?: Array<ContactUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitLatestEstimateDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: LatestEstimateFileDataInput;
  ignoreWarnings?: Array<LatestEstimateUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitLiftDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: LiftFileDataInput;
  ignoreWarnings?: Array<LiftUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitPointOfSaleDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: PointOfSaleFileDataInput;
  ignoreWarnings?: Array<PointOfSaleUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
  selectedRevenueSource: Scalars['UUID'];
};


export type MutationSubmitPricingDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: PricingFileDataInput;
  ignoreWarnings?: Array<PricingUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
  pricingUploadDate: Scalars['Date'];
};


export type MutationSubmitProductDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: ProductFileDataInput;
  ignoreWarnings?: Array<ProductUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitPromotionDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: PromotionFileDataInput;
  ignoreWarnings?: Array<PromotionUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  isUpdateFlow?: InputMaybe<Scalars['Boolean']>;
  parsedColumnHeaders: Array<Scalars['String']>;
  replaceExistingValues?: Scalars['Boolean'];
};


export type MutationSubmitRevenueDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: RevenueFileDataInput;
  ignoreWarnings?: Array<RevenueUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
  selectedRevenueSource: Scalars['UUID'];
};


export type MutationSubmitSpendDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: SpendFileDataInput;
  ignoreWarnings?: Array<SpendUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
};


export type MutationSubmitTransactionDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: TransactionFileDataInput;
  ignoreWarnings?: Array<TransactionUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
  selectedAccountingSource: Scalars['UUID'];
};


export type MutationSubmitTransactionNextDataArgs = {
  columnMappingList: Array<UserFileHeader>;
  companyId: Scalars['UUID'];
  data: TransactionFileDataInput;
  ignoreWarnings?: Array<TransactionUploadWarningEnum>;
  ignoredIndices?: Array<Scalars['Int']>;
  parsedColumnHeaders: Array<Scalars['String']>;
  selectedAccountingSource: Scalars['UUID'];
};


export type MutationTestLiftPredictionModelArgs = {
  companyId: Scalars['UUID'];
  modelName: Scalars['String'];
};


export type MutationToggleBackupNotAvailableArgs = {
  input: ToggleBackupNotAvailableInput;
};


export type MutationTrainLiftPredictionModelArgs = {
  modelName: Scalars['String'];
};


export type MutationTransferStorageObjectsArgs = {
  data: StorageTransferRequest;
};


export type MutationUnassignBackupFileFromInvoiceNextArgs = {
  id: Scalars['UUID'];
};


export type MutationUnassignFromStaffArgs = {
  userId: Scalars['UUID'];
};


export type MutationUnassignFundTypeFromInvoiceArgs = {
  fundType: Scalars['UUID'];
  id: Scalars['UUID'];
};


export type MutationUnassignFundTypeFromInvoiceNextArgs = {
  fundType: Scalars['UUID'];
  id: Scalars['UUID'];
};


export type MutationUnassignUserArgs = {
  data: CompanyUserRelationshipPartialInput;
};


export type MutationUnassignUserFromInvoiceArgs = {
  id: Scalars['UUID'];
  user: Scalars['UUID'];
};


export type MutationUnlinkInvoiceFromTransactionArgs = {
  input: UnlinkInvoiceFromTransactionInput;
};


export type MutationUpdateAllInvoiceLineStatusesForInvoicesArgs = {
  invoiceIds: Array<Scalars['UUID']>;
};


export type MutationUpdateAssignedUsersArgs = {
  input: AssignedUsersInput;
};


export type MutationUpdateBackupRetrievalArgs = {
  data: ReviseBackupRetrievalInput;
  id: Scalars['UUID'];
};


export type MutationUpdateBackupSourceArgs = {
  input: UpdateBackupSourceInput;
};


export type MutationUpdateBackupSourceGroupArgs = {
  input: UpdateBackupSourceGroupInput;
};


export type MutationUpdateBackupSourceSettingsOverrideArgs = {
  input: UpdateBackupSourceSettingsOverrideInput;
};


export type MutationUpdateBackupSourceTemplateFileArgs = {
  input: UpdateBackupSourceTemplateFileInput;
};


export type MutationUpdateBudgetDefaultViewArgs = {
  data: ReviseBudgetDefaultViewInput;
  filters?: InputMaybe<BudgetDefaultViewFilter>;
};


export type MutationUpdateBudgetDiffThresholdArgs = {
  data: ReviseBudgetDiffThresholdInput;
};


export type MutationUpdateBuyInWindowsArgs = {
  data: Array<BuyInWindowPartialInput>;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyUpdateInput;
};


export type MutationUpdateCompanyDefaultApprovalLimitArgs = {
  data: CompanyDefaultApprovalLimitPartialInput;
};


export type MutationUpdateCompanyScanningSettingsArgs = {
  data: CompanyScanningSettingPartialInput;
};


export type MutationUpdateContactArgs = {
  data: ContactPartialInput;
};


export type MutationUpdateContactsArgs = {
  data: ContactPartialInput;
  filters?: InputMaybe<ContactFilter>;
};


export type MutationUpdateCustomerArgs = {
  data: CustomerPartialInput;
  id: Scalars['UUID'];
};


export type MutationUpdateCustomerAlternativeNamesArgs = {
  data: Array<CustomerAlternativeNamePartialInput>;
};


export type MutationUpdateCustomerTimeFramesArgs = {
  data: Array<CustomerTimeFramePartialUpdateInput>;
};


export type MutationUpdateCustomersArgs = {
  data: CustomerPartialInput;
  ids: Array<Scalars['UUID']>;
};


export type MutationUpdateDeductionMatchingColumnMappingArgs = {
  input: UpdateDeductionMatchingColumnMappingInput;
};


export type MutationUpdateDeductionMatchingColumnOrderArgs = {
  input: UpdateDeductionMatchingColumnOrderInput;
};


export type MutationUpdateDeductionMatchingInstanceArgs = {
  input: UpdateDeductionMatchingInstanceInput;
};


export type MutationUpdateDeductionScanArgs = {
  input: UpdateDeductionScanInput;
};


export type MutationUpdateDeductionScanFilesArgs = {
  data: UpdateDeductionScanFileInput;
  filters?: InputMaybe<DeductionScanFileFilter>;
};


export type MutationUpdateDiscountCutoffArgs = {
  id: Scalars['UUID'];
  input: ReviseDiscountCutoffInput;
};


export type MutationUpdateDrmEventCommentTagsArgs = {
  data: ReviseDrmEventCommentTagInput;
};


export type MutationUpdateDrmEventCommentTagsNextArgs = {
  data: ReviseDrmEventCommentTagNextInput;
};


export type MutationUpdateForecastOverrideArgs = {
  forecastOverride: ForecastOverrideInput;
};


export type MutationUpdateForecastOverridesArgs = {
  forecastOverrides: Array<ForecastOverrideInput>;
  toWeek: Scalars['Date'];
  version?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateForecastSeasonalityBucketArgs = {
  bucketParams: UpdateSeasonalityBucketInput;
};


export type MutationUpdateForecastSeasonalityIndexesArgs = {
  data: ForecastSeasonalityPartialInput;
  filters?: InputMaybe<ForecastSeasonalityIndexFilter>;
};


export type MutationUpdateFreeTrialArgs = {
  data: UpdateDsmFreeTrialInput;
  filters?: InputMaybe<DsmFreeTrialFilter>;
};


export type MutationUpdateFundTypeArgs = {
  input: FundTypeUpdateInput;
};


export type MutationUpdateInternalFileArgs = {
  input: UpdateInternalFileInput;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['UUID'];
  input: InvoiceReviseInput;
};


export type MutationUpdateInvoiceLineArgs = {
  id: Scalars['UUID'];
  input: InvoiceLineReviseInput;
};


export type MutationUpdateInvoiceLineMatchesArgs = {
  companyId: Scalars['UUID'];
  lines: Array<ReviseInvoiceLineMatchesInput>;
};


export type MutationUpdateInvoiceLineMatchesNextArgs = {
  companyId: Scalars['UUID'];
  lines: Array<ReviseInvoiceLineNextMatchesInput>;
  manual?: Scalars['Boolean'];
  unmatch?: Scalars['Boolean'];
};


export type MutationUpdateInvoiceMapAndGenerateFileDataArgs = {
  invoiceId: Scalars['UUID'];
  mappingUpdates: UpdateInvoiceMappingInput;
};


export type MutationUpdateInvoiceNextArgs = {
  id: Scalars['UUID'];
  input: InvoiceReviseInputNext;
};


export type MutationUpdateInvoiceNextMapAndGenerateFileDataArgs = {
  invoiceId: Scalars['UUID'];
  mappingUpdates: UpdateInvoiceMappingInputNext;
};


export type MutationUpdateInvoicesNextArgs = {
  input: Array<BulkUpdateInvoiceInputNext>;
};


export type MutationUpdateLiftBucketsArgs = {
  data: UpdateLiftBucketInput;
};


export type MutationUpdateLiftsArgs = {
  data: UpdateLiftInput;
};


export type MutationUpdateLineActualLiftsArgs = {
  companyId: Scalars['UUID'];
};


export type MutationUpdatePaymentArgs = {
  input: PaymentReviseInput;
};


export type MutationUpdatePaymentsArgs = {
  input: Array<PaymentReviseInput>;
};


export type MutationUpdatePermissionsArgs = {
  data: PermissionPartialInput;
  filters?: InputMaybe<PermissionFilter>;
};


export type MutationUpdatePnlGroupsArgs = {
  data: PnlGroupPartialInput;
  filters?: InputMaybe<PnlGroupFilter>;
};


export type MutationUpdatePnlGroupsNamesArgs = {
  data: Array<PnlGroupNameUpdateInput>;
};


export type MutationUpdatePresetCustomerArgs = {
  data: PresetCustomerPartialInput;
};


export type MutationUpdatePresetCustomerAlternativeNamesArgs = {
  data: Array<PresetCustomerAlternativeNamePartialInput>;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  id: Scalars['UUID'];
};


export type MutationUpdateProductGroupAlternateNameCustomArgs = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type MutationUpdateProductGroupsArgs = {
  data: ProductGroupPartialInput;
};


export type MutationUpdatePromotionApprovalLimitsArgs = {
  data: PromotionApprovalLimitPartialInput;
  filters?: InputMaybe<PromotionApprovalLimitFilter>;
};


export type MutationUpdatePromotionLineArgs = {
  data: PromotionLinePartialInput;
  filters?: InputMaybe<PromotionLineFilter>;
};


export type MutationUpdatePromotionLineLiftArgs = {
  data: UpdatePromotionLineLiftInput;
};


export type MutationUpdatePromotionNotificationSettingArgs = {
  id: Scalars['UUID'];
  isEnabled: Scalars['Boolean'];
};


export type MutationUpdatePromotionStarArgs = {
  data: UpdatePromotionStarInput;
};


export type MutationUpdatePromotionStatusArgs = {
  data: UpdatePromotionStatusInput;
};


export type MutationUpdatePromotionTagArgs = {
  input: UpdatePromotionTagInput;
};


export type MutationUpdatePromotionTagsArgs = {
  data: UpdatePromotionTagsInput;
};


export type MutationUpdatePromotionWithLinesArgs = {
  data: PromotionPartialInput;
};


export type MutationUpdateQuickClearThresholdArgs = {
  input: QuickClearThresholdInput;
};


export type MutationUpdateRemitArgs = {
  input: RemitReviseInput;
};


export type MutationUpdateRemitsArgs = {
  input: Array<RemitReviseInput>;
};


export type MutationUpdateResolutionActivityArgs = {
  id: Scalars['UUID'];
  input: ResolutionActivityReviseInput;
};


export type MutationUpdateRevenueSourceArgs = {
  updateInput: ReviseRevenueSourceInput;
};


export type MutationUpdateRoleArgs = {
  data: UpdateRoleInput;
  id: Scalars['UUID'];
};


export type MutationUpdateRolePromotionFilterSettingsArgs = {
  data: RolePromotionFilterSettingsPartialInput;
};


export type MutationUpdateSpendSplitsArgs = {
  data: Array<UpdateSpendSplitInput>;
};


export type MutationUpdateTransactionArgs = {
  id: Scalars['UUID'];
  input: TransactionReviseInput;
};


export type MutationUpdateTransactionNextArgs = {
  id: Scalars['UUID'];
  input: TransactionNextReviseInput;
};


export type MutationUpdateTransactionNextBackupsArgs = {
  input: UpdateTransactionNextBackupsInput;
};


export type MutationUpdateUserSettingsArgs = {
  id: Scalars['UUID'];
  value: Scalars['Boolean'];
};


export type MutationUpdateUsersArgs = {
  data: UserPartialInput;
  filters?: InputMaybe<UserFilter>;
};


export type MutationUploadErpTransactionsArgs = {
  companyId: Scalars['UUID'];
  transactions: Array<ErpTransaction>;
};


export type MutationUploadErpTransactionsFlatArgs = {
  companyId: Scalars['UUID'];
  transactions: Array<ErpTransactionFlat>;
};


export type MutationUploadPromotionTagArgs = {
  requestObj: PromotionTagBulkUploadInput;
};


export type MutationUpsertSalesReviewNotificationSettingsArgs = {
  data: SalesReviewNotificationSettingsInput;
};


export type MutationViewAllEventCommentTagsArgs = {
  companyId: Scalars['UUID'];
};


export type MutationViewAllEventCommentTagsNextArgs = {
  companyId: Scalars['UUID'];
};


export type MutationVoidClearWriteoffResolutionLinesArgs = {
  input: VoidResolutionActivitiesInput;
};

export type NamesAndAltNames = {
  altNames: Array<Scalars['String']>;
  customerNames: Array<Scalars['String']>;
};

export type NotificationTimeInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export enum NumberComparison {
  Ge = 'GE',
  Lt = 'LT'
}

export type OffsetPaginationInput = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type OldestLine = {
  id: Scalars['UUID'];
  numberOfDaysFromOldestLineToToday: Scalars['String'];
  openAmount: Scalars['Float'];
  salesReviewDate: Scalars['String'];
};

export type OpenDeductionsReportNextNode = {
  erpTransactionId: Scalars['String'];
  id: Scalars['UUID'];
  invoiceBackupAttached: Scalars['Boolean'];
  invoiceEndDate?: Maybe<Scalars['Date']>;
  invoiceLineCustomerName?: Maybe<Scalars['String']>;
  invoiceLineFlagged?: Maybe<InvoiceLineFlags>;
  invoiceLineFundTypeName?: Maybe<Scalars['String']>;
  invoiceLineMatchedPromotionLine?: Maybe<Scalars['String']>;
  invoiceLineOpenAmount: Scalars['Float'];
  invoiceLineProductGroupName?: Maybe<Scalars['String']>;
  invoiceLineProductName?: Maybe<Scalars['String']>;
  invoiceLineStatus?: Maybe<InvoiceLineNextStatuses>;
  invoiceLineTotalAmount: Scalars['Float'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceOpenAmount: Scalars['Float'];
  invoiceStatus?: Maybe<InvoiceStatuses>;
  invoiceTotalAmount: Scalars['Float'];
  transactionBackupAttached: Scalars['Boolean'];
  transactionCheckNumber?: Maybe<Scalars['String']>;
  transactionCustomerName: Scalars['String'];
  transactionDate: Scalars['Date'];
  transactionDaysOutstanding: Scalars['Int'];
  transactionImportDate: Scalars['String'];
  transactionOpenAmount: Scalars['Float'];
  transactionProcessor?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatuses;
  transactionTotalAmount: Scalars['Float'];
  transactionType: TransactionTypes;
};

export type OpenDeductionsReportNode = {
  erpTransactionId: Scalars['String'];
  id: Scalars['UUID'];
  invoiceEndDate?: Maybe<Scalars['Date']>;
  invoiceLineCustomerName?: Maybe<Scalars['String']>;
  invoiceLineFundTypeName?: Maybe<Scalars['String']>;
  invoiceLineOpenAmount: Scalars['Float'];
  invoiceLineProductName?: Maybe<Scalars['String']>;
  invoiceLineTotalAmount: Scalars['Float'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceStartDate?: Maybe<Scalars['Date']>;
  transactionBackupAttached: Scalars['Boolean'];
  transactionCheckNumber?: Maybe<Scalars['String']>;
  transactionCustomerName: Scalars['String'];
  transactionDate: Scalars['Date'];
  transactionDaysOutstanding: Scalars['Int'];
  transactionOpenAmount: Scalars['Float'];
  transactionProcessor?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatuses;
  transactionTotalAmount: Scalars['Float'];
  transactionType: TransactionTypes;
};

export type OrderByLiftValue = {
  liftValueOrder?: InputMaybe<Scalars['String']>;
  productGroupId?: InputMaybe<Scalars['UUID']>;
};

export enum Ordering {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OriginalFileColumnHeaderFilter = {
  AND?: InputMaybe<OriginalFileColumnHeaderFilter>;
  NOT?: InputMaybe<OriginalFileColumnHeaderFilter>;
  OR?: InputMaybe<OriginalFileColumnHeaderFilter>;
  columnHeader?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  sheetNumber?: InputMaybe<IntFilterLookup>;
  uploadedFile?: InputMaybe<CompanyFileFilter>;
};

export type OriginalFileColumnHeaderNode = {
  columnHeader: Scalars['String'];
  columnHeaderLabel: DeductionMatchingColumnHeaders;
  id: Scalars['UUID'];
  sheetNumber: Scalars['Int'];
  uploadedFile: CompanyFileNode;
};

export type OriginalFileColumnHeaderNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<OriginalFileColumnHeaderNode>;
};

export enum OverrideType {
  AddedUnits = 'ADDED_UNITS',
  BaseSales = 'BASE_SALES',
  Stores = 'STORES',
  Velocity = 'VELOCITY'
}

export type PnlGroupFilter = {
  AND?: InputMaybe<PnlGroupFilter>;
  NOT?: InputMaybe<PnlGroupFilter>;
  OR?: InputMaybe<PnlGroupFilter>;
  company?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type PnlGroupInput = {
  company: Scalars['UUID'];
  groupColor: Scalars['String'];
  name: Scalars['String'];
};

export type PnlGroupNameUpdateInput = {
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

export type PnlGroupNode = {
  company: CompanyNode;
  customers: Array<CustomerNode>;
  groupColor: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type PnlGroupNodeCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
};

export type PnlGroupPartialInput = {
  company: Scalars['UUID'];
  groupColor?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PosFilter = {
  AND?: InputMaybe<PosFilter>;
  NOT?: InputMaybe<PosFilter>;
  OR?: InputMaybe<PosFilter>;
  company?: InputMaybe<CompanyFilter>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DatetimeFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  omni?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductFilter>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  source?: InputMaybe<RevenueSourceFilter>;
};

export type PosNode = {
  arp: Scalars['Float'];
  baseUnits: Scalars['Float'];
  company: CompanyNode;
  customer: CustomerNode;
  date: Scalars['DateTime'];
  dollarSales: Scalars['Float'];
  eqUnits: Scalars['Float'];
  id: Scalars['UUID'];
  incrUnits: Scalars['Float'];
  numStores?: Maybe<Scalars['Float']>;
  product: ProductNode;
  source?: Maybe<RevenueSourceNode>;
  tdp?: Maybe<Scalars['Float']>;
  tdpAnyPromo?: Maybe<Scalars['Float']>;
};

export type PosNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<PosNode>;
};

export type PosOrder = {
  arp?: InputMaybe<Ordering>;
  baseUnits?: InputMaybe<Ordering>;
  date?: InputMaybe<Ordering>;
  dollarSales?: InputMaybe<Ordering>;
  eqUnits?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  incrUnits?: InputMaybe<Ordering>;
  numStores?: InputMaybe<Ordering>;
  tdp?: InputMaybe<Ordering>;
  tdpAnyPromo?: InputMaybe<Ordering>;
};

export type PageInfo = {
  totalCount: Scalars['Int'];
};

export type PaginationInputConfig = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PartialPricingTimeFrameInput = {
  amount?: InputMaybe<Scalars['Float']>;
  company?: InputMaybe<Scalars['UUID']>;
  customer?: InputMaybe<Scalars['UUID']>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
};

export type PassThroughTypesNode = {
  company: CompanyNode;
  fundTypes: Array<FundTypeNode>;
  id: Scalars['UUID'];
};


export type PassThroughTypesNodeFundTypesArgs = {
  filters?: InputMaybe<FundTypeFilter>;
};

export type PaymentFilter = {
  AND?: InputMaybe<PaymentFilter>;
  NOT?: InputMaybe<PaymentFilter>;
  OR?: InputMaybe<PaymentFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DateFilterLookup>;
  flag?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  paymentNumber?: InputMaybe<StrFilterLookup>;
  status?: InputMaybe<StrFilterLookup>;
};

export enum PaymentFlags {
  NeedsReview = 'NEEDS_REVIEW'
}

export type PaymentIntroduceInput = {
  amount: Scalars['Float'];
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  company: Scalars['UUID'];
  customer: Scalars['UUID'];
  date: Scalars['Date'];
  paymentNumber: Scalars['String'];
};

export type PaymentNode = {
  amount: Scalars['Float'];
  assignedUsers?: Maybe<Array<UserNode>>;
  customer: CustomerNode;
  date: Scalars['Date'];
  flag?: Maybe<PaymentFlags>;
  id: Scalars['UUID'];
  paymentNumber: Scalars['String'];
  status: PaymentStatuses;
};


export type PaymentNodeAssignedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};

export type PaymentNodePaymentPaginationConnection = {
  pageInfo: PageInfo;
  results: Array<PaymentNode>;
  summary: PaymentSummary;
};

export type PaymentOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type PaymentReviseInput = {
  amount?: InputMaybe<Scalars['Float']>;
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  company?: InputMaybe<Scalars['UUID']>;
  customer?: InputMaybe<Scalars['UUID']>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['UUID'];
  paymentNumber?: InputMaybe<Scalars['String']>;
};

export enum PaymentStatuses {
  Applied = 'APPLIED',
  InProgress = 'IN_PROGRESS',
  NotApplied = 'NOT_APPLIED'
}

export type PaymentSummary = {
  allPaymentIds: Array<Scalars['UUID']>;
  totalAmount: Scalars['Float'];
};

export type PermissionFilter = {
  AND?: InputMaybe<PermissionFilter>;
  NOT?: InputMaybe<PermissionFilter>;
  OR?: InputMaybe<PermissionFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  requiredFeature?: InputMaybe<Feature>;
  requiredTier?: InputMaybe<Tier>;
};

export type PermissionInput = {
  description?: InputMaybe<Scalars['String']>;
  internalOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  requiredFeature: Feature;
  requiredTier: Tier;
};

export enum PermissionName {
  Add = 'ADD',
  AddContacts = 'ADD_CONTACTS',
  Admin = 'ADMIN',
  ApprovalInvoices = 'APPROVAL_INVOICES',
  Brokers = 'BROKERS',
  CancelInvoicesTransactions = 'CANCEL_INVOICES_TRANSACTIONS',
  DeductionsReconciliation = 'DEDUCTIONS_RECONCILIATION',
  DeductionsSpend = 'DEDUCTIONS_SPEND',
  DeductionsThreshold = 'DEDUCTIONS_THRESHOLD',
  DrmResolution = 'DRM_RESOLUTION',
  Edit = 'EDIT',
  EditContacts = 'EDIT_CONTACTS',
  Forecast = 'FORECAST',
  ForecastEdit = 'FORECAST_EDIT',
  ForecastVersioning = 'FORECAST_VERSIONING',
  Lift = 'LIFT',
  ManageSalesEmails = 'MANAGE_SALES_EMAILS',
  MasterEdit = 'MASTER_EDIT',
  PastPromotionCreation = 'PAST_PROMOTION_CREATION',
  Pricing = 'PRICING',
  Promotions = 'PROMOTIONS',
  PromotionsUploadCreate = 'PROMOTIONS_UPLOAD_CREATE',
  PromotionsUploadEdit = 'PROMOTIONS_UPLOAD_EDIT',
  PromotionApproval = 'PROMOTION_APPROVAL',
  PromotionCancellation = 'PROMOTION_CANCELLATION',
  PromotionClosure = 'PROMOTION_CLOSURE',
  PromotionLineClosure = 'PROMOTION_LINE_CLOSURE',
  PromotionSubmission = 'PROMOTION_SUBMISSION',
  RevenueDollars = 'REVENUE_DOLLARS',
  Users = 'USERS',
  ViewBusiness = 'VIEW_BUSINESS',
  ViewCommissions = 'VIEW_COMMISSIONS',
  ViewInsights = 'VIEW_INSIGHTS',
  ViewManage = 'VIEW_MANAGE'
}

export type PermissionNode = {
  companyRoles?: Maybe<Array<RoleNode>>;
  description: Scalars['String'];
  id: Scalars['UUID'];
  internalOnly: Scalars['Boolean'];
  name: Scalars['String'];
  requiredFeature?: Maybe<Feature>;
  requiredTier?: Maybe<Tier>;
  roles: Array<RoleNode>;
};


export type PermissionNodeCompanyRolesArgs = {
  filters?: InputMaybe<RoleFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type PermissionNodeRolesArgs = {
  filters?: InputMaybe<RoleFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type PermissionOrder = {
  name?: InputMaybe<Ordering>;
};

export type PermissionPartialInput = {
  description?: InputMaybe<Scalars['String']>;
  internalOnly?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  requiredFeature: Feature;
  requiredTier: Tier;
};

export type PointOfSaleColumnMapping = {
  arp: Scalars['String'];
  baseUnits: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  dollarSales: Scalars['String'];
  eqUnits: Scalars['String'];
  incrUnits: Scalars['String'];
  numStores: Scalars['String'];
  productName: Scalars['String'];
  tdp: Scalars['String'];
  tdpAnyPromo: Scalars['String'];
};

export type PointOfSaleFileData = {
  arp: Array<Scalars['String']>;
  baseUnits: Array<Scalars['String']>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  dollarSales: Array<Scalars['String']>;
  eqUnits: Array<Scalars['String']>;
  incrUnits: Array<Scalars['String']>;
  numStores: Array<Scalars['String']>;
  productName: Array<Scalars['String']>;
  tdp: Array<Maybe<Scalars['String']>>;
  tdpAnyPromo: Array<Maybe<Scalars['String']>>;
};

export type PointOfSaleFileDataInput = {
  arp: Array<Scalars['String']>;
  baseUnits: Array<Scalars['String']>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  dollarSales: Array<Scalars['String']>;
  eqUnits: Array<Scalars['String']>;
  incrUnits: Array<Scalars['String']>;
  numStores: Array<Scalars['String']>;
  productName: Array<Scalars['String']>;
  tdp: Array<InputMaybe<Scalars['String']>>;
  tdpAnyPromo: Array<InputMaybe<Scalars['String']>>;
};

export type PointOfSaleFileDataOutput = PointOfSaleFileData & {
  arp: Array<Scalars['String']>;
  baseUnits: Array<Scalars['String']>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  dollarSales: Array<Scalars['String']>;
  eqUnits: Array<Scalars['String']>;
  incrUnits: Array<Scalars['String']>;
  numStores: Array<Scalars['String']>;
  productName: Array<Scalars['String']>;
  tdp: Array<Maybe<Scalars['String']>>;
  tdpAnyPromo: Array<Maybe<Scalars['String']>>;
};

export type PointOfSaleGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: PointOfSaleUploadWarningEnum;
};

export type PointOfSaleMappedFileResponse = {
  columnMapping: PointOfSaleColumnMapping;
  fileData: PointOfSaleFileDataOutput;
  validationResults: PointOfSaleUploadValidationResponse;
};

export type PointOfSaleUploadErrors = {
  alreadyInDatabase?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  productNotFound?: Maybe<Array<Scalars['Int']>>;
};

export type PointOfSaleUploadValidationResponse = {
  errors?: Maybe<PointOfSaleUploadErrors>;
  warnings?: Maybe<PointOfSaleUploadWarnings>;
};

export enum PointOfSaleUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type PointOfSaleUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: PointOfSaleGroupedIndiceWarning;
};

export type PresetCustomerAlternativeNameFilter = {
  AND?: InputMaybe<PresetCustomerAlternativeNameFilter>;
  NOT?: InputMaybe<PresetCustomerAlternativeNameFilter>;
  OR?: InputMaybe<PresetCustomerAlternativeNameFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  presetCustomer?: InputMaybe<PresetCustomerFilter>;
};

export type PresetCustomerAlternativeNameInput = {
  name: Scalars['String'];
  presetCustomer?: InputMaybe<Scalars['UUID']>;
  tags: Array<Scalars['String']>;
};

export type PresetCustomerAlternativeNameNode = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  presetCustomer: PresetCustomerNode;
  tags: Array<Scalars['String']>;
};

export type PresetCustomerAlternativeNamePartialInput = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  presetCustomer?: InputMaybe<Scalars['UUID']>;
  tags: Array<Scalars['String']>;
};

export type PresetCustomerFilter = {
  AND?: InputMaybe<PresetCustomerFilter>;
  NOT?: InputMaybe<PresetCustomerFilter>;
  OR?: InputMaybe<PresetCustomerFilter>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type PresetCustomerInput = {
  customerType?: InputMaybe<CustomerType>;
  name: Scalars['String'];
};

export type PresetCustomerNode = {
  alternativeNames: Array<PresetCustomerAlternativeNameNode>;
  customerType: CustomerType;
  id: Scalars['UUID'];
  name: Scalars['String'];
};


export type PresetCustomerNodeAlternativeNamesArgs = {
  filters?: InputMaybe<PresetCustomerAlternativeNameFilter>;
};

export type PresetCustomerPartialInput = {
  customerType?: InputMaybe<CustomerType>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum PresetDb {
  Beta = 'BETA',
  Other = 'OTHER',
  Prod = 'PROD',
  Sandbox = 'SANDBOX'
}

export type PriceElasticityOutput = {
  dataPoints: Array<PriceElasticityPoint>;
  success: Scalars['Boolean'];
};

export type PriceElasticityPoint = {
  predictedLift: Scalars['Float'];
  promotedPrice: Scalars['Float'];
};

export type PricingColumnMapping = {
  customerName: Scalars['String'];
  price: Scalars['String'];
  productGroupName: Scalars['String'];
};

export type PricingFileData = {
  customerName: Array<Scalars['String']>;
  price: Array<Scalars['String']>;
  productGroupName: Array<Scalars['String']>;
};

export type PricingFileDataInput = {
  customerName: Array<Scalars['String']>;
  price: Array<Scalars['String']>;
  productGroupName: Array<Scalars['String']>;
};

export type PricingFileDataOutput = PricingFileData & {
  customerName: Array<Scalars['String']>;
  price: Array<Scalars['String']>;
  productGroupName: Array<Scalars['String']>;
};

export type PricingGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: PricingUploadWarningEnum;
};

export type PricingMappedFileResponse = {
  columnMapping: PricingColumnMapping;
  fileData: PricingFileDataOutput;
  validationResults: PricingUploadValidationResponse;
};

export type PricingTimeFrameFilter = {
  AND?: InputMaybe<PricingTimeFrameFilter>;
  NOT?: InputMaybe<PricingTimeFrameFilter>;
  OR?: InputMaybe<PricingTimeFrameFilter>;
  company?: InputMaybe<DjangoModelFilterInput>;
  customer?: InputMaybe<DjangoModelFilterInput>;
  effectiveDate?: InputMaybe<DateFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  productGroup?: InputMaybe<DjangoModelFilterInput>;
};

export type PricingTimeFrameInput = {
  amount?: InputMaybe<Scalars['Float']>;
  company?: InputMaybe<Scalars['UUID']>;
  customer?: InputMaybe<Scalars['UUID']>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
};

export type PricingTimeFrameNode = {
  amount?: Maybe<Scalars['Float']>;
  company: CompanyNode;
  customer: CustomerNode;
  customerId: Scalars['String'];
  customerTimeFrame?: Maybe<Array<CustomerTimeFrameNode>>;
  effectiveDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['UUID'];
  productGroup: ProductGroupNode;
  productGroupId: Scalars['String'];
};

export type PricingTimeFrameOrder = {
  dateRange?: InputMaybe<Ordering>;
  effectiveDate?: InputMaybe<Ordering>;
};

export type PricingUploadErrors = {
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  indirectRetailers?: Maybe<Array<Scalars['Int']>>;
  productGroupNotFound?: Maybe<Array<Scalars['Int']>>;
};

export type PricingUploadValidationResponse = {
  errors?: Maybe<PricingUploadErrors>;
  warnings?: Maybe<PricingUploadWarnings>;
};

export enum PricingUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type PricingUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: PricingGroupedIndiceWarning;
};

export type ProductBucketInput = {
  bucket: Scalars['UUID'];
  product: Scalars['UUID'];
};

export type ProductBucketRelationshipFilter = {
  AND?: InputMaybe<ProductBucketRelationshipFilter>;
  NOT?: InputMaybe<ProductBucketRelationshipFilter>;
  OR?: InputMaybe<ProductBucketRelationshipFilter>;
  bucket?: InputMaybe<ForecastSeasonalityBucketFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  product?: InputMaybe<ProductFilter>;
};

export type ProductBucketRelationshipNode = {
  bucket: ForecastSeasonalityBucketNode;
  id: Scalars['UUID'];
  product: ProductNode;
};

export type ProductBucketRelationshipOrder = {
  product?: InputMaybe<ProductOrder>;
};

export type ProductCodeFilter = {
  AND?: InputMaybe<ProductCodeFilter>;
  NOT?: InputMaybe<ProductCodeFilter>;
  OR?: InputMaybe<ProductCodeFilter>;
  code?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  product?: InputMaybe<ProductFilter>;
};

export type ProductCodeInput = {
  code: Scalars['String'];
  productId: Scalars['UUID'];
};

export type ProductCodeNode = {
  code: Scalars['String'];
  id: Scalars['UUID'];
  product: DjangoModelType;
};

export type ProductCodeOrder = {
  code?: InputMaybe<Ordering>;
  company_Name?: InputMaybe<Ordering>;
};

export type ProductColumnMapping = {
  codes: Scalars['String'];
  productName: Scalars['String'];
  size: Scalars['String'];
  unitsPerCase: Scalars['String'];
};

export type ProductDeleteInput = {
  id: Scalars['UUID'];
};

export type ProductFileData = {
  codes: Array<Maybe<Scalars['String']>>;
  productName: Array<Scalars['String']>;
  size: Array<Maybe<Scalars['String']>>;
  unitsPerCase: Array<Maybe<Scalars['String']>>;
};

export type ProductFileDataInput = {
  codes: Array<InputMaybe<Scalars['String']>>;
  productName: Array<Scalars['String']>;
  size: Array<InputMaybe<Scalars['String']>>;
  unitsPerCase: Array<InputMaybe<Scalars['String']>>;
};

export type ProductFileDataOutput = ProductFileData & {
  codes: Array<Maybe<Scalars['String']>>;
  productName: Array<Scalars['String']>;
  size: Array<Maybe<Scalars['String']>>;
  unitsPerCase: Array<Maybe<Scalars['String']>>;
};

export type ProductFilter = {
  AND?: InputMaybe<ProductFilter>;
  NOT?: InputMaybe<ProductFilter>;
  OR?: InputMaybe<ProductFilter>;
  company?: InputMaybe<CompanyFilter>;
  forecastSeasonalityBucket?: InputMaybe<ForecastSeasonalityBucketFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  omni?: Scalars['String'];
  size?: InputMaybe<StrFilterLookup>;
  unitsPerCase?: InputMaybe<IntFilterLookup>;
};

export type ProductGroupAlternativeNameFilter = {
  AND?: InputMaybe<ProductGroupAlternativeNameFilter>;
  NOT?: InputMaybe<ProductGroupAlternativeNameFilter>;
  OR?: InputMaybe<ProductGroupAlternativeNameFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  productGroup?: InputMaybe<ProductGroupFilter>;
};

export type ProductGroupAlternativeNameInput = {
  name: Scalars['String'];
  productGroup: Scalars['UUID'];
};

export type ProductGroupAlternativeNameNode = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  productGroup: ProductGroupNode;
};

export type ProductGroupAlternativeNameOrder = {
  name?: InputMaybe<Ordering>;
  productGroup_Name?: InputMaybe<Ordering>;
};

export type ProductGroupDeleteInput = {
  id: Scalars['UUID'];
};

export type ProductGroupFilter = {
  AND?: InputMaybe<ProductGroupFilter>;
  NOT?: InputMaybe<ProductGroupFilter>;
  OR?: InputMaybe<ProductGroupFilter>;
  company?: InputMaybe<CompanyFilter>;
  grossMargin?: InputMaybe<FloatFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  isPricing?: InputMaybe<Scalars['Boolean']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StrFilterLookup>;
  productGroupAlternativeNames?: InputMaybe<ProductGroupAlternativeNameFilter>;
  products?: InputMaybe<ProductFilter>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type ProductGroupNextCreateErrors = {
  /** Database Integrity Error such as unique constraint error */
  dbIntegrityError?: Maybe<Scalars['String']>;
  /** The hierarchy integrity is violated with the new entry */
  hierarchyIntegrityViolation?: Maybe<Scalars['Boolean']>;
  /** The parent product group doesn't exist. */
  parentPgDoesNotExistInHierarchy?: Maybe<Scalars['Boolean']>;
};

export type ProductGroupNextCreateResponse = {
  data?: Maybe<ProductGroupNextCreateSuccess>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<ProductGroupNextCreateErrors>;
};

export type ProductGroupNextCreateSuccess = {
  parentProductGroup?: Maybe<ProductGroupNode>;
  productGroup: ProductGroupNode;
};

export type ProductGroupNextInput = {
  companyId: Scalars['UUID'];
  grossMargin?: InputMaybe<Scalars['Float']>;
  isPricing?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** Parent Product Group ID, if any at all. If not provided, then this is a root product group. */
  parentPg?: InputMaybe<Scalars['UUID']>;
};

export type ProductGroupNode = {
  company: CompanyNode;
  grossMargin?: Maybe<Scalars['Float']>;
  id: Scalars['UUID'];
  isPricing: Scalars['Boolean'];
  isRoot: Scalars['Boolean'];
  name: Scalars['String'];
  pricingTimeFrames: Array<PricingTimeFrameNode>;
  productGroupAlternativeNames: Array<ProductGroupAlternativeNameNode>;
  products: Array<ProductNode>;
};


export type ProductGroupNodePricingTimeFramesArgs = {
  filters?: InputMaybe<PricingTimeFrameFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type ProductGroupNodeProductsArgs = {
  filters?: InputMaybe<ProductFilter>;
};

export type ProductGroupOrder = {
  company_Name?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  grossMargin?: InputMaybe<Ordering>;
  isPricing?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

export type ProductGroupPartialInput = {
  companyId?: InputMaybe<Scalars['UUID']>;
  grossMargin?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['UUID']>;
  isPricing?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductGroupProductRelationshipInput = {
  deleteAnyway?: InputMaybe<Scalars['Boolean']>;
  productGroupId: Scalars['UUID'];
  productId: Scalars['UUID'];
};

export type ProductGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: ProductUploadWarningEnum;
};

export type ProductHierarchyList = {
  ancestorPg?: Maybe<ProductHierarchyNode>;
  currentNode?: Maybe<ProductHierarchyNode>;
  descendants: Array<ProductHierarchyNode>;
  steplen: Scalars['Int'];
  /** The total number of descendants of the current node. */
  totalDescendants: Scalars['Int'];
};

export type ProductHierarchyNextOptions = {
  /** If true, only direct children are returned otherwise all descendants, including children of children will be returned. */
  getOnlyDirectChildren?: InputMaybe<Scalars['Boolean']>;
  /** If true, only product groups are returned. */
  getOnlyProductGroups?: InputMaybe<Scalars['Boolean']>;
  /** If true, the parent product group of the current node will be returned. */
  getParentPg?: InputMaybe<Scalars['Boolean']>;
  /** The parent product group ID to get the children of; if not provided, the root product group will be used. */
  parentPgId?: InputMaybe<Scalars['ID']>;
  /** The date from which the created_at field of the node should be considered; if not provided, all nodes will be considered. */
  validFrom?: InputMaybe<Scalars['DateTime']>;
  /** The date till which the deleted_at field of the node should be considered; if not provided, all nodes will be considered. */
  validTill?: InputMaybe<Scalars['DateTime']>;
};

export type ProductHierarchyNode = {
  depth: Scalars['Int'];
  doesNodeRepresentProductGroup: Scalars['Boolean'];
  nodeLabel: ProductHierarchyNodeLabels;
  path: Scalars['String'];
  product?: Maybe<ProductNode>;
  productGroup?: Maybe<ProductGroupNode>;
};

export enum ProductHierarchyNodeLabels {
  CategoricalPg = 'CATEGORICAL_PG',
  ImpurePg = 'IMPURE_PG',
  Product = 'PRODUCT',
  PurePg = 'PURE_PG'
}

export type ProductInput = {
  companyId: Scalars['UUID'];
  customProductField1?: InputMaybe<Scalars['String']>;
  forecastSeasonalityBucket?: InputMaybe<Scalars['UUID']>;
  legacyId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productNumber?: InputMaybe<Scalars['String']>;
  profitCenter?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  unitsPerCase?: InputMaybe<Scalars['Int']>;
};

export type ProductMappedFileResponse = {
  columnMapping: ProductColumnMapping;
  fileData: ProductFileDataOutput;
  validationResults: ProductUploadValidationResponse;
};

export type ProductNextCreateErrors = {
  /** Hierarchy Integrity Violation while adding product */
  hierarchyIntegrityViolation?: Maybe<Scalars['Boolean']>;
  /** Parent Product Group provided in the request object does not exist */
  parentPgDoesNotExist?: Maybe<Scalars['Boolean']>;
  /** Parent Product Group not provided and Root Product Group does not exist */
  parentPgNotProvidedAndRootDoesNotExist?: Maybe<Scalars['Boolean']>;
  /** Parent Product Group has no Hierarchy entry */
  pgHierarchyDoesNotExist?: Maybe<Scalars['Boolean']>;
  /** Error while creating product */
  productCreationError?: Maybe<Scalars['String']>;
};

export type ProductNextCreateResponse = {
  data?: Maybe<ProductNextCreateSuccess>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<ProductNextCreateErrors>;
};

export type ProductNextCreateSuccess = {
  parentProductGroup: ProductGroupNode;
  product: ProductNode;
};

export type ProductNextInput = {
  companyId: Scalars['UUID'];
  name: Scalars['String'];
  /** Parent Product Group ID, if not provided - the product will be added to the root PG by default */
  parentPg?: InputMaybe<Scalars['UUID']>;
  size?: InputMaybe<Scalars['String']>;
  unitsPerCase?: InputMaybe<Scalars['Int']>;
};

export type ProductNode = {
  company: CompanyNode;
  createdAt: Scalars['DateTime'];
  customProductField1?: Maybe<Scalars['String']>;
  forecastSeasonalityBucket?: Maybe<ForecastSeasonalityBucketNode>;
  id: Scalars['UUID'];
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productCodes: Array<ProductCodeNode>;
  productGroups: Array<ProductGroupNode>;
  productNumber?: Maybe<Scalars['String']>;
  profitCenter?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  unitsPerCase?: Maybe<Scalars['Int']>;
};


export type ProductNodeProductGroupsArgs = {
  filters?: InputMaybe<ProductGroupFilter>;
};

export type ProductNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<ProductNode>;
};

export type ProductOrder = {
  company_Name?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  size?: InputMaybe<Ordering>;
  unitsPerCase?: InputMaybe<Ordering>;
};

export type ProductUpdateInput = {
  customProductField1?: InputMaybe<Scalars['String']>;
  forecastSeasonalityBucket?: InputMaybe<Scalars['UUID']>;
  legacyId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productNumber?: InputMaybe<Scalars['String']>;
  profitCenter?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  unitsPerCase?: InputMaybe<Scalars['Int']>;
};

export type ProductUploadErrors = {
  alreadyInDatabase?: Maybe<Array<Scalars['Int']>>;
  duplicateProductCode?: Maybe<Scalars['JSON']>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  infileDuplicateProductCode?: Maybe<Array<Array<Scalars['Int']>>>;
};

export type ProductUploadValidationResponse = {
  errors?: Maybe<ProductUploadErrors>;
  warnings?: Maybe<ProductUploadWarnings>;
};

export enum ProductUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type ProductUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: ProductGroupedIndiceWarning;
};

export type PromotionActivityLogFilter = {
  AND?: InputMaybe<PromotionActivityLogFilter>;
  NOT?: InputMaybe<PromotionActivityLogFilter>;
  OR?: InputMaybe<PromotionActivityLogFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  promotion?: InputMaybe<PromotionFilter>;
};

export type PromotionActivityLogNode = {
  changeType: PromotionChangeType;
  changes: Scalars['JSON'];
  company: CompanyNode;
  id: Scalars['ID'];
  promotion: PromotionNode;
  promotionActivityLineType?: Maybe<PromotionLineActivityType>;
  promotionActivityType?: Maybe<PromotionActivityType>;
  promotionLine?: Maybe<PromotionLineNode>;
  timestamp: Scalars['DateTime'];
  user?: Maybe<UserNode>;
};

export enum PromotionActivityType {
  CommentList = 'COMMENT_LIST',
  ContactName = 'CONTACT_NAME',
  Contract = 'CONTRACT',
  ContractFiles = 'CONTRACT_FILES',
  CustomerName = 'CUSTOMER_NAME',
  Files = 'FILES',
  FirstReceiver = 'FIRST_RECEIVER',
  Month = 'MONTH',
  Promotion = 'PROMOTION',
  PromotionName = 'PROMOTION_NAME',
  Status = 'STATUS',
  Tags = 'TAGS',
  Year = 'YEAR'
}

export enum PromotionApprovalFilterOptions {
  All = 'ALL',
  BelowApprovalLimit = 'BELOW_APPROVAL_LIMIT'
}

export type PromotionApprovalLimitFilter = {
  AND?: InputMaybe<PromotionApprovalLimitFilter>;
  NOT?: InputMaybe<PromotionApprovalLimitFilter>;
  OR?: InputMaybe<PromotionApprovalLimitFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  limit?: InputMaybe<IntFilterLookup>;
};

export type PromotionApprovalLimitInput = {
  limit?: InputMaybe<Scalars['Int']>;
  role: Scalars['UUID'];
};

export type PromotionApprovalLimitNode = {
  id: Scalars['UUID'];
  limit?: Maybe<Scalars['Int']>;
  role: RoleNode;
};

export type PromotionApprovalLimitPartialInput = {
  id?: InputMaybe<Scalars['UUID']>;
  limit?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['UUID']>;
};

export enum PromotionChangeType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type PromotionColumnMapping = {
  autoUpdateForecast: Scalars['String'];
  basePrice: Scalars['String'];
  buyInEndDate: Scalars['String'];
  buyInStartDate: Scalars['String'];
  comments: Scalars['String'];
  contacts: Scalars['String'];
  contract: Scalars['String'];
  customerName: Scalars['String'];
  firstReceiverName: Scalars['String'];
  fundType: Scalars['String'];
  inStoreEndDate: Scalars['String'];
  inStoreStartDate: Scalars['String'];
  lift: Scalars['String'];
  lineKey: Scalars['String'];
  lumpSumSpend: Scalars['String'];
  month: Scalars['String'];
  productGroupName: Scalars['String'];
  productName: Scalars['String'];
  promotionName: Scalars['String'];
  retailPrice: Scalars['String'];
  scanBackEndDate: Scalars['String'];
  scanBackStartDate: Scalars['String'];
  spendRate: Scalars['String'];
  tags: Scalars['String'];
  totalExpSales: Scalars['String'];
  year: Scalars['String'];
};

export type PromotionCompanyFileRelationshipFilter = {
  AND?: InputMaybe<PromotionCompanyFileRelationshipFilter>;
  NOT?: InputMaybe<PromotionCompanyFileRelationshipFilter>;
  OR?: InputMaybe<PromotionCompanyFileRelationshipFilter>;
  companyFile?: InputMaybe<CompanyFileFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  isContract?: InputMaybe<Scalars['Boolean']>;
  promotion?: InputMaybe<PromotionFilter>;
};

export type PromotionCompanyFileRelationshipInput = {
  companyFile: Scalars['UUID'];
  isContract?: InputMaybe<Scalars['Boolean']>;
  toBeCopied?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionCompanyFileRelationshipNode = {
  companyFile: CompanyFileNode;
  id: Scalars['UUID'];
  isContract: Scalars['Boolean'];
  promotion: PromotionNode;
};

export type PromotionExportStatus = {
  data?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type PromotionFileData = {
  autoUpdateForecast: Array<Maybe<Scalars['String']>>;
  basePrice: Array<Maybe<Scalars['String']>>;
  buyInEndDate: Array<Maybe<Scalars['String']>>;
  buyInStartDate: Array<Maybe<Scalars['String']>>;
  comments: Array<Maybe<Scalars['String']>>;
  contacts: Array<Maybe<Scalars['String']>>;
  contract: Array<Maybe<Scalars['String']>>;
  customerName: Array<Maybe<Scalars['String']>>;
  firstReceiverName: Array<Maybe<Scalars['String']>>;
  fundType: Array<Maybe<Scalars['String']>>;
  inStoreEndDate: Array<Maybe<Scalars['String']>>;
  inStoreStartDate: Array<Maybe<Scalars['String']>>;
  lift: Array<Maybe<Scalars['String']>>;
  lineKey: Array<Maybe<Scalars['String']>>;
  lumpSumSpend: Array<Maybe<Scalars['String']>>;
  month: Array<Maybe<Scalars['String']>>;
  productGroupName: Array<Maybe<Scalars['String']>>;
  productName: Array<Maybe<Scalars['String']>>;
  promotionName: Array<Maybe<Scalars['String']>>;
  retailPrice: Array<Maybe<Scalars['String']>>;
  scanBackEndDate: Array<Maybe<Scalars['String']>>;
  scanBackStartDate: Array<Maybe<Scalars['String']>>;
  spendRate: Array<Maybe<Scalars['String']>>;
  tags: Array<Maybe<Scalars['String']>>;
  totalExpSales: Array<Maybe<Scalars['String']>>;
  year: Array<Maybe<Scalars['String']>>;
};

export type PromotionFileDataInput = {
  autoUpdateForecast: Array<InputMaybe<Scalars['String']>>;
  basePrice: Array<InputMaybe<Scalars['String']>>;
  buyInEndDate: Array<InputMaybe<Scalars['String']>>;
  buyInStartDate: Array<InputMaybe<Scalars['String']>>;
  comments: Array<InputMaybe<Scalars['String']>>;
  contacts: Array<InputMaybe<Scalars['String']>>;
  contract: Array<InputMaybe<Scalars['String']>>;
  customerName: Array<InputMaybe<Scalars['String']>>;
  firstReceiverName: Array<InputMaybe<Scalars['String']>>;
  fundType: Array<InputMaybe<Scalars['String']>>;
  inStoreEndDate: Array<InputMaybe<Scalars['String']>>;
  inStoreStartDate: Array<InputMaybe<Scalars['String']>>;
  lift: Array<InputMaybe<Scalars['String']>>;
  lineKey: Array<InputMaybe<Scalars['String']>>;
  lumpSumSpend: Array<InputMaybe<Scalars['String']>>;
  month: Array<InputMaybe<Scalars['String']>>;
  productGroupName: Array<InputMaybe<Scalars['String']>>;
  productName: Array<InputMaybe<Scalars['String']>>;
  promotionName: Array<InputMaybe<Scalars['String']>>;
  retailPrice: Array<InputMaybe<Scalars['String']>>;
  scanBackEndDate: Array<InputMaybe<Scalars['String']>>;
  scanBackStartDate: Array<InputMaybe<Scalars['String']>>;
  spendRate: Array<InputMaybe<Scalars['String']>>;
  tags: Array<InputMaybe<Scalars['String']>>;
  totalExpSales: Array<InputMaybe<Scalars['String']>>;
  year: Array<InputMaybe<Scalars['String']>>;
};

export type PromotionFileDataOutput = PromotionFileData & {
  autoUpdateForecast: Array<Maybe<Scalars['String']>>;
  basePrice: Array<Maybe<Scalars['String']>>;
  buyInEndDate: Array<Maybe<Scalars['String']>>;
  buyInStartDate: Array<Maybe<Scalars['String']>>;
  comments: Array<Maybe<Scalars['String']>>;
  contacts: Array<Maybe<Scalars['String']>>;
  contract: Array<Maybe<Scalars['String']>>;
  customerName: Array<Maybe<Scalars['String']>>;
  firstReceiverName: Array<Maybe<Scalars['String']>>;
  fundType: Array<Maybe<Scalars['String']>>;
  inStoreEndDate: Array<Maybe<Scalars['String']>>;
  inStoreStartDate: Array<Maybe<Scalars['String']>>;
  lift: Array<Maybe<Scalars['String']>>;
  lineKey: Array<Maybe<Scalars['String']>>;
  lumpSumSpend: Array<Maybe<Scalars['String']>>;
  month: Array<Maybe<Scalars['String']>>;
  productGroupName: Array<Maybe<Scalars['String']>>;
  productName: Array<Maybe<Scalars['String']>>;
  promotionName: Array<Maybe<Scalars['String']>>;
  retailPrice: Array<Maybe<Scalars['String']>>;
  scanBackEndDate: Array<Maybe<Scalars['String']>>;
  scanBackStartDate: Array<Maybe<Scalars['String']>>;
  spendRate: Array<Maybe<Scalars['String']>>;
  tags: Array<Maybe<Scalars['String']>>;
  totalExpSales: Array<Maybe<Scalars['String']>>;
  year: Array<Maybe<Scalars['String']>>;
};

export type PromotionFilter = {
  AND?: InputMaybe<PromotionFilter>;
  NOT?: InputMaybe<PromotionFilter>;
  OR?: InputMaybe<PromotionFilter>;
  closed?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyFilter>;
  contains?: InputMaybe<Scalars['String']>;
  contract?: InputMaybe<StrFilterLookup>;
  createUser?: InputMaybe<UserFilter>;
  customer?: InputMaybe<CustomerFilter>;
  firstReceivers?: InputMaybe<CustomerFilter>;
  fundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  hasAttachments?: InputMaybe<Scalars['Boolean']>;
  hasContracts?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  ignoreEmptyContract?: InputMaybe<Scalars['Boolean']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  lineClosed?: InputMaybe<Scalars['Boolean']>;
  month?: InputMaybe<IntFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  productGroups?: InputMaybe<Array<Scalars['UUID']>>;
  products?: InputMaybe<Array<Scalars['UUID']>>;
  promKey?: InputMaybe<StrFilterLookup>;
  selectedRoles?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<PromotionStatus>;
  status_In?: InputMaybe<Scalars['String']>;
  status_Not?: InputMaybe<PromotionStatus>;
  tags?: InputMaybe<PromotionTagFilter>;
  year?: InputMaybe<IntFilterLookup>;
};

export type PromotionGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: PromotionUploadWarningEnum;
};

export type PromotionIndividualIndiceWarning = IndividualIndiceWarning & {
  indices?: Maybe<Array<Scalars['Int']>>;
  warningType: PromotionUploadWarningEnum;
};

export type PromotionInput = {
  attachments?: InputMaybe<Array<PromotionCompanyFileRelationshipInput>>;
  closed?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Array<CommentsInput>>;
  companyId: Scalars['UUID'];
  contacts?: InputMaybe<Array<Scalars['ID']>>;
  contract?: InputMaybe<Scalars['String']>;
  customerId: Scalars['UUID'];
  firstReceivers: Array<Scalars['ID']>;
  lines: Array<PromotionLineInput>;
  linesToDelete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  month: Scalars['Int'];
  name: Scalars['String'];
  prevTotalExpSales?: InputMaybe<Scalars['Float']>;
  prevTotalExpSpend?: InputMaybe<Scalars['Float']>;
  status: PromotionStatus;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  totalExpRevenue?: InputMaybe<Scalars['Float']>;
  totalExpSales?: InputMaybe<Scalars['Float']>;
  totalExpSpend?: InputMaybe<Scalars['Float']>;
  year: Scalars['Int'];
};

export enum PromotionLineActivityType {
  ActualLift = 'ACTUAL_LIFT',
  AutoUpdate = 'AUTO_UPDATE',
  BasePrice = 'BASE_PRICE',
  BuyInEndDate = 'BUY_IN_END_DATE',
  BuyInStartDate = 'BUY_IN_START_DATE',
  Closed = 'CLOSED',
  ExpectedLift = 'EXPECTED_LIFT',
  InStoreEndDate = 'IN_STORE_END_DATE',
  InStoreStartDate = 'IN_STORE_START_DATE',
  LineKey = 'LINE_KEY',
  LumpSumSpend = 'LUMP_SUM_SPEND',
  Month = 'MONTH',
  ProductGroup = 'PRODUCT_GROUP',
  ProductName = 'PRODUCT_NAME',
  PromotionType = 'PROMOTION_TYPE',
  RetailPrice = 'RETAIL_PRICE',
  ScanBackEndDate = 'SCAN_BACK_END_DATE',
  ScanBackStartDate = 'SCAN_BACK_START_DATE',
  SpendRate = 'SPEND_RATE',
  TotalExpectedSpend = 'TOTAL_EXPECTED_SPEND',
  TotalExpectedUnitSales = 'TOTAL_EXPECTED_UNIT_SALES',
  Year = 'YEAR'
}

export type PromotionLineCompanyFileRelationshipFilter = {
  AND?: InputMaybe<PromotionLineCompanyFileRelationshipFilter>;
  NOT?: InputMaybe<PromotionLineCompanyFileRelationshipFilter>;
  OR?: InputMaybe<PromotionLineCompanyFileRelationshipFilter>;
  companyFile?: InputMaybe<CompanyFileFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  isContract?: InputMaybe<Scalars['Boolean']>;
  promotionLine?: InputMaybe<PromotionLineFilter>;
};

export type PromotionLineCompanyFileRelationshipInput = {
  companyFile: Scalars['UUID'];
  isContract?: InputMaybe<Scalars['Boolean']>;
  toBeCopied?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionLineCompanyFileRelationshipNode = {
  companyFile: CompanyFileNode;
  id: Scalars['UUID'];
  isContract: Scalars['Boolean'];
  promotionLine: PromotionLineNode;
};

export type PromotionLineFilter = {
  AND?: InputMaybe<PromotionLineFilter>;
  NOT?: InputMaybe<PromotionLineFilter>;
  OR?: InputMaybe<PromotionLineFilter>;
  account?: InputMaybe<AccountFilter>;
  autoUpdateForecast?: InputMaybe<Scalars['Boolean']>;
  basePrice?: InputMaybe<FloatFilterLookup>;
  buyinEndDate?: InputMaybe<DatetimeFilterLookup>;
  buyinStartDate?: InputMaybe<DatetimeFilterLookup>;
  closed?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyFilter>;
  contains?: InputMaybe<Scalars['String']>;
  customProducts?: InputMaybe<ProductFilter>;
  discount?: InputMaybe<FloatFilterLookup>;
  endDate?: InputMaybe<DatetimeFilterLookup>;
  fundType?: InputMaybe<Scalars['UUID']>;
  fundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  hasAttachments?: InputMaybe<Scalars['Boolean']>;
  hasContracts?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  instoreEndDate?: InputMaybe<DatetimeFilterLookup>;
  instoreStartDate?: InputMaybe<DatetimeFilterLookup>;
  lift?: InputMaybe<FloatFilterLookup>;
  lineKey?: InputMaybe<StrFilterLookup>;
  lumpSumSpend?: InputMaybe<FloatFilterLookup>;
  prevTotalExpSales?: InputMaybe<FloatFilterLookup>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  productGroups?: InputMaybe<Array<Scalars['UUID']>>;
  products?: InputMaybe<Array<Scalars['UUID']>>;
  promotion?: InputMaybe<PromotionFilter>;
  promotionStatus_Not?: InputMaybe<PromotionStatus>;
  retailPrice?: InputMaybe<FloatFilterLookup>;
  scanbackEndDate?: InputMaybe<DatetimeFilterLookup>;
  scanbackStartDate?: InputMaybe<DatetimeFilterLookup>;
  spendRate?: InputMaybe<FloatFilterLookup>;
  startDate?: InputMaybe<DatetimeFilterLookup>;
  status_In?: InputMaybe<Scalars['String']>;
  totalExpSales?: InputMaybe<FloatFilterLookup>;
  totalExpSpend?: InputMaybe<FloatFilterLookup>;
};

export type PromotionLineInput = {
  actualLift?: InputMaybe<Scalars['Float']>;
  attachments?: InputMaybe<Array<PromotionLineCompanyFileRelationshipInput>>;
  autoUpdateForecast?: InputMaybe<Scalars['Boolean']>;
  basePrice?: InputMaybe<Scalars['Float']>;
  buyinEndDate?: InputMaybe<Scalars['DateTime']>;
  buyinStartDate?: InputMaybe<Scalars['DateTime']>;
  closed?: InputMaybe<Scalars['Boolean']>;
  customProducts: Array<InputMaybe<Scalars['UUID']>>;
  discount?: InputMaybe<Scalars['Float']>;
  endDate: Scalars['DateTime'];
  fundType: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  instoreEndDate?: InputMaybe<Scalars['DateTime']>;
  instoreStartDate?: InputMaybe<Scalars['DateTime']>;
  lift?: InputMaybe<Scalars['Float']>;
  lineKey?: InputMaybe<Scalars['String']>;
  lumpSumSpend?: InputMaybe<Scalars['Float']>;
  pointOfSaleFields: Array<InputMaybe<Scalars['UUID']>>;
  prevTotalExpSales?: InputMaybe<Scalars['Float']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  retailPrice?: InputMaybe<Scalars['Float']>;
  scanbackEndDate?: InputMaybe<Scalars['DateTime']>;
  scanbackStartDate?: InputMaybe<Scalars['DateTime']>;
  spendRate?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  toDelete?: InputMaybe<Scalars['Boolean']>;
  totalExpRevenue?: InputMaybe<Scalars['Float']>;
  totalExpSales?: InputMaybe<Scalars['Float']>;
  totalExpSpend?: InputMaybe<Scalars['Float']>;
};

export type PromotionLineNode = {
  account: AccountNode;
  actualLift?: Maybe<Scalars['Float']>;
  attachments: Array<CompanyFileNode>;
  autoUpdateForecast?: Maybe<Scalars['Boolean']>;
  basePrice?: Maybe<Scalars['Float']>;
  buyinEndDate?: Maybe<Scalars['DateTime']>;
  buyinStartDate?: Maybe<Scalars['DateTime']>;
  closed?: Maybe<Scalars['Boolean']>;
  company: CompanyNode;
  customProducts: Array<ProductNode>;
  discount?: Maybe<Scalars['Float']>;
  endDate: Scalars['DateTime'];
  fundType: FundTypeNode;
  id: Scalars['UUID'];
  instoreEndDate?: Maybe<Scalars['DateTime']>;
  instoreStartDate?: Maybe<Scalars['DateTime']>;
  lift?: Maybe<Scalars['Float']>;
  lineKey: Scalars['String'];
  lumpSumSpend?: Maybe<Scalars['Float']>;
  pointOfSaleFields: Array<PosNode>;
  prevTotalExpSales?: Maybe<Scalars['Float']>;
  productGroup?: Maybe<ProductGroupNode>;
  promotion: PromotionNode;
  promotionLineAttachments: Array<PromotionLineCompanyFileRelationshipNode>;
  promotionlinecompanyfilerelationshipSet: Array<PromotionLineCompanyFileRelationshipNode>;
  retailPrice?: Maybe<Scalars['Float']>;
  scanbackEndDate?: Maybe<Scalars['DateTime']>;
  scanbackStartDate?: Maybe<Scalars['DateTime']>;
  spend?: Maybe<Array<SpendNode>>;
  spendRate?: Maybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  totalExpRevenue?: Maybe<Scalars['Float']>;
  totalExpSales?: Maybe<Scalars['Float']>;
  totalExpSpend?: Maybe<Scalars['Float']>;
};


export type PromotionLineNodeAttachmentsArgs = {
  filters?: InputMaybe<CompanyFileFilter>;
};


export type PromotionLineNodeCustomProductsArgs = {
  filters?: InputMaybe<ProductFilter>;
};


export type PromotionLineNodePointOfSaleFieldsArgs = {
  filters?: InputMaybe<PosFilter>;
};


export type PromotionLineNodeSpendArgs = {
  filters?: InputMaybe<SpendFilter>;
};

export type PromotionLineNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<PromotionLineNode>;
};

export type PromotionLineOrder = {
  closed?: InputMaybe<Ordering>;
  company_Name?: InputMaybe<Ordering>;
  customProducts?: InputMaybe<ProductOrder>;
  endDate?: InputMaybe<Ordering>;
  fundType?: InputMaybe<FundTypeOrder>;
  lineKey?: InputMaybe<Ordering>;
  productGroup?: InputMaybe<ProductGroupOrder>;
  promotion?: InputMaybe<PromotionOrder>;
  promotion_Name?: InputMaybe<Ordering>;
  startDate?: InputMaybe<Ordering>;
  updatedAt?: InputMaybe<Ordering>;
};

export type PromotionLinePartialInput = {
  actualLift?: InputMaybe<Scalars['Float']>;
  attachments?: InputMaybe<Array<PromotionLineCompanyFileRelationshipInput>>;
  autoUpdateForecast?: InputMaybe<Scalars['Boolean']>;
  basePrice?: InputMaybe<Scalars['Float']>;
  buyinEndDate?: InputMaybe<Scalars['DateTime']>;
  buyinStartDate?: InputMaybe<Scalars['DateTime']>;
  closed?: InputMaybe<Scalars['Boolean']>;
  customProducts: Array<InputMaybe<Scalars['UUID']>>;
  discount?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  fundType: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  instoreEndDate?: InputMaybe<Scalars['DateTime']>;
  instoreStartDate?: InputMaybe<Scalars['DateTime']>;
  lift?: InputMaybe<Scalars['Float']>;
  lineKey?: InputMaybe<Scalars['String']>;
  lumpSumSpend?: InputMaybe<Scalars['Float']>;
  pointOfSaleFields: Array<InputMaybe<Scalars['UUID']>>;
  prevTotalExpSales?: InputMaybe<Scalars['Float']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  retailPrice?: InputMaybe<Scalars['Float']>;
  scanbackEndDate?: InputMaybe<Scalars['DateTime']>;
  scanbackStartDate?: InputMaybe<Scalars['DateTime']>;
  spendRate?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  toDelete?: InputMaybe<Scalars['Boolean']>;
  totalExpRevenue?: InputMaybe<Scalars['Float']>;
  totalExpSales?: InputMaybe<Scalars['Float']>;
  totalExpSpend?: InputMaybe<Scalars['Float']>;
};

export type PromotionMappedFileResponse = {
  columnMapping: PromotionColumnMapping;
  fileData: PromotionFileDataOutput;
  validationResults: PromotionUploadValidationResponse;
};

export type PromotionNode = {
  attachments: Array<CompanyFileNode>;
  closed: Scalars['Boolean'];
  comments: Array<CommentNode>;
  company: CompanyNode;
  contacts: Array<ContactNode>;
  contract?: Maybe<Scalars['String']>;
  createUser?: Maybe<UserNode>;
  createdAt: Scalars['DateTime'];
  customer: CustomerNode;
  firstReceivers: Array<CustomerNode>;
  id: Scalars['UUID'];
  isStarred?: Maybe<Scalars['Boolean']>;
  month: Scalars['Int'];
  name: Scalars['String'];
  prevTotalExpSpend?: Maybe<Scalars['Float']>;
  promKey?: Maybe<Scalars['String']>;
  promotionAttachments: Array<PromotionCompanyFileRelationshipNode>;
  promotionLineAttachments: Array<PromotionLineCompanyFileRelationshipNode>;
  promotioncompanyfilerelationshipSet: Array<PromotionCompanyFileRelationshipNode>;
  promotionlinePromotion: Array<PromotionLineNode>;
  spendPromotion?: Maybe<Array<SpendNode>>;
  spendRate?: Maybe<Scalars['Float']>;
  status: PromotionStatus;
  tags: Array<PromotionTagNode>;
  totalExpRevenue?: Maybe<Scalars['Float']>;
  totalExpSales?: Maybe<Scalars['Float']>;
  totalExpSpend?: Maybe<Scalars['Float']>;
  updateUser?: Maybe<UserNode>;
  updatedAt: Scalars['DateTime'];
  year: Scalars['Int'];
};


export type PromotionNodeAttachmentsArgs = {
  filters?: InputMaybe<CompanyFileFilter>;
};


export type PromotionNodeCommentsArgs = {
  order?: InputMaybe<CommentOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type PromotionNodeContactsArgs = {
  filters?: InputMaybe<ContactFilter>;
};


export type PromotionNodeFirstReceiversArgs = {
  filters?: InputMaybe<CustomerFilter>;
};


export type PromotionNodePromotionlinePromotionArgs = {
  filters?: InputMaybe<PromotionLineFilter>;
};


export type PromotionNodeSpendPromotionArgs = {
  filters?: InputMaybe<SpendFilter>;
};

export type PromotionNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<PromotionNode>;
};

export type PromotionNotificationSettingNode = {
  description: Scalars['String'];
  id: Scalars['UUID'];
  isEnabled: Scalars['Boolean'];
};

export type PromotionOrder = {
  customer?: InputMaybe<CustomerOrder>;
  customer_Name?: InputMaybe<Ordering>;
  month?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  totalExpSpend?: InputMaybe<Ordering>;
  updatedAt?: InputMaybe<Ordering>;
  year?: InputMaybe<Ordering>;
};

export type PromotionPartialInput = {
  attachments?: InputMaybe<Array<PromotionCompanyFileRelationshipInput>>;
  closed?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Array<CommentsInput>>;
  companyId: Scalars['UUID'];
  contacts?: InputMaybe<Array<Scalars['ID']>>;
  contract?: InputMaybe<Scalars['String']>;
  customerId: Scalars['UUID'];
  firstReceivers: Array<Scalars['ID']>;
  id?: InputMaybe<Scalars['UUID']>;
  lines: Array<PromotionLineInput>;
  linesToDelete?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  month?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  prevTotalExpSales?: InputMaybe<Scalars['Float']>;
  prevTotalExpSpend?: InputMaybe<Scalars['Float']>;
  status: PromotionStatus;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  totalExpRevenue?: InputMaybe<Scalars['Float']>;
  totalExpSales?: InputMaybe<Scalars['Float']>;
  totalExpSpend?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum PromotionSettingChoices {
  DailyEmailNotifications = 'DAILY_EMAIL_NOTIFICATIONS',
  PromotionApproved = 'PROMOTION_APPROVED',
  PromotionCommentsAdded = 'PROMOTION_COMMENTS_ADDED',
  PromotionCreated = 'PROMOTION_CREATED',
  PromotionDeclined = 'PROMOTION_DECLINED',
  PromotionEdited = 'PROMOTION_EDITED',
  PromotionNeedsApproval = 'PROMOTION_NEEDS_APPROVAL'
}

export enum PromotionStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Finalized = 'FINALIZED',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Submitted = 'SUBMITTED'
}

export type PromotionTagBulkUploadInput = {
  /** File to be uploaded. Either of file_url or file should be present */
  file?: InputMaybe<Scalars['Upload']>;
  /** Signed file URL from where the file can be retrieved. Either of file_url or file should be present */
  fileUrl?: InputMaybe<Scalars['String']>;
  /** Sheet name in the workbook where promotions is mapped to tags */
  sheetName?: Scalars['String'];
  /** Column name in the sheet where tags are present */
  tagColumn?: Scalars['String'];
  /** Separator used to separate multiple tags in a single cell */
  tagSeparator?: Scalars['String'];
  /** Column name in the sheet where promotion UUID is present */
  uuidColumn?: Scalars['String'];
};

export type PromotionTagFilter = {
  AND?: InputMaybe<PromotionTagFilter>;
  NOT?: InputMaybe<PromotionTagFilter>;
  OR?: InputMaybe<PromotionTagFilter>;
  color?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  description?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  promotions?: InputMaybe<PromotionFilter>;
  textColor?: InputMaybe<StrFilterLookup>;
};

export type PromotionTagNode = {
  color: Scalars['String'];
  company: CompanyNode;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  textColor: Scalars['String'];
};

export type PromotionTagOrder = {
  color?: InputMaybe<Ordering>;
  company_Name?: InputMaybe<Ordering>;
  description?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  textColor?: InputMaybe<Ordering>;
};

export type PromotionUploadErrors = {
  contactNotFound?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  extraFieldsError?: Maybe<Array<Scalars['Int']>>;
  extraneousFundTypeInfo?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  fundTypeNotFound?: Maybe<Array<Scalars['Int']>>;
  infileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  invalidFundTypeInfo?: Maybe<Array<Scalars['Int']>>;
  invalidMonthValue?: Maybe<Array<Scalars['Int']>>;
  lineKeyExists?: Maybe<Array<Scalars['Int']>>;
  lineKeyNotFound?: Maybe<Array<Scalars['Int']>>;
  lineKeyRequired?: Maybe<Array<Scalars['Int']>>;
  mismatchedFirstReceivers?: Maybe<Array<Scalars['Int']>>;
  mismatchedFundType?: Maybe<Array<Scalars['Int']>>;
  missingFundTypeInfo?: Maybe<Array<Scalars['Int']>>;
  negativeSpendRate?: Maybe<Array<Scalars['Int']>>;
  negativeTotalExpSales?: Maybe<Array<Scalars['Int']>>;
  negativeTotalSpend?: Maybe<Array<Scalars['Int']>>;
  productGroupNotFound?: Maybe<Array<Scalars['Int']>>;
  productNotFound?: Maybe<Array<Scalars['Int']>>;
  promoLevelInfoMismatch?: Maybe<Array<Scalars['Int']>>;
  promotionNameExists?: Maybe<Array<Scalars['Int']>>;
  tagNotFound?: Maybe<Array<Scalars['Int']>>;
};

export type PromotionUploadValidationResponse = {
  errors?: Maybe<PromotionUploadErrors>;
  warnings?: Maybe<PromotionUploadWarnings>;
};

export enum PromotionUploadWarningEnum {
  DefaultLineKeyChosen = 'DEFAULT_LINE_KEY_CHOSEN',
  InfileDuplicates = 'INFILE_DUPLICATES',
  ProductListIgnored = 'PRODUCT_LIST_IGNORED'
}

export type PromotionUploadWarnings = {
  DefaultLineKeyChosen?: Maybe<Array<Scalars['Int']>>;
  InfileDuplicates?: Maybe<Array<Scalars['Int']>>;
  ProductListIgnored?: Maybe<Array<Scalars['Int']>>;
  defaultLineKeyChosen: PromotionIndividualIndiceWarning;
  infileDuplicates: PromotionGroupedIndiceWarning;
  productListIgnored: PromotionIndividualIndiceWarning;
};

export enum ProvidedServices {
  DeductionsScanningServicing = 'DEDUCTIONS_SCANNING_SERVICING'
}

export type Query = {
  POS: Array<PosNode>;
  account: AccountNode;
  accounts: Array<AccountNode>;
  accumulatedFlaggedInvoiceLines: AccumulatedInvoiceLinesNode;
  accumulatedInvoiceLines: AccumulatedInvoiceLineSummary;
  activeStaffUsers: Array<UserNode>;
  adminActivityLog: AdminActivityLogNode;
  adminActivityLogs: AdminActivityLogNodePaginationConnection;
  allAdminActivityLog: Array<AdminActivityLogNode>;
  allBackupSourceGroupRelationships: Array<BackupSourceGroupRelationshipNode>;
  allBackupSourceGroups: Array<BackupSourceGroupNode>;
  allBackupSourceSettingsOverrides: Array<BackupSourceSettingsOverrideNode>;
  allBackupSourceTemplateFiles: Array<BackupSourceTemplateFileNode>;
  allCompanyFiles: Array<CompanyFileNode>;
  allDeductionMatchingColumnMappings: Array<DeductionMatchingColumnMappingNode>;
  allDeductionMatchingColumnOrders: Array<DeductionMatchingColumnOrderNode>;
  allDeductionMatchingInstances: Array<DeductionMatchingInstanceNode>;
  allDeductionScans: Array<DeductionScanNode>;
  allDiscountCutoffs: Array<DiscountCutoffNode>;
  allInternalFiles: Array<InternalFileNode>;
  allInvoiceLineColumnMappings: Array<InvoiceLineColumnMappingNode>;
  allInvoiceLineUploadFiles: Array<InvoiceLineUploadFileNode>;
  allInvoiceLines: Array<InvoiceLineNode>;
  allInvoiceLinesNext: Array<InvoiceLineNextNode>;
  allInvoiceScanResultFiles: Array<InvoiceScanResultFileNode>;
  allInvoices: Array<InvoiceNode>;
  allInvoicesNext: Array<InvoiceNextNode>;
  allOriginalFileColumnHeaders: Array<OriginalFileColumnHeaderNode>;
  allResolutionActivities: Array<ResolutionActivityNode>;
  allResolutionActivitiesNext: Array<ResolutionActivityNextNode>;
  allTransactionColumnMappings: Array<TransactionColumnMappingNode>;
  allTransactions: Array<TransactionNode>;
  allTransactionsNext: Array<TransactionNextNode>;
  allUploadTemplateFiles: Array<UploadTemplateFileNode>;
  allowManualMatch: Array<AllowManualMatchNode>;
  allowManualMatchNext: Array<AllowManualMatchNodeNext>;
  backupAmountFilterRanges: BackupAmountFilterRangeNode;
  backupFile: BackupFileNode;
  backupFiles: BackupFileNodeBackupFilePaginationConnection;
  backupRetrievals: Array<BackupRetrievalNode>;
  backupSource: BackupSourceNode;
  backupSourceGroup: BackupSourceGroupNode;
  backupSourceGroupRelationship: BackupSourceGroupRelationshipNode;
  backupSourceGroupRelationships: BackupSourceGroupRelationshipNodePaginationConnection;
  backupSourceGroups: Array<BackupSourceGroupNode>;
  backupSourceSettingsOverride: BackupSourceSettingsOverrideNode;
  backupSourceSettingsOverrides: Array<BackupSourceSettingsOverrideNode>;
  backupSourceTemplateFile: BackupSourceTemplateFileNode;
  backupSourceTemplateFiles: BackupSourceTemplateFileNodePaginationConnection;
  backupSources: Array<BackupSourceNode>;
  budgetDefaultViews: Array<BudgetDefaultViewNode>;
  budgetDiffThreshold?: Maybe<BudgetDiffThresholdNode>;
  budgets: Array<BudgetNode>;
  bumpChartData: BumpChartDataNode;
  bumpChartDatas: Array<BumpChartDataNode>;
  buyInWindow: BuyInWindowNode;
  buyInWindows: Array<BuyInWindowNode>;
  calculateExpectedRevenue: ExpectedRevenueNode;
  checkInvoiceLinesHasResolutionHistory: Scalars['Boolean'];
  comment: CommentNode;
  comments: Array<CommentNode>;
  companies: Array<CompanyNode>;
  company: CompanyNode;
  companyBackupSource: CompanyBackupSourceRelationshipNode;
  companyBackupSources: Array<CompanyBackupSourceRelationshipNode>;
  companyDefaultApprovalLimit: CompanyDefaultApprovalLimitNode;
  companyDefaultApprovalLimits: Array<CompanyDefaultApprovalLimitNode>;
  companyFile: CompanyFileNode;
  companyFiles: CompanyFileNodePaginationConnection;
  companyScanningSetting: CompanyScanningSettingNode;
  companyScanningSettings: Array<CompanyScanningSettingNode>;
  contact: ContactNode;
  contacts: Array<ContactNode>;
  createPromotionLine: PromotionLineNode;
  currentCustomersByCompany: Array<CustomerTimeFrameNode>;
  customer: CustomerNode;
  customerAlternativeName: CustomerAlternativeNameNode;
  customerAlternativeNameTags: Array<CustomerAlternativeNameTagNode>;
  customerAlternativeNames: Array<CustomerAlternativeNameNode>;
  customerBucketRelationship: CustomerBucketRelationshipNode;
  customerBucketRelationships: Array<CustomerBucketRelationshipNode>;
  customerProfilePresetCustomers: Array<PresetCustomerNode>;
  customerTimeFrame: CustomerTimeFrameNode;
  customerTimeFrameByDate: CustomerTimeFrameNode;
  customerTimeFrames: Array<CustomerTimeFrameNode>;
  customers: Array<CustomerNode>;
  customersWithMostExpSpend: Array<CustomerNode>;
  customersWithoutAob: Array<CustomerNode>;
  deductionMatchingColumnMapping: DeductionMatchingColumnMappingNode;
  deductionMatchingColumnMappingByDeductionMatchingInstance: DeductionMatchingColumnMappingNode;
  deductionMatchingColumnMappings: DeductionMatchingColumnMappingNodePaginationConnection;
  deductionMatchingColumnOrder: DeductionMatchingColumnOrderNode;
  deductionMatchingColumnOrders: DeductionMatchingColumnOrderNodePaginationConnection;
  deductionMatchingInstance: DeductionMatchingInstanceNode;
  deductionMatchingInstances: Array<DeductionMatchingInstanceNode>;
  deductionScan: DeductionScanNode;
  deductionScanFile: DeductionScanFileNode;
  deductionScanFiles: DeductionScanFileNodePaginationConnection;
  deductionScans: DeductionScanNodePaginationConnection;
  defaultProductGroup: ProductGroupNode;
  discountCutoff: DiscountCutoffNode;
  discountCutoffs: Array<DiscountCutoffNode>;
  downloadBackupFiles: Scalars['String'];
  drmEvent: DrmEventNode;
  drmEventCommentTag: DrmEventCommentTagNode;
  drmEventCommentTagNext: DrmEventCommentTagNextNode;
  drmEventCommentTags: DrmEventCommentTagNodePaginationConnection;
  drmEventCommentTagsNext: DrmEventCommentTagNextNodePaginationConnection;
  drmEventNext: DrmEventNextNode;
  drmEvents: DrmEventNodePaginationConnection;
  drmEventsNext: DrmEventNextNodePaginationConnection;
  drmUnseenCommentCount: Scalars['Int'];
  drmUnseenCommentCountNext: Scalars['Int'];
  eligiblePromotionsForApproval: Array<PromotionNode>;
  emailDomain: EmailDomainNode;
  emailDomains: Array<EmailDomainNode>;
  enums: EnumsNode;
  filteredDeductionScans: Array<DeductionScanNode>;
  firstReceiverOptions: Array<CustomerNode>;
  forecastSeasonalityBucket: ForecastSeasonalityBucketNode;
  forecastSeasonalityBuckets: Array<ForecastSeasonalityBucketNode>;
  forecastSeasonalityIndex: ForecastSeasonalityIndexNode;
  forecastSeasonalityIndexes: Array<ForecastSeasonalityIndexNode>;
  freeTrial: DsmFreeTrialNode;
  freeTrialByCompany?: Maybe<DsmFreeTrialNode>;
  freeTrials: Array<DsmFreeTrialNode>;
  fundType: FundTypeNode;
  fundTypeDateRangeChoices: Array<FundTypeDateRangeChoicesReturn>;
  fundTypeField: FundTypeFieldNode;
  fundTypeFieldChoices: Array<FundTypeFieldChoicesReturn>;
  fundTypeFields: Array<FundTypeFieldNode>;
  fundTypes: Array<FundTypeNode>;
  /** Get all customer groups in the hierarchy along with their descendants. Comes with options to filter the results. */
  getAllCustomerGroupsInHierarchy: CustomerHierarchyList;
  /** Get all product groups in the hierarchy along with their descendants. Comes with options to filter the results. */
  getAllProductGroupsInHierarchy: ProductHierarchyList;
  getBucketList?: Maybe<Array<Scalars['String']>>;
  getBucketObjects?: Maybe<Array<Scalars['String']>>;
  getDeductionsForSpecificPromotion: Array<DeductionsForPromLineResponse>;
  getLatestDmmColumnMapping: DeductionMatchingColumnMappingNode;
  getSignedUrlForBackupFile: BackupFileSignedUrlNode;
  getSupportedColumnHeaders: Array<FileTypeSystemHeader>;
  immediateProducts: Array<ProductNode>;
  internalFile: InternalFileNode;
  internalFiles: InternalFileNodePaginationConnection;
  invoice: InvoiceNode;
  invoiceAmountFilterRanges: InvoiceAmountFilterRangeNode;
  invoiceAmountFilterRangesNext: InvoiceAmountFilterRangeNodeNext;
  invoiceLine: InvoiceLineNode;
  invoiceLineColumnMapping: InvoiceLineColumnMappingNode;
  invoiceLineColumnMappings: InvoiceLineColumnMappingNodePaginationConnection;
  invoiceLineNext: InvoiceLineNextNode;
  invoiceLineUploadFile: InvoiceLineUploadFileNode;
  invoiceLineUploadFiles: InvoiceLineUploadFileNodePaginationConnection;
  invoiceLines: InvoiceLineNodeInvoiceLinePaginationConnection;
  invoiceLinesNext: InvoiceLineNextNodeInvoiceLinePaginationConnection;
  invoiceLinesNextSimple: InvoiceLineNextNodePaginationConnection;
  invoiceNext: InvoiceNextNode;
  invoiceScanResultFile: InvoiceScanResultFileNode;
  invoiceScanResultFiles: InvoiceScanResultFileNodePaginationConnection;
  invoices: InvoiceNodeInvoicePaginationConnection;
  invoicesNext: InvoiceNextNodeInvoicePaginationConnection;
  latestEstimates: Array<LatestEstimateNode>;
  latestSoftwareVersion?: Maybe<SoftwareVersionNode>;
  lift: LiftNode;
  liftBucket: LiftBucketNode;
  liftBuckets: Array<LiftBucketNode>;
  lifts: Array<LiftNode>;
  matchedTagsByPromotion: Array<PromotionTagNode>;
  nextCustomerTimeFrameByDate: CustomerTimeFrameNode;
  nextPricingTimeFrameByDate: PricingTimeFrameNode;
  nonPaginatedInvoiceLinesNext: Array<InvoiceLineNextNode>;
  numCustomers: Scalars['Int'];
  numTimeFramesInDateRange: Scalars['Int'];
  originalFileColumnHeader: OriginalFileColumnHeaderNode;
  originalFileColumnHeaders: OriginalFileColumnHeaderNodePaginationConnection;
  paginatedContacts: ContactNodePaginationConnection;
  paginatedCustomers: CustomerNodePaginationConnection;
  paginatedLifts: LiftNodePaginationConnection;
  paginatedPos: PosNodePaginationConnection;
  paginatedProducts: ProductNodePaginationConnection;
  paginatedPromotions: PromotionNodePaginationConnection;
  paginatedPromotionsLines: PromotionLineNodePaginationConnection;
  paginatedRevenues: RevenueNodePaginationConnection;
  paginatedSpends: SpendNodePaginationConnection;
  paginatedUsers: UserNodePaginationConnection;
  passThroughTypes?: Maybe<PassThroughTypesNode>;
  payment: PaymentNode;
  payments: PaymentNodePaymentPaginationConnection;
  permission: PermissionNode;
  permissions: Array<PermissionNode>;
  pnlGroup: PnlGroupNode;
  pnlGroups: Array<PnlGroupNode>;
  presetCustomer: PresetCustomerNode;
  presetCustomerAlternativeName: PresetCustomerAlternativeNameNode;
  presetCustomerAlternativeNames: Array<PresetCustomerAlternativeNameNode>;
  presetCustomers: Array<PresetCustomerNode>;
  pricingTimeFrame: PricingTimeFrameNode;
  pricingTimeFrames: Array<PricingTimeFrameNode>;
  pricingTimeFramesByDate: Array<PricingTimeFrameNode>;
  product: ProductNode;
  productBucketRelationship: ProductBucketRelationshipNode;
  productBucketRelationships: Array<ProductBucketRelationshipNode>;
  productCode: ProductCodeNode;
  productCodes: Array<ProductCodeNode>;
  productGroup: ProductGroupNode;
  productGroupAlternativeName: ProductGroupAlternativeNameNode;
  productGroupAlternativeNames: Array<ProductGroupAlternativeNameNode>;
  productGroups: Array<ProductGroupNode>;
  products: Array<ProductNode>;
  promotion: PromotionNode;
  promotionActivityLogs: Array<PromotionActivityLogNode>;
  promotionApprovalLimit: PromotionApprovalLimitNode;
  promotionApprovalLimits: Array<PromotionApprovalLimitNode>;
  promotionCompanyFileRelationship: PromotionCompanyFileRelationshipNode;
  promotionCompanyFileRelationships: Array<PromotionCompanyFileRelationshipNode>;
  promotionLine: PromotionLineNode;
  promotionLineCompanyFileRelationship: PromotionLineCompanyFileRelationshipNode;
  promotionLineCompanyFileRelationships: Array<PromotionLineCompanyFileRelationshipNode>;
  promotionLines: Array<PromotionLineNode>;
  promotionNotificationSettings: Array<PromotionNotificationSettingNode>;
  promotionTag: PromotionTagNode;
  promotionTags: Array<PromotionTagNode>;
  promotions: Array<PromotionNode>;
  quickClearThreshold: QuickClearThresholdNode;
  regionGroup: RegionGroupNode;
  regionGroups: Array<RegionGroupNode>;
  remit: RemitNode;
  remits: RemitNodeRemitPaginationConnection;
  resolutionActivities: ResolutionActivityNodePaginationConnection;
  resolutionActivitiesNext: ResolutionActivityNextNodePaginationConnection;
  resolutionActivity: ResolutionActivityNode;
  resolutionActivityNext: ResolutionActivityNextNode;
  revenue: RevenueNode;
  revenueSource: RevenueSourceNode;
  revenueSources: Array<RevenueSourceNode>;
  revenues: Array<RevenueNode>;
  role: RoleNode;
  rolePromotionFilterSettings: RolePromotionFilterSettingsNode;
  roles: Array<RoleNode>;
  salesReviewEmailDataByAssignedUser: Array<EmailData>;
  salesReviewNotificationSettings?: Maybe<Array<SalesReviewNotificationSettingsNode>>;
  similarPromotions: Array<PromotionNode>;
  siteWideSearch: SiteWideSearchResponse;
  spend: SpendNode;
  spends: Array<SpendNode>;
  tierChoices: Array<TierChoicesReturn>;
  token: TokenNode;
  tokens: Array<TokenNode>;
  transaction: TransactionNode;
  transactionAmountFilterRanges: TransactionAmountFilterRangeNode;
  transactionAmountFilterRangesNext: TransactionAmountFilterRangeNodeNext;
  transactionColumnMapping: TransactionColumnMappingNode;
  transactionColumnMappings: TransactionColumnMappingNodePaginationConnection;
  transactionNext: TransactionNextNode;
  transactions: TransactionNodeTransactionPaginationConnection;
  transactionsNext: TransactionNextNodeTransactionPaginationConnection;
  unassignedUsers: Array<UserNode>;
  upcomingPromotions: Array<PromotionNode>;
  updatePromotionLine: Array<PromotionLineNode>;
  updatePromotionStatus: PromotionNode;
  updatePromotionWithLines: PromotionNode;
  uploadTemplateFile: UploadTemplateFileNode;
  uploadTemplateFiles: Array<UploadTemplateFileNode>;
  user: UserNode;
  userSetting: UserSettingsNode;
  userSettings: Array<UserSettingsNode>;
  users: Array<UserNode>;
};


export type QueryPosArgs = {
  filters?: InputMaybe<PosFilter>;
  order?: InputMaybe<PosOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryAccountArgs = {
  id: Scalars['UUID'];
};


export type QueryAccountsArgs = {
  filters?: InputMaybe<AccountFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryAccumulatedFlaggedInvoiceLinesArgs = {
  companyId: Scalars['UUID'];
  filters: AccumulatedFlaggedInvoiceLinesFilterConfig;
  paginationConfig: PaginationInputConfig;
  sortBy: Scalars['String'];
  sortDir: Scalars['String'];
};


export type QueryAccumulatedInvoiceLinesArgs = {
  filters: AccumulatedInvoiceLinesFilterConfig;
  invoiceId: Scalars['UUID'];
  paginationConfig: PaginationInputConfig;
  sortBy: Scalars['String'];
  sortDir: Scalars['String'];
};


export type QueryAdminActivityLogArgs = {
  id: Scalars['UUID'];
};


export type QueryAdminActivityLogsArgs = {
  filters?: InputMaybe<AdminActivityLogFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryAllAdminActivityLogArgs = {
  filters?: InputMaybe<AdminActivityLogFilter>;
};


export type QueryAllBackupSourceGroupRelationshipsArgs = {
  filters?: InputMaybe<BackupSourceGroupRelationshipFilter>;
};


export type QueryAllBackupSourceGroupsArgs = {
  filters?: InputMaybe<BackupSourceGroupFilter>;
};


export type QueryAllBackupSourceSettingsOverridesArgs = {
  filters?: InputMaybe<BackupSourceSettingsOverrideFilter>;
};


export type QueryAllBackupSourceTemplateFilesArgs = {
  filters?: InputMaybe<BackupSourceTemplateFileFilter>;
};


export type QueryAllCompanyFilesArgs = {
  filters?: InputMaybe<CompanyFileFilter>;
};


export type QueryAllDeductionMatchingColumnMappingsArgs = {
  filters?: InputMaybe<DeductionMatchingColumnMappingFilter>;
};


export type QueryAllDeductionMatchingColumnOrdersArgs = {
  filters?: InputMaybe<DeductionMatchingColumnOrderFilter>;
};


export type QueryAllDeductionMatchingInstancesArgs = {
  filters?: InputMaybe<DeductionMatchingInstanceFilter>;
};


export type QueryAllDeductionScansArgs = {
  filters?: InputMaybe<DeductionScanFilter>;
};


export type QueryAllDiscountCutoffsArgs = {
  filters?: InputMaybe<DiscountCutoffFilter>;
};


export type QueryAllInternalFilesArgs = {
  filters?: InputMaybe<InternalFileFilter>;
};


export type QueryAllInvoiceLineColumnMappingsArgs = {
  filters?: InputMaybe<InvoiceLineColumnMappingFilter>;
};


export type QueryAllInvoiceLineUploadFilesArgs = {
  filters?: InputMaybe<InvoiceLineUploadFileFilter>;
};


export type QueryAllInvoiceLinesArgs = {
  filters?: InputMaybe<InvoiceLineFilter>;
};


export type QueryAllInvoiceLinesNextArgs = {
  filters?: InputMaybe<InvoiceLineNextFilter>;
};


export type QueryAllInvoiceScanResultFilesArgs = {
  filters?: InputMaybe<InvoiceScanResultFileFilter>;
};


export type QueryAllInvoicesArgs = {
  filters?: InputMaybe<InvoiceFilter>;
};


export type QueryAllInvoicesNextArgs = {
  filters?: InputMaybe<InvoiceNextFilter>;
};


export type QueryAllOriginalFileColumnHeadersArgs = {
  filters?: InputMaybe<OriginalFileColumnHeaderFilter>;
};


export type QueryAllTransactionColumnMappingsArgs = {
  filters?: InputMaybe<TransactionColumnMappingFilter>;
};


export type QueryAllTransactionsArgs = {
  filters?: InputMaybe<TransactionFilter>;
};


export type QueryAllTransactionsNextArgs = {
  filters?: InputMaybe<TransactionNextFilter>;
};


export type QueryAllUploadTemplateFilesArgs = {
  filters?: InputMaybe<UploadTemplateFileFilter>;
};


export type QueryAllowManualMatchArgs = {
  id: Scalars['UUID'];
};


export type QueryAllowManualMatchNextArgs = {
  id: Scalars['UUID'];
};


export type QueryBackupAmountFilterRangesArgs = {
  companyId: Scalars['UUID'];
};


export type QueryBackupFilesArgs = {
  filters?: InputMaybe<BackupFileFilter>;
  order?: InputMaybe<BackupFileOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryBackupRetrievalsArgs = {
  filters?: InputMaybe<BackupRetrievalFilter>;
};


export type QueryBackupSourceArgs = {
  id: Scalars['UUID'];
};


export type QueryBackupSourceGroupArgs = {
  id: Scalars['UUID'];
};


export type QueryBackupSourceGroupRelationshipArgs = {
  id: Scalars['UUID'];
};


export type QueryBackupSourceGroupRelationshipsArgs = {
  filters?: InputMaybe<BackupSourceGroupRelationshipFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryBackupSourceGroupsArgs = {
  filters?: InputMaybe<BackupSourceGroupFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryBackupSourceSettingsOverrideArgs = {
  id: Scalars['UUID'];
};


export type QueryBackupSourceSettingsOverridesArgs = {
  filters?: InputMaybe<BackupSourceSettingsOverrideFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryBackupSourceTemplateFileArgs = {
  id: Scalars['UUID'];
};


export type QueryBackupSourceTemplateFilesArgs = {
  filters?: InputMaybe<BackupSourceTemplateFileFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryBackupSourcesArgs = {
  filters?: InputMaybe<BackupSourceFilter>;
  order?: InputMaybe<BackupSourceOrder>;
};


export type QueryBudgetDefaultViewsArgs = {
  filters?: InputMaybe<BudgetDefaultViewFilter>;
};


export type QueryBudgetDiffThresholdArgs = {
  companyId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryBudgetsArgs = {
  filters?: InputMaybe<BudgetFilter>;
};


export type QueryBumpChartDataArgs = {
  id: Scalars['UUID'];
};


export type QueryBumpChartDatasArgs = {
  filters?: InputMaybe<BumpChartDataFilter>;
};


export type QueryBuyInWindowArgs = {
  id: Scalars['UUID'];
};


export type QueryBuyInWindowsArgs = {
  filters?: InputMaybe<BuyInWindowFilter>;
};


export type QueryCalculateExpectedRevenueArgs = {
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  lines?: InputMaybe<Array<ExpectedRevenuePromotionLineInput>>;
};


export type QueryCheckInvoiceLinesHasResolutionHistoryArgs = {
  invoiceLineIds: Array<Scalars['UUID']>;
};


export type QueryCommentArgs = {
  id: Scalars['UUID'];
};


export type QueryCommentsArgs = {
  filters?: InputMaybe<CommentFilter>;
  order?: InputMaybe<CommentOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCompaniesArgs = {
  filters?: InputMaybe<CompanyFilter>;
  order?: InputMaybe<CompanyOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCompanyArgs = {
  id: Scalars['UUID'];
};


export type QueryCompanyBackupSourceArgs = {
  id: Scalars['UUID'];
};


export type QueryCompanyBackupSourcesArgs = {
  filters?: InputMaybe<CompanyBackupSourceRelationshipFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCompanyDefaultApprovalLimitArgs = {
  id: Scalars['UUID'];
};


export type QueryCompanyDefaultApprovalLimitsArgs = {
  filters?: InputMaybe<CompanyDefaultApprovalLimitFilter>;
};


export type QueryCompanyFileArgs = {
  id: Scalars['UUID'];
};


export type QueryCompanyFilesArgs = {
  filters?: InputMaybe<CompanyFileFilter>;
  order?: InputMaybe<CompanyFileOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCompanyScanningSettingArgs = {
  id: Scalars['UUID'];
};


export type QueryCompanyScanningSettingsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryContactArgs = {
  id: Scalars['UUID'];
};


export type QueryContactsArgs = {
  filters?: InputMaybe<ContactFilter>;
  order?: InputMaybe<ContactOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCreatePromotionLineArgs = {
  data: PromotionLineInput;
};


export type QueryCurrentCustomersByCompanyArgs = {
  companyId: Scalars['UUID'];
  currentDate?: Scalars['Date'];
};


export type QueryCustomerArgs = {
  id: Scalars['UUID'];
};


export type QueryCustomerAlternativeNameArgs = {
  id: Scalars['UUID'];
};


export type QueryCustomerAlternativeNameTagsArgs = {
  filters?: InputMaybe<CustomerAlternativeNameTagFilter>;
};


export type QueryCustomerAlternativeNamesArgs = {
  filters?: InputMaybe<CustomerAlternativeNameFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCustomerBucketRelationshipArgs = {
  id: Scalars['UUID'];
};


export type QueryCustomerBucketRelationshipsArgs = {
  filters?: InputMaybe<CustomerBucketRelationshipFilter>;
  order?: InputMaybe<CustomerBucketRelationshipOrder>;
};


export type QueryCustomerProfilePresetCustomersArgs = {
  company: Scalars['UUID'];
  customer: Scalars['UUID'];
  date: Scalars['Date'];
};


export type QueryCustomerTimeFrameArgs = {
  id: Scalars['UUID'];
};


export type QueryCustomerTimeFrameByDateArgs = {
  customerId: Scalars['UUID'];
  effectiveDate: Scalars['Date'];
};


export type QueryCustomerTimeFramesArgs = {
  filters?: InputMaybe<CustomerTimeFrameFilter>;
  order?: InputMaybe<CustomerTimeFrameOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
  order?: InputMaybe<CustomerOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryCustomersWithMostExpSpendArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersWithoutAobArgs = {
  companyId: Scalars['UUID'];
};


export type QueryDeductionMatchingColumnMappingArgs = {
  id: Scalars['UUID'];
};


export type QueryDeductionMatchingColumnMappingByDeductionMatchingInstanceArgs = {
  dmmInstanceId: Scalars['UUID'];
};


export type QueryDeductionMatchingColumnMappingsArgs = {
  filters?: InputMaybe<DeductionMatchingColumnMappingFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDeductionMatchingColumnOrderArgs = {
  id: Scalars['UUID'];
};


export type QueryDeductionMatchingColumnOrdersArgs = {
  filters?: InputMaybe<DeductionMatchingColumnOrderFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDeductionMatchingInstanceArgs = {
  id: Scalars['UUID'];
};


export type QueryDeductionMatchingInstancesArgs = {
  filters?: InputMaybe<DeductionMatchingInstanceFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDeductionScanArgs = {
  id: Scalars['UUID'];
};


export type QueryDeductionScanFileArgs = {
  id: Scalars['UUID'];
};


export type QueryDeductionScanFilesArgs = {
  filters?: InputMaybe<DeductionScanFileFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDeductionScansArgs = {
  filters?: InputMaybe<DeductionScanFilter>;
  order?: InputMaybe<DeductionScanOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDefaultProductGroupArgs = {
  companyId: Scalars['UUID'];
};


export type QueryDiscountCutoffArgs = {
  id: Scalars['UUID'];
};


export type QueryDiscountCutoffsArgs = {
  filters?: InputMaybe<DiscountCutoffFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDownloadBackupFilesArgs = {
  companyId: Scalars['UUID'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryDrmEventArgs = {
  id: Scalars['UUID'];
};


export type QueryDrmEventCommentTagArgs = {
  id: Scalars['UUID'];
};


export type QueryDrmEventCommentTagNextArgs = {
  id: Scalars['UUID'];
};


export type QueryDrmEventCommentTagsArgs = {
  filters?: InputMaybe<DrmEventCommentTagFilter>;
  order?: InputMaybe<DeductionsReconciliationEventCommentTagOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDrmEventCommentTagsNextArgs = {
  filters?: InputMaybe<DrmEventCommentTagNextFilter>;
  order?: InputMaybe<DeductionsReconciliationEventCommentTagNextOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDrmEventNextArgs = {
  id: Scalars['UUID'];
};


export type QueryDrmEventsArgs = {
  filters?: InputMaybe<DrmEventFilter>;
  order?: InputMaybe<DeductionsReconciliationEventOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDrmEventsNextArgs = {
  filters?: InputMaybe<DrmEventNextFilter>;
  order?: InputMaybe<DeductionsReconciliationEventOrderNext>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryDrmUnseenCommentCountArgs = {
  companyId: Scalars['UUID'];
};


export type QueryDrmUnseenCommentCountNextArgs = {
  companyId: Scalars['UUID'];
};


export type QueryEmailDomainArgs = {
  id: Scalars['UUID'];
};


export type QueryEmailDomainsArgs = {
  filters?: InputMaybe<EmailDomainFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryFilteredDeductionScansArgs = {
  filters?: InputMaybe<DeductionScanFilter>;
};


export type QueryFirstReceiverOptionsArgs = {
  timeFrameId: Scalars['UUID'];
};


export type QueryForecastSeasonalityBucketArgs = {
  id: Scalars['UUID'];
};


export type QueryForecastSeasonalityBucketsArgs = {
  filters?: InputMaybe<ForecastSeasonalityBucketFilter>;
  order?: InputMaybe<ForecastSeasonalityBucketOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryForecastSeasonalityIndexArgs = {
  id: Scalars['UUID'];
};


export type QueryForecastSeasonalityIndexesArgs = {
  filters?: InputMaybe<ForecastSeasonalityIndexFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryFreeTrialArgs = {
  id: Scalars['UUID'];
};


export type QueryFreeTrialByCompanyArgs = {
  companyId: Scalars['UUID'];
};


export type QueryFreeTrialsArgs = {
  filters?: InputMaybe<DsmFreeTrialFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryFundTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryFundTypeFieldArgs = {
  id: Scalars['UUID'];
};


export type QueryFundTypeFieldsArgs = {
  filters?: InputMaybe<FundTypeFieldFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryFundTypesArgs = {
  filters?: InputMaybe<FundTypeFilter>;
  order?: InputMaybe<FundTypeOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryGetAllCustomerGroupsInHierarchyArgs = {
  companyId: Scalars['UUID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<CustomerHierarchyNextOptions>;
};


export type QueryGetAllProductGroupsInHierarchyArgs = {
  companyId: Scalars['UUID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<ProductHierarchyNextOptions>;
};


export type QueryGetBucketListArgs = {
  projectId: Scalars['String'];
};


export type QueryGetBucketObjectsArgs = {
  bucketName: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryGetDeductionsForSpecificPromotionArgs = {
  companyId: Scalars['ID'];
  promId: Scalars['UUID'];
};


export type QueryGetLatestDmmColumnMappingArgs = {
  deductionInstanceId: Scalars['UUID'];
};


export type QueryGetSignedUrlForBackupFileArgs = {
  backupFileId: Scalars['UUID'];
};


export type QueryGetSupportedColumnHeadersArgs = {
  fileCategory: CompanyFileCategories;
};


export type QueryImmediateProductsArgs = {
  productGroupId: Scalars['UUID'];
};


export type QueryInternalFileArgs = {
  id: Scalars['UUID'];
};


export type QueryInternalFilesArgs = {
  filters?: InputMaybe<InternalFileFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoiceAmountFilterRangesArgs = {
  companyId: Scalars['UUID'];
};


export type QueryInvoiceAmountFilterRangesNextArgs = {
  companyId: Scalars['UUID'];
};


export type QueryInvoiceLineArgs = {
  id: Scalars['UUID'];
};


export type QueryInvoiceLineColumnMappingArgs = {
  id: Scalars['UUID'];
};


export type QueryInvoiceLineColumnMappingsArgs = {
  filters?: InputMaybe<InvoiceLineColumnMappingFilter>;
  order?: InputMaybe<InvoiceLineColumnMappingOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoiceLineNextArgs = {
  id: Scalars['UUID'];
};


export type QueryInvoiceLineUploadFileArgs = {
  id: Scalars['UUID'];
};


export type QueryInvoiceLineUploadFilesArgs = {
  filters?: InputMaybe<InvoiceLineUploadFileFilter>;
  order?: InputMaybe<InvoiceLineUploadFileOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoiceLinesArgs = {
  filters?: InputMaybe<InvoiceLineFilter>;
  order?: InputMaybe<InvoiceLineOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoiceLinesNextArgs = {
  filters?: InputMaybe<InvoiceLineNextFilter>;
  order?: InputMaybe<InvoiceLineNextOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoiceLinesNextSimpleArgs = {
  filters?: InputMaybe<InvoiceLineNextFilter>;
  order?: InputMaybe<InvoiceLineNextOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoiceNextArgs = {
  id: Scalars['UUID'];
};


export type QueryInvoiceScanResultFileArgs = {
  id: Scalars['UUID'];
};


export type QueryInvoiceScanResultFilesArgs = {
  filters?: InputMaybe<InvoiceScanResultFileFilter>;
  order?: InputMaybe<InvoiceScanResultFileOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoicesArgs = {
  filters?: InputMaybe<InvoiceFilter>;
  order?: InputMaybe<InvoiceOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryInvoicesNextArgs = {
  filters?: InputMaybe<InvoiceNextFilter>;
  order?: InputMaybe<InvoiceNextOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryLatestEstimatesArgs = {
  filters?: InputMaybe<LatestEstimateFilter>;
};


export type QueryLiftArgs = {
  id: Scalars['UUID'];
};


export type QueryLiftBucketArgs = {
  id: Scalars['UUID'];
};


export type QueryLiftBucketsArgs = {
  filters?: InputMaybe<LiftBucketFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryLiftsArgs = {
  filters?: InputMaybe<LiftFilter>;
  order?: InputMaybe<LiftOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryMatchedTagsByPromotionArgs = {
  company: Scalars['ID'];
  promotions: Array<Scalars['ID']>;
};


export type QueryNextCustomerTimeFrameByDateArgs = {
  customerId: Scalars['UUID'];
  effectiveDate: Scalars['Date'];
};


export type QueryNextPricingTimeFrameByDateArgs = {
  filters: PartialPricingTimeFrameInput;
};


export type QueryNonPaginatedInvoiceLinesNextArgs = {
  filters?: InputMaybe<InvoiceLineNextFilter>;
  order?: InputMaybe<InvoiceLineNextOrder>;
};


export type QueryNumCustomersArgs = {
  companyId: Scalars['UUID'];
};


export type QueryNumTimeFramesInDateRangeArgs = {
  customerId?: InputMaybe<Scalars['UUID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryOriginalFileColumnHeaderArgs = {
  id: Scalars['UUID'];
};


export type QueryOriginalFileColumnHeadersArgs = {
  filters?: InputMaybe<OriginalFileColumnHeaderFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedContactsArgs = {
  filters?: InputMaybe<ContactFilter>;
  order?: InputMaybe<ContactOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
  order?: InputMaybe<CustomerOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedLiftsArgs = {
  filters?: InputMaybe<LiftFilter>;
  order?: InputMaybe<LiftOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedPosArgs = {
  filters?: InputMaybe<PosFilter>;
  order?: InputMaybe<PosOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedProductsArgs = {
  filters?: InputMaybe<ProductFilter>;
  order?: InputMaybe<ProductOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedPromotionsArgs = {
  filters?: InputMaybe<PromotionFilter>;
  order?: InputMaybe<PromotionOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedPromotionsLinesArgs = {
  filters?: InputMaybe<PromotionLineFilter>;
  order?: InputMaybe<PromotionLineOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedRevenuesArgs = {
  filters?: InputMaybe<RevenueFilter>;
  order?: InputMaybe<RevenueOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedSpendsArgs = {
  filters?: InputMaybe<SpendFilter>;
  order?: InputMaybe<SpendOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPaginatedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
  order?: InputMaybe<UserOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPassThroughTypesArgs = {
  companyId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryPaymentArgs = {
  id: Scalars['UUID'];
};


export type QueryPaymentsArgs = {
  filters?: InputMaybe<PaymentFilter>;
  order?: InputMaybe<PaymentOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPermissionArgs = {
  id: Scalars['UUID'];
};


export type QueryPermissionsArgs = {
  companyId?: InputMaybe<Scalars['UUID']>;
  filters?: InputMaybe<PermissionFilter>;
  order?: InputMaybe<PermissionOrder>;
};


export type QueryPnlGroupsArgs = {
  filters?: InputMaybe<PnlGroupFilter>;
};


export type QueryPresetCustomerArgs = {
  id: Scalars['UUID'];
};


export type QueryPresetCustomerAlternativeNameArgs = {
  id: Scalars['UUID'];
};


export type QueryPresetCustomerAlternativeNamesArgs = {
  filters?: InputMaybe<PresetCustomerAlternativeNameFilter>;
};


export type QueryPresetCustomersArgs = {
  filters?: InputMaybe<PresetCustomerFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPricingTimeFrameArgs = {
  id: Scalars['UUID'];
};


export type QueryPricingTimeFramesArgs = {
  filters?: InputMaybe<PricingTimeFrameFilter>;
  order?: InputMaybe<PricingTimeFrameOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPricingTimeFramesByDateArgs = {
  company: Scalars['UUID'];
  customerId?: Scalars['String'];
  effectiveDate: Scalars['Date'];
  productGroupId?: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['UUID'];
};


export type QueryProductBucketRelationshipArgs = {
  id: Scalars['UUID'];
};


export type QueryProductBucketRelationshipsArgs = {
  filters?: InputMaybe<ProductBucketRelationshipFilter>;
  order?: InputMaybe<ProductBucketRelationshipOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryProductCodeArgs = {
  id: Scalars['UUID'];
};


export type QueryProductCodesArgs = {
  filters?: InputMaybe<ProductCodeFilter>;
  order?: InputMaybe<ProductCodeOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryProductGroupArgs = {
  id: Scalars['UUID'];
};


export type QueryProductGroupAlternativeNameArgs = {
  id: Scalars['UUID'];
};


export type QueryProductGroupAlternativeNamesArgs = {
  filters?: InputMaybe<ProductGroupAlternativeNameFilter>;
  order?: InputMaybe<ProductGroupAlternativeNameOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryProductGroupsArgs = {
  filters?: InputMaybe<ProductGroupFilter>;
  order?: InputMaybe<ProductGroupOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryProductsArgs = {
  filters?: InputMaybe<ProductFilter>;
  order?: InputMaybe<ProductOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPromotionArgs = {
  id: Scalars['UUID'];
};


export type QueryPromotionActivityLogsArgs = {
  filters?: InputMaybe<PromotionActivityLogFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPromotionApprovalLimitArgs = {
  id: Scalars['UUID'];
};


export type QueryPromotionApprovalLimitsArgs = {
  filters?: InputMaybe<PromotionApprovalLimitFilter>;
};


export type QueryPromotionCompanyFileRelationshipArgs = {
  id: Scalars['UUID'];
};


export type QueryPromotionCompanyFileRelationshipsArgs = {
  filters?: InputMaybe<PromotionCompanyFileRelationshipFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPromotionLineArgs = {
  id: Scalars['UUID'];
};


export type QueryPromotionLineCompanyFileRelationshipArgs = {
  id: Scalars['UUID'];
};


export type QueryPromotionLineCompanyFileRelationshipsArgs = {
  filters?: InputMaybe<PromotionLineCompanyFileRelationshipFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPromotionLinesArgs = {
  filters?: InputMaybe<PromotionLineFilter>;
  order?: InputMaybe<PromotionLineOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPromotionNotificationSettingsArgs = {
  companyId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type QueryPromotionTagArgs = {
  id: Scalars['UUID'];
};


export type QueryPromotionTagsArgs = {
  filters?: InputMaybe<PromotionTagFilter>;
  order?: InputMaybe<PromotionTagOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryPromotionsArgs = {
  filters?: InputMaybe<PromotionFilter>;
  order?: InputMaybe<PromotionOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryQuickClearThresholdArgs = {
  companyId: Scalars['UUID'];
};


export type QueryRegionGroupArgs = {
  id: Scalars['UUID'];
};


export type QueryRegionGroupsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryRemitArgs = {
  id: Scalars['UUID'];
};


export type QueryRemitsArgs = {
  filters?: InputMaybe<RemitFilter>;
  order?: InputMaybe<RemitOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryResolutionActivitiesArgs = {
  filters?: InputMaybe<ResolutionActivityFilter>;
  order?: InputMaybe<ResolutionActivityOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryResolutionActivitiesNextArgs = {
  filters?: InputMaybe<ResolutionActivityNextFilter>;
  order?: InputMaybe<ResolutionActivityNextOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryResolutionActivityArgs = {
  id: Scalars['UUID'];
};


export type QueryResolutionActivityNextArgs = {
  id: Scalars['UUID'];
};


export type QueryRevenueArgs = {
  id: Scalars['UUID'];
};


export type QueryRevenueSourceArgs = {
  id: Scalars['UUID'];
};


export type QueryRevenueSourcesArgs = {
  filters?: InputMaybe<RevenueSourceFilter>;
  order?: InputMaybe<RevenueSourceOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryRevenuesArgs = {
  filters?: InputMaybe<RevenueFilter>;
  order?: InputMaybe<RevenueOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


export type QueryRolePromotionFilterSettingsArgs = {
  id: Scalars['UUID'];
};


export type QueryRolesArgs = {
  filters?: InputMaybe<RoleFilter>;
};


export type QuerySalesReviewEmailDataByAssignedUserArgs = {
  companyId: Scalars['UUID'];
};


export type QuerySimilarPromotionsArgs = {
  companyId: Scalars['ID'];
  customer: Scalars['ID'];
  fundType: Scalars['ID'];
  month: Scalars['Int'];
  productGroup: Scalars['ID'];
  promId?: InputMaybe<Scalars['ID']>;
  year: Scalars['Int'];
};


export type QuerySiteWideSearchArgs = {
  searchQuery: Scalars['String'];
};


export type QuerySpendArgs = {
  id: Scalars['UUID'];
};


export type QuerySpendsArgs = {
  filters?: InputMaybe<SpendFilter>;
};


export type QueryTokensArgs = {
  data: Scalars['UUID'];
};


export type QueryTransactionArgs = {
  id: Scalars['UUID'];
};


export type QueryTransactionAmountFilterRangesArgs = {
  companyId: Scalars['UUID'];
};


export type QueryTransactionAmountFilterRangesNextArgs = {
  companyId: Scalars['UUID'];
};


export type QueryTransactionColumnMappingArgs = {
  id: Scalars['UUID'];
};


export type QueryTransactionColumnMappingsArgs = {
  filters?: InputMaybe<TransactionColumnMappingFilter>;
  order?: InputMaybe<TransactionColumnMappingOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryTransactionNextArgs = {
  id: Scalars['UUID'];
};


export type QueryTransactionsArgs = {
  filters?: InputMaybe<TransactionFilter>;
  order?: InputMaybe<TransactionOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryTransactionsNextArgs = {
  filters?: InputMaybe<TransactionNextFilter>;
  order?: InputMaybe<TransactionNextOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryUpcomingPromotionsArgs = {
  id: Scalars['ID'];
};


export type QueryUpdatePromotionLineArgs = {
  data: PromotionLinePartialInput;
  filters?: InputMaybe<PromotionLineFilter>;
};


export type QueryUpdatePromotionStatusArgs = {
  data: UpdatePromotionStatusInput;
};


export type QueryUpdatePromotionWithLinesArgs = {
  data: PromotionPartialInput;
};


export type QueryUploadTemplateFileArgs = {
  id: Scalars['UUID'];
};


export type QueryUploadTemplateFilesArgs = {
  filters?: InputMaybe<UploadTemplateFileFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type QueryUserArgs = {
  id: Scalars['UUID'];
};


export type QueryUserSettingArgs = {
  id: Scalars['UUID'];
};


export type QueryUserSettingsArgs = {
  filters?: InputMaybe<UserSettingsFilter>;
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilter>;
  order?: InputMaybe<UserOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type QuickClearByInvoicesInputWithPromotionLineId = {
  comment?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  invoiceIds: Array<Scalars['UUID']>;
  promotionLineId: Scalars['UUID'];
};

export type QuickClearDependencyMutationResponse = {
  errors?: Maybe<Array<Scalars['String']>>;
  expectedTotalClearedAmount?: Maybe<Scalars['Float']>;
  numberOfCustomers?: Maybe<Scalars['Int']>;
  warnings?: Maybe<Array<Scalars['String']>>;
};

export type QuickClearInput = {
  companyId: Scalars['UUID'];
  transactionIds: Array<Scalars['UUID']>;
};

export type QuickClearInputWithPromotionLineId = {
  companyId: Scalars['UUID'];
  promotionLineId: Scalars['UUID'];
  transactionIds: Array<Scalars['UUID']>;
};

export type QuickClearInvoiceMutationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type QuickClearMutationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  resolutionActivities?: Maybe<Array<ResolutionActivityNextNode>>;
};

export type QuickClearThresholdInput = {
  defaultFundType?: InputMaybe<Scalars['UUID']>;
  defaultProductGroup?: InputMaybe<Scalars['UUID']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  minimumDeductionThreshold?: InputMaybe<Scalars['Float']>;
};

export type QuickClearThresholdNode = {
  company: CompanyNode;
  defaultFundType?: Maybe<FundTypeNode>;
  defaultProductGroup?: Maybe<ProductGroupNode>;
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
  minimumDeductionThreshold: Scalars['Float'];
};

export type QuickWriteOffByInvoicesInput = {
  comment?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  fundTypeId: Scalars['UUID'];
  invoiceIds: Array<Scalars['UUID']>;
  productGroupId?: InputMaybe<Scalars['UUID']>;
};

export type QuickWriteOffInput = {
  companyId: Scalars['UUID'];
  fundTypeId: Scalars['UUID'];
  productGroupId?: InputMaybe<Scalars['UUID']>;
  transactionIds: Array<Scalars['UUID']>;
};

export type RefreshDashboardMetricsInput = {
  /** Dashboard to refresh metrics for */
  dashboards: Array<DashboardType>;
};

export type RefreshDashboardMetricsResponse = {
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RegionGroupNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type RemitFilter = {
  AND?: InputMaybe<RemitFilter>;
  NOT?: InputMaybe<RemitFilter>;
  OR?: InputMaybe<RemitFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DateFilterLookup>;
  fileName?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  status?: InputMaybe<StrFilterLookup>;
};

export type RemitNode = {
  amount: Scalars['Float'];
  checkNumber?: Maybe<Scalars['String']>;
  customer: CustomerNode;
  date: Scalars['Date'];
  fileName: Scalars['String'];
  id: Scalars['UUID'];
  status: RemitStatuses;
};

export type RemitNodeRemitPaginationConnection = {
  pageInfo: PageInfo;
  results: Array<RemitNode>;
  summary: RemitSummary;
};

export type RemitOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type RemitReviseInput = {
  amount?: InputMaybe<Scalars['Float']>;
  checkNumber?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['UUID']>;
  customer?: InputMaybe<Scalars['UUID']>;
  date?: InputMaybe<Scalars['Date']>;
  fileName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export enum RemitStatuses {
  Attached = 'ATTACHED',
  NotAttached = 'NOT_ATTACHED'
}

export type RemitSummary = {
  allRemitIds: Array<Scalars['UUID']>;
};

export type ResolutionActivityCommentTagInput = {
  endIndex: Scalars['Int'];
  startIndex: Scalars['Int'];
  userId: Scalars['UUID'];
};

export type ResolutionActivityFilter = {
  AND?: InputMaybe<ResolutionActivityFilter>;
  NOT?: InputMaybe<ResolutionActivityFilter>;
  OR?: InputMaybe<ResolutionActivityFilter>;
  account?: InputMaybe<AccountFilter>;
  activityType?: InputMaybe<ResolutionActivityTypes>;
  amount?: InputMaybe<FloatFilterLookup>;
  comment?: InputMaybe<StrFilterLookup>;
  fundType?: InputMaybe<FundTypeFilter>;
  invoiceLine?: InputMaybe<InvoiceLineFilter>;
  promotionLine?: InputMaybe<PromotionLineFilter>;
  repayment?: InputMaybe<TransactionFilter>;
};

export type ResolutionActivityInput = {
  activityType: ResolutionActivityTypes;
  amount?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  invoiceLineId: Scalars['UUID'];
  suggestedFundTypeId?: InputMaybe<Scalars['UUID']>;
  userTags?: InputMaybe<Array<ResolutionActivityCommentTagInput>>;
};

export type ResolutionActivityIntroduceInput = {
  activityType: ResolutionActivityTypes;
  amount?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  deductionId?: InputMaybe<Scalars['UUID']>;
  invoiceId: Scalars['UUID'];
  invoiceLineId: Scalars['UUID'];
  parentRepaymentId?: InputMaybe<Scalars['UUID']>;
  suggestedFundTypeId?: InputMaybe<Scalars['UUID']>;
  userTags?: InputMaybe<Array<ResolutionActivityCommentTagInput>>;
};

export type ResolutionActivityNextCommentTagInput = {
  endIndex: Scalars['Int'];
  startIndex: Scalars['Int'];
  userId: Scalars['UUID'];
};

export type ResolutionActivityNextFilter = {
  AND?: InputMaybe<ResolutionActivityNextFilter>;
  NOT?: InputMaybe<ResolutionActivityNextFilter>;
  OR?: InputMaybe<ResolutionActivityNextFilter>;
  account?: InputMaybe<AccountFilter>;
  activityType?: InputMaybe<ResolutionActivityNextTypes>;
  amount?: InputMaybe<FloatFilterLookup>;
  comment?: InputMaybe<StrFilterLookup>;
  fundType?: InputMaybe<FundTypeFilter>;
  invoiceLine?: InputMaybe<InvoiceLineNextFilter>;
  promotionLine?: InputMaybe<PromotionLineFilter>;
  signedAmount?: InputMaybe<Scalars['Float']>;
};

export type ResolutionActivityNextNode = {
  account?: Maybe<AccountNode>;
  activityType: ResolutionActivityNextTypes;
  amount?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customCustomerField1?: Maybe<Scalars['String']>;
  customProductField1?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  fundType?: Maybe<FundTypeNode>;
  id: Scalars['UUID'];
  invoice: InvoiceNextNode;
  invoiceLine: InvoiceLineNextNode;
  productNumber?: Maybe<Scalars['String']>;
  profitCenter?: Maybe<Scalars['String']>;
  promotionLine?: Maybe<PromotionLineNode>;
  signedAmount?: Maybe<Scalars['Float']>;
};

export type ResolutionActivityNextNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<ResolutionActivityNextNode>;
};

export type ResolutionActivityNextOrder = {
  activityType?: InputMaybe<ResolutionActivityTypes>;
  createdAt?: InputMaybe<Ordering>;
};

export enum ResolutionActivityNextTypes {
  Clear = 'CLEAR',
  CreditWriteOff = 'CREDIT_WRITE_OFF',
  Dispute = 'DISPUTE',
  DisputeRepayment = 'DISPUTE_REPAYMENT',
  Offset = 'OFFSET',
  SalesReview = 'SALES_REVIEW',
  VoidClear = 'VOID_CLEAR',
  VoidDispute = 'VOID_DISPUTE',
  VoidSalesReview = 'VOID_SALES_REVIEW',
  VoidWriteOff = 'VOID_WRITE_OFF',
  WriteOff = 'WRITE_OFF'
}

export type ResolutionActivityNode = {
  account?: Maybe<AccountNode>;
  activityType: ResolutionActivityTypes;
  amount?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deduction?: Maybe<TransactionNode>;
  fundType?: Maybe<FundTypeNode>;
  id: Scalars['UUID'];
  invoice: InvoiceNode;
  invoiceLine: InvoiceLineNode;
  parentRepayment?: Maybe<TransactionNode>;
  promotionLine?: Maybe<PromotionLineNode>;
  signedAmount?: Maybe<Scalars['Float']>;
};

export type ResolutionActivityNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<ResolutionActivityNode>;
};

export type ResolutionActivityOrder = {
  activityType?: InputMaybe<ResolutionActivityTypes>;
  createdAt?: InputMaybe<Ordering>;
};

export type ResolutionActivityReviseInput = {
  activityType: ResolutionActivityTypes;
  amount?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  deductionId?: InputMaybe<Scalars['UUID']>;
  invoiceId: Scalars['UUID'];
  invoiceLineId: Scalars['UUID'];
  parentRepaymentId?: InputMaybe<Scalars['UUID']>;
  suggestedFundTypeId?: InputMaybe<Scalars['UUID']>;
  userTags?: InputMaybe<Array<ResolutionActivityCommentTagInput>>;
};

export enum ResolutionActivityTypes {
  Clear = 'CLEAR',
  CreditWriteOff = 'CREDIT_WRITE_OFF',
  Dispute = 'DISPUTE',
  DisputeRepayment = 'DISPUTE_REPAYMENT',
  SalesReview = 'SALES_REVIEW',
  WriteOff = 'WRITE_OFF'
}

export type RevenueBudgetColumnMapping = {
  customerName: Scalars['String'];
  date: Scalars['String'];
  productName: Scalars['String'];
  revenueBudget: Scalars['String'];
};

export type RevenueBudgetFileData = {
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<Maybe<Scalars['String']>>;
  revenueBudget: Array<Maybe<Scalars['String']>>;
};

export type RevenueBudgetFileDataInput = {
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<InputMaybe<Scalars['String']>>;
  revenueBudget: Array<InputMaybe<Scalars['String']>>;
};

export type RevenueBudgetFileDataOutput = RevenueBudgetFileData & {
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<Maybe<Scalars['String']>>;
  revenueBudget: Array<Maybe<Scalars['String']>>;
};

export type RevenueBudgetMappedFileResponse = {
  columnMapping: RevenueBudgetColumnMapping;
  fileData: RevenueBudgetFileDataOutput;
  validationResults: BudgetUploadValidationResponse;
};

export enum RevenueColumnHeaders {
  ActualUnitSales = 'actual_unit_sales',
  CustomerName = 'customer_name',
  Date = 'date',
  DisputedSpend = 'disputed_spend',
  ProductName = 'product_name',
  PurchaseOrder = 'purchase_order',
  Revenue = 'revenue',
  Source = 'source',
  Spend = 'spend',
  Stores = 'stores',
  UnitPrice = 'unit_price'
}

export type RevenueColumnMapping = {
  actualUnitSales: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  productName: Scalars['String'];
  purchaseOrder: Scalars['String'];
  revenue: Scalars['String'];
  stores: Scalars['String'];
};

export type RevenueEntryArgument = {
  actSales?: InputMaybe<Scalars['Float']>;
  amount?: Scalars['Float'];
  date: Scalars['String'];
  unitPrice?: InputMaybe<Scalars['Float']>;
};

export type RevenueFileData = {
  actualUnitSales: Array<Maybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<Maybe<Scalars['String']>>;
  purchaseOrder: Array<Maybe<Scalars['String']>>;
  revenue: Array<Maybe<Scalars['String']>>;
  stores: Array<Maybe<Scalars['String']>>;
};

export type RevenueFileDataInput = {
  actualUnitSales: Array<InputMaybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<InputMaybe<Scalars['String']>>;
  purchaseOrder: Array<InputMaybe<Scalars['String']>>;
  revenue: Array<InputMaybe<Scalars['String']>>;
  stores: Array<InputMaybe<Scalars['String']>>;
};

export type RevenueFileDataOutput = RevenueFileData & {
  actualUnitSales: Array<Maybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  productName: Array<Maybe<Scalars['String']>>;
  purchaseOrder: Array<Maybe<Scalars['String']>>;
  revenue: Array<Maybe<Scalars['String']>>;
  stores: Array<Maybe<Scalars['String']>>;
};

export type RevenueFilter = {
  AND?: InputMaybe<RevenueFilter>;
  NOT?: InputMaybe<RevenueFilter>;
  OR?: InputMaybe<RevenueFilter>;
  company?: InputMaybe<CompanyFilter>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DatetimeFilterLookup>;
  deletedAt?: InputMaybe<DatetimeFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  omni?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductFilter>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  source?: InputMaybe<RevenueSourceFilter>;
};

export type RevenueGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: RevenueUploadWarningEnum;
};

export type RevenueMappedFileResponse = {
  columnMapping: RevenueColumnMapping;
  fileData: RevenueFileDataOutput;
  validationResults: RevenueUploadValidationResponse;
};

export type RevenueNode = {
  actSales?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  company: CompanyNode;
  customer: CustomerNode;
  date: Scalars['DateTime'];
  id: Scalars['UUID'];
  numStores?: Maybe<Scalars['Float']>;
  product?: Maybe<ProductNode>;
  purchaseOrder?: Maybe<Scalars['String']>;
  source?: Maybe<RevenueSourceNode>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type RevenueNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<RevenueNode>;
};

export type RevenueOrder = {
  actSales?: InputMaybe<Ordering>;
  amount?: InputMaybe<Ordering>;
  date?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  numStores?: InputMaybe<Ordering>;
  unitPrice?: InputMaybe<Ordering>;
};

export type RevenueSourceFilter = {
  AND?: InputMaybe<RevenueSourceFilter>;
  NOT?: InputMaybe<RevenueSourceFilter>;
  OR?: InputMaybe<RevenueSourceFilter>;
  company?: InputMaybe<CompanyFilter>;
  id?: InputMaybe<UuidFilterLookup>;
  isAccountingSource?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StrFilterLookup>;
};

export type RevenueSourceNode = {
  company: CompanyNode;
  id: Scalars['UUID'];
  isAccountingSource: Scalars['Boolean'];
  isDaily: Scalars['Boolean'];
  isIncomingDeductionsPositive: Scalars['Boolean'];
  name: Scalars['String'];
  revenueItemsCount: Scalars['Int'];
};

export type RevenueSourceOrder = {
  name?: InputMaybe<Ordering>;
};

export type RevenueSourceUpdateResponse = {
  data?: Maybe<RevenueSourceUpdatedSuccessfully>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<RevenueSourceUpdateValidationErrors>;
};

export type RevenueSourceUpdateValidationErrors = {
  revenueItemExistsForRevenueSource?: Maybe<Scalars['Boolean']>;
  revenueSourceDoesNotExist?: Maybe<Scalars['Boolean']>;
  userDisallowedFromUpdatingRevenueSource?: Maybe<Scalars['Boolean']>;
};

export type RevenueSourceUpdatedSuccessfully = {
  revenueSource: RevenueSourceNode;
};

export type RevenueUploadErrors = {
  alreadyInDatabase?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  pricingNotFound?: Maybe<Array<Scalars['Int']>>;
  productNotFound?: Maybe<Array<Scalars['Int']>>;
  zeroRevenue?: Maybe<Array<Scalars['Int']>>;
};

export type RevenueUploadValidationResponse = {
  errors?: Maybe<RevenueUploadErrors>;
  warnings?: Maybe<RevenueUploadWarnings>;
};

export enum RevenueUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type RevenueUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: RevenueGroupedIndiceWarning;
};

export type ReviseBackupRetrievalInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  customer: Scalars['UUID'];
  isDeductionPositive?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ReviseBudgetDefaultViewInput = {
  budgetView?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['UUID']>;
};

export type ReviseBudgetDiffThresholdInput = {
  id: Scalars['UUID'];
  revenueLeSource?: InputMaybe<Scalars['String']>;
  threshold?: InputMaybe<Scalars['Float']>;
};

export type ReviseDrmEventCommentTagInput = {
  id: Scalars['UUID'];
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type ReviseDrmEventCommentTagNextInput = {
  id: Scalars['UUID'];
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type ReviseDiscountCutoffInput = {
  comparison?: InputMaybe<NumberComparison>;
  cutoffValue?: InputMaybe<Scalars['Float']>;
};

export type ReviseInvoiceLineMatchesInput = {
  id: Scalars['UUID'];
  matchedPromotionLineId: Scalars['String'];
};

export type ReviseInvoiceLineNextMatchesInput = {
  id: Scalars['UUID'];
  matchedPromotionLineId?: InputMaybe<Scalars['UUID']>;
};

export type ReviseRevenueSourceInput = {
  id: Scalars['UUID'];
  isAccountingSource?: InputMaybe<Scalars['Boolean']>;
  isDaily?: InputMaybe<Scalars['Boolean']>;
  isIncomingDeductionsPositive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RoleFilter = {
  AND?: InputMaybe<RoleFilter>;
  NOT?: InputMaybe<RoleFilter>;
  OR?: InputMaybe<RoleFilter>;
  company?: InputMaybe<CompanyFilter>;
  default?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UuidFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

export type RoleNode = {
  company: CompanyNode;
  default: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  permissions: Array<PermissionNode>;
  promotionApprovalLimit?: Maybe<PromotionApprovalLimitNode>;
  rolePromotionFilterSettings?: Maybe<RolePromotionFilterSettingsNode>;
  users: Array<UserNode>;
};


export type RoleNodePermissionsArgs = {
  filters?: InputMaybe<PermissionFilter>;
};


export type RoleNodeUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};

export type RolePromotionFilterSettingsInput = {
  appearsInFrontendFilters?: InputMaybe<Scalars['Boolean']>;
  lowerLimit?: InputMaybe<Scalars['Int']>;
  role: Scalars['UUID'];
};

export type RolePromotionFilterSettingsNode = {
  appearsInFrontendFilters?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  lowerLimit?: Maybe<Scalars['Int']>;
  role: RoleNode;
};

export type RolePromotionFilterSettingsPartialInput = {
  appearsInFrontendFilters?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['UUID']>;
  lowerLimit?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['UUID']>;
};

export enum SalesReviewNotificationSettingTypes {
  Email = 'EMAIL'
}

export type SalesReviewNotificationSettingsInput = {
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationTimeInUtc?: InputMaybe<NotificationTimeInput>;
  type: SalesReviewNotificationSettingTypes;
};

export type SalesReviewNotificationSettingsNode = {
  company: CompanyNode;
  daysOfWeek: Array<Scalars['String']>;
  id: Scalars['UUID'];
  isEnabled: Scalars['Boolean'];
  notificationTimeInUtc: Scalars['Time'];
  type: SalesReviewNotificationSettingTypes;
};

export type SaveEditedInvoiceLineNextInput = {
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  customer?: InputMaybe<Scalars['UUID']>;
  date?: InputMaybe<Scalars['String']>;
  fundType?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  openAmount?: InputMaybe<Scalars['Float']>;
  originalAmount?: InputMaybe<Scalars['Float']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  promoLineId?: InputMaybe<Scalars['UUID']>;
  spendRate?: InputMaybe<Scalars['Float']>;
  spendRatePct?: InputMaybe<Scalars['Float']>;
};

export type ScanInvoiceNextResponseNode = {
  columnMapping?: Maybe<InvoiceLineNextColumnMappingNode>;
  errorMessage?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceNextNode>;
  status: InvoiceLineUploadStatuses;
};

export type ScanInvoiceResponseNode = {
  columnMapping?: Maybe<InvoiceLineColumnMappingNode>;
  errorMessage?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceNode>;
  status: InvoiceLineUploadStatuses;
};

export enum ScrapingPortal {
  Costco = 'COSTCO',
  Kehe = 'KEHE'
}

export type SetIsContractInput = {
  id?: InputMaybe<Scalars['UUID']>;
  isContract?: InputMaybe<Scalars['Boolean']>;
};

export type SetLineAttachmentsForPromotionInput = {
  attachments: Array<Scalars['UUID']>;
  companyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  lines: Array<Scalars['UUID']>;
};

export type SetLineIsContractInput = {
  id?: InputMaybe<Scalars['UUID']>;
  isContract?: InputMaybe<Scalars['Boolean']>;
};

export type SettlementReportInput = {
  companyId: Scalars['UUID'];
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type SettlementReportRowNextNode = {
  accountName?: Maybe<Scalars['String']>;
  activityType: ResolutionActivityNextTypes;
  amountCleared?: Maybe<Scalars['Float']>;
  checkNumber?: Maybe<Scalars['String']>;
  clearedByUserName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  customCustomerField1?: Maybe<Scalars['String']>;
  customProductField1?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  dateCleared?: Maybe<Scalars['Date']>;
  deductionKey: Scalars['String'];
  deductionOriginalAmount: Scalars['Float'];
  firstReceivers?: Maybe<Scalars['String']>;
  fundTypeName?: Maybe<Scalars['String']>;
  invoiceLineProductGroupName?: Maybe<Scalars['String']>;
  invoiceLineProductName?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['String'];
  linkedInvoiceLineId?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  profitCenter?: Maybe<Scalars['String']>;
  promoCustomerName?: Maybe<Scalars['String']>;
  promotionLineLegacyId?: Maybe<Scalars['UUID']>;
  settlementKey: Scalars['String'];
  transactionCreateDate?: Maybe<Scalars['Date']>;
  transactionDate?: Maybe<Scalars['Date']>;
  transactionDisplayId: Scalars['String'];
  transactionInvoiceNumber?: Maybe<Scalars['String']>;
  transactionType?: Maybe<TransactionTypes>;
};

export type SettlementReportRowNode = {
  accountName?: Maybe<Scalars['String']>;
  activityType: ResolutionActivityTypes;
  amountCleared?: Maybe<Scalars['Float']>;
  clearedByUserName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  dateCleared?: Maybe<Scalars['DateTime']>;
  fundTypeName?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['String'];
  promotionLineLegacyId?: Maybe<Scalars['UUID']>;
  transactionCreateDate?: Maybe<Scalars['Date']>;
  transactionDisplayId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<TransactionTypes>;
};

export type SingleResolutionActivityFlagInput = {
  activityType: ResolutionActivityNextTypes;
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  comment: Scalars['String'];
  invoiceLineId: Scalars['UUID'];
  userTags?: InputMaybe<Array<ResolutionActivityNextCommentTagInput>>;
};

export type SingleResolutionActivityResponseNode = {
  errorMessage?: Maybe<Scalars['String']>;
  resolutionActivity?: Maybe<ResolutionActivityNextNode>;
};

export type SingleResolutionClearWriteOffInput = {
  activityType: ResolutionActivityNextTypes;
  amount: Scalars['Float'];
  comment?: InputMaybe<Scalars['String']>;
  invoiceLineId: Scalars['UUID'];
  suggestedFundTypeId?: InputMaybe<Scalars['UUID']>;
  userTags?: InputMaybe<Array<ResolutionActivityNextCommentTagInput>>;
};

export type SingleResolutionOffsetInput = {
  comment: Scalars['String'];
  invoiceLineId: Scalars['UUID'];
  linkedInvoiceLineId: Scalars['UUID'];
  promotionLineId?: InputMaybe<Scalars['UUID']>;
  suggestedFundTypeId?: InputMaybe<Scalars['UUID']>;
  userTags?: InputMaybe<Array<ResolutionActivityNextCommentTagInput>>;
};

export type SiteWideSearchResponse = {
  promotions: Array<SiteWideSearchResponseItem>;
  transactions: Array<SiteWideSearchResponseItem>;
};

export type SiteWideSearchResponseItem = {
  id: Scalars['UUID'];
  label: Scalars['String'];
  type: Scalars['String'];
};

export type SoftwareVersionNode = {
  id: Scalars['UUID'];
  number: Scalars['Int'];
};

export enum SpendColumnHeaders {
  Account = 'account',
  ActualSpend = 'actual_spend',
  CheckNumber = 'check_number',
  CustomerName = 'customer_name',
  Date = 'date',
  LineKey = 'line_key',
  ProductName = 'product_name'
}

export type SpendColumnMapping = {
  account: Scalars['String'];
  actualSpend: Scalars['String'];
  checkNumber: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  lineKey: Scalars['String'];
};

export type SpendFileData = {
  account: Array<Maybe<Scalars['String']>>;
  actualSpend: Array<Scalars['String']>;
  checkNumber: Array<Maybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  lineKey: Array<Scalars['String']>;
};

export type SpendFileDataInput = {
  account: Array<InputMaybe<Scalars['String']>>;
  actualSpend: Array<Scalars['String']>;
  checkNumber: Array<InputMaybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  lineKey: Array<Scalars['String']>;
};

export type SpendFileDataOutput = SpendFileData & {
  account: Array<Maybe<Scalars['String']>>;
  actualSpend: Array<Scalars['String']>;
  checkNumber: Array<Maybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  date: Array<Scalars['String']>;
  lineKey: Array<Scalars['String']>;
};

export type SpendFilter = {
  AND?: InputMaybe<SpendFilter>;
  NOT?: InputMaybe<SpendFilter>;
  OR?: InputMaybe<SpendFilter>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  customer?: InputMaybe<CustomerFilter>;
  date?: InputMaybe<DatetimeFilterLookup>;
  fundType?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<UuidFilterLookup>;
  omni?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['UUID']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
  promotion?: InputMaybe<PromotionFilter>;
  promotionLine?: InputMaybe<PromotionLineFilter>;
  sourceIsDrm?: InputMaybe<Scalars['Boolean']>;
  spend?: InputMaybe<FloatFilterLookup>;
};

export type SpendGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: SpendUploadWarningEnum;
};

export type SpendIndividualIndiceWarning = IndividualIndiceWarning & {
  indices?: Maybe<Array<Scalars['Int']>>;
  warningType: SpendUploadWarningEnum;
};

export type SpendInput = {
  checkNumber?: InputMaybe<Scalars['String']>;
  company: Scalars['UUID'];
  customer: Scalars['UUID'];
  date: Scalars['DateTime'];
  promotion: Scalars['UUID'];
  promotionLine: Scalars['UUID'];
  sales?: InputMaybe<Scalars['Float']>;
  spend: Scalars['Float'];
};

export type SpendMappedFileResponse = {
  columnMapping: SpendColumnMapping;
  fileData: SpendFileDataOutput;
  validationResults: SpendUploadValidationResponse;
};

export type SpendNode = {
  checkNumber?: Maybe<Scalars['String']>;
  company: CompanyNode;
  customer: CustomerNode;
  date: Scalars['DateTime'];
  id: Scalars['UUID'];
  legacyId?: Maybe<Scalars['String']>;
  promotion: PromotionNode;
  promotionLine: PromotionLineNode;
  resolutionActivity?: Maybe<ResolutionActivityNextNode>;
  sales?: Maybe<Scalars['Float']>;
  sourceIsDrm: Scalars['Boolean'];
  spend: Scalars['Float'];
  spendRate?: Maybe<Scalars['Float']>;
};

export type SpendNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<SpendNode>;
};

export type SpendOrder = {
  createdAt?: InputMaybe<Ordering>;
  customer_Name?: InputMaybe<Ordering>;
  date?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  spend?: InputMaybe<Ordering>;
};

export type SpendUploadErrors = {
  accountNotFound?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  declinedOrCancelledPromotions?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  indirectRetailers?: Maybe<Array<Scalars['Int']>>;
  lineNotFound?: Maybe<Array<Scalars['Int']>>;
  zeroSpend?: Maybe<Array<Scalars['Int']>>;
};

export type SpendUploadValidationResponse = {
  errors?: Maybe<SpendUploadErrors>;
  warnings?: Maybe<SpendUploadWarnings>;
};

export enum SpendUploadWarningEnum {
  AlreadyInDatabase = 'ALREADY_IN_DATABASE',
  InfileDuplicates = 'INFILE_DUPLICATES',
  MismatchedAccounts = 'MISMATCHED_ACCOUNTS',
  MismatchedCustomers = 'MISMATCHED_CUSTOMERS'
}

export type SpendUploadWarnings = {
  AlreadyInDatabase?: Maybe<Array<Scalars['Int']>>;
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  MismatchedAccounts?: Maybe<Array<Scalars['Int']>>;
  MismatchedCustomers?: Maybe<Array<Scalars['Int']>>;
  alreadyInDatabase: SpendIndividualIndiceWarning;
  infileDuplicates: SpendGroupedIndiceWarning;
  mismatchedAccounts: SpendIndividualIndiceWarning;
  mismatchedCustomers: SpendIndividualIndiceWarning;
};

export type StorageTransferRequest = {
  destinationBucket: Scalars['String'];
  destinationObjectName: Scalars['String'];
  shouldMove?: Scalars['Boolean'];
  sourceBucket: Scalars['String'];
  sourceObjectName: Scalars['String'];
};

export type StorageTransferResponse = {
  destinationBucket: Scalars['String'];
  destinationObjectName: Scalars['String'];
  sourceBucket: Scalars['String'];
  sourceObjectName: Scalars['String'];
};

export type StrFilterLookup = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  exact?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iContains?: InputMaybe<Scalars['String']>;
  iEndsWith?: InputMaybe<Scalars['String']>;
  iExact?: InputMaybe<Scalars['String']>;
  iRegex?: InputMaybe<Scalars['String']>;
  iStartsWith?: InputMaybe<Scalars['String']>;
  inList?: InputMaybe<Array<Scalars['String']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Array<Scalars['String']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubmitBudgetResponse = {
  success: Scalars['Boolean'];
  validationResults: BudgetUploadValidationResponse;
};

export type SubmitBumpChartResponse = {
  success: Scalars['Boolean'];
  validationResults: BumpChartUploadValidationResponse;
};

export type SubmitContactResponse = {
  success: Scalars['Boolean'];
  validationResults: ContactUploadValidationResponse;
};

export type SubmitLatestEstimateResponse = {
  success: Scalars['Boolean'];
  validationResults: LatestEstimateUploadValidationResponse;
};

export type SubmitLiftResponse = {
  success: Scalars['Boolean'];
  validationResults: LiftUploadValidationResponse;
};

export type SubmitPointOfSaleResponse = {
  success: Scalars['Boolean'];
  validationResults: PointOfSaleUploadValidationResponse;
};

export type SubmitPricingResponse = {
  success: Scalars['Boolean'];
  validationResults: PricingUploadValidationResponse;
};

export type SubmitProductResponse = {
  success: Scalars['Boolean'];
  validationResults: ProductUploadValidationResponse;
};

export type SubmitPromotionResponse = {
  success: Scalars['Boolean'];
  validationResults: PromotionUploadValidationResponse;
};

export type SubmitRevenueResponse = {
  success: Scalars['Boolean'];
  validationResults: RevenueUploadValidationResponse;
};

export type SubmitSpendResponse = {
  success: Scalars['Boolean'];
  validationResults: SpendUploadValidationResponse;
};

export type SubmitTransactionNextResponse = {
  success: Scalars['Boolean'];
  validationResults: TransactionUploadValidationResponse;
};

export type SubmitTransactionResponse = {
  success: Scalars['Boolean'];
  validationResults: TransactionUploadValidationResponse;
};

export type TestLiftPredictionModelsOutput = {
  mae: Scalars['Float'];
  message: Scalars['String'];
  rmse: Scalars['Float'];
  success: Scalars['Boolean'];
};

export enum Tier {
  Emerging = 'EMERGING',
  Growth = 'GROWTH',
  Scale = 'SCALE'
}

export type TierChoicesReturn = {
  id: Scalars['String'];
  label: Scalars['String'];
};

export type ToggleBackupNotAvailableInput = {
  backupNotAvailable: Scalars['Boolean'];
  id: Scalars['UUID'];
};

export type TokenFilter = {
  AND?: InputMaybe<TokenFilter>;
  NOT?: InputMaybe<TokenFilter>;
  OR?: InputMaybe<TokenFilter>;
  created?: InputMaybe<DatetimeFilterLookup>;
  user?: InputMaybe<UserFilter>;
};

export type TokenInput = {
  user: Scalars['UUID'];
};

export type TokenNode = {
  created: Scalars['DateTime'];
  user: UserNode;
};

export type TokenNodeWithKey = {
  created: Scalars['DateTime'];
  key: Scalars['String'];
  user: UserNode;
};

export type TrainLiftPredictionModelsOutput = {
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type TransactionAmountFilterRangeNode = {
  maxOpenAmount: Scalars['Float'];
  maxSignedAmount: Scalars['Float'];
  minOpenAmount: Scalars['Float'];
  minSignedAmount: Scalars['Float'];
};

export type TransactionAmountFilterRangeNodeNext = {
  maxOpenAmount: Scalars['Float'];
  maxSignedAmount: Scalars['Float'];
  minOpenAmount: Scalars['Float'];
  minSignedAmount: Scalars['Float'];
};

export enum TransactionBackupStatuses {
  Attached = 'ATTACHED',
  NotAvailable = 'NOT_AVAILABLE',
  Pending = 'PENDING'
}

export type TransactionColumnMapping = {
  amount: Scalars['String'];
  checkNumber: Scalars['String'];
  customerName: Scalars['String'];
  invoiceNumber: Scalars['String'];
  transactionDate: Scalars['String'];
  transactionId: Scalars['String'];
  transactionType: Scalars['String'];
};

export type TransactionColumnMappingFilter = {
  AND?: InputMaybe<TransactionColumnMappingFilter>;
  NOT?: InputMaybe<TransactionColumnMappingFilter>;
  OR?: InputMaybe<TransactionColumnMappingFilter>;
  amount?: InputMaybe<StrFilterLookup>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  customerName?: InputMaybe<StrFilterLookup>;
  date?: InputMaybe<StrFilterLookup>;
  displayId?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  invoiceNumber?: InputMaybe<StrFilterLookup>;
  uploadFile?: InputMaybe<CompanyFileFilter>;
};

export type TransactionColumnMappingNode = {
  amount: Scalars['String'];
  checkNumber: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  displayId: Scalars['String'];
  id: Scalars['UUID'];
  invoiceNumber: Scalars['String'];
  uploadFile: CompanyFileNode;
};

export type TransactionColumnMappingNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<TransactionColumnMappingNode>;
};

export type TransactionColumnMappingOrder = {
  createdAt?: InputMaybe<Ordering>;
};

export type TransactionFileData = {
  amount: Array<Scalars['String']>;
  checkNumber: Array<Maybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  invoiceNumber: Array<Scalars['String']>;
  transactionDate: Array<Scalars['String']>;
  transactionId: Array<Scalars['String']>;
  transactionType: Array<Maybe<Scalars['String']>>;
};

export type TransactionFileDataInput = {
  amount: Array<Scalars['String']>;
  checkNumber: Array<InputMaybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  invoiceNumber: Array<Scalars['String']>;
  transactionDate: Array<Scalars['String']>;
  transactionId: Array<Scalars['String']>;
  transactionType: Array<InputMaybe<Scalars['String']>>;
};

export type TransactionFileDataOutput = TransactionFileData & {
  amount: Array<Scalars['String']>;
  checkNumber: Array<Maybe<Scalars['String']>>;
  customerName: Array<Scalars['String']>;
  invoiceNumber: Array<Scalars['String']>;
  transactionDate: Array<Scalars['String']>;
  transactionId: Array<Scalars['String']>;
  transactionType: Array<Maybe<Scalars['String']>>;
};

export type TransactionFilter = {
  AND?: InputMaybe<TransactionFilter>;
  NOT?: InputMaybe<TransactionFilter>;
  OR?: InputMaybe<TransactionFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  assignedUsers?: InputMaybe<UserFilter>;
  attachedRepayments?: InputMaybe<TransactionFilter>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  customerName?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<DateFilterLookup>;
  displayId?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  invoiceNumber?: InputMaybe<StrFilterLookup>;
  linkedInvoices?: InputMaybe<InvoiceFilter>;
  openAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  originalFile?: InputMaybe<CompanyFileFilter>;
  signedAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  sortAssignedUserNames?: InputMaybe<Ordering>;
  sortOpenAmount?: InputMaybe<Ordering>;
  sortSignedAmount?: InputMaybe<Ordering>;
  status?: InputMaybe<StrFilterLookup>;
  status_In?: InputMaybe<Array<TransactionStatuses>>;
  transactionType?: InputMaybe<StrFilterLookup>;
  typeName?: InputMaybe<Scalars['String']>;
  userSearch?: InputMaybe<Scalars['String']>;
};

export type TransactionGroupedIndiceWarning = GroupedIndiceWarning & {
  indices?: Maybe<Array<Array<Scalars['Int']>>>;
  warningType: TransactionUploadWarningEnum;
};

export type TransactionIntroduceInput = {
  accountingSourceId: Scalars['UUID'];
  amount: Scalars['Float'];
  checkNumber?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  date: Scalars['Date'];
  displayId: Scalars['String'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  originalFileId?: InputMaybe<Scalars['UUID']>;
  status: TransactionStatuses;
  transactionType: TransactionTypes;
};

export enum TransactionIssueType {
  Error = 'ERROR',
  Warning = 'WARNING'
}

export type TransactionMappedFileResponse = {
  columnMapping: TransactionColumnMapping;
  fileData: TransactionFileDataOutput;
  validationResults: TransactionUploadValidationResponse;
};

export type TransactionNextFilter = {
  AND?: InputMaybe<TransactionNextFilter>;
  NOT?: InputMaybe<TransactionNextFilter>;
  OR?: InputMaybe<TransactionNextFilter>;
  accountingSource?: InputMaybe<RevenueSourceFilter>;
  amount?: InputMaybe<FloatFilterLookup>;
  assignedUsers?: InputMaybe<UserFilter>;
  backupFiles?: InputMaybe<CompanyFileFilter>;
  backupStatus?: InputMaybe<Scalars['String']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  checkNumber?: InputMaybe<StrFilterLookup>;
  company?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<DatetimeFilterLookup>;
  customer?: InputMaybe<CustomerFilter>;
  customerName?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<DateFilterLookup>;
  displayId?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  invoiceNumber?: InputMaybe<StrFilterLookup>;
  openAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  signedAmountRange?: InputMaybe<Array<Scalars['Float']>>;
  sortAssignedUserNames?: InputMaybe<Ordering>;
  sortBackupStatus?: InputMaybe<Ordering>;
  sortOpenAmount?: InputMaybe<Ordering>;
  sortSignedAmount?: InputMaybe<Ordering>;
  sortStatus?: InputMaybe<Ordering>;
  status?: InputMaybe<Scalars['String']>;
  status_In?: InputMaybe<Array<TransactionStatuses>>;
  transactionType?: InputMaybe<StrFilterLookup>;
  typeName?: InputMaybe<Scalars['String']>;
  userSearch?: InputMaybe<Scalars['String']>;
};

export type TransactionNextIntroduceInput = {
  accountingSourceId: Scalars['UUID'];
  amount: Scalars['Float'];
  checkNumber?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  customerId: Scalars['UUID'];
  date: Scalars['Date'];
  displayId: Scalars['String'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  originalFileId?: InputMaybe<Scalars['UUID']>;
  transactionType: TransactionTypes;
};

export enum TransactionNextIssueType {
  Error = 'ERROR',
  Warning = 'WARNING'
}

export type TransactionNextNode = {
  amount: Scalars['Float'];
  appliedResolutionActivities: Array<ResolutionActivityNextNode>;
  assignedUsers: Array<UserNode>;
  backupFiles: Array<BackupFileNode>;
  backupNotAvailable: Scalars['Boolean'];
  backupStatus: TransactionBackupStatuses;
  cancelled: Scalars['Boolean'];
  checkNumber?: Maybe<Scalars['String']>;
  childrenResolutionActivities: Array<ResolutionActivityNextNode>;
  company: CompanyNode;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: CustomerNode;
  date: Scalars['Date'];
  displayId: Scalars['String'];
  drmV1Id?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoices: Array<InvoiceNextNode>;
  openAmount: Scalars['Float'];
  signedAmount: Scalars['Float'];
  status: TransactionStatuses;
  transactionType: TransactionTypes;
};


export type TransactionNextNodeAssignedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};


export type TransactionNextNodeInvoicesArgs = {
  filters?: InputMaybe<InvoiceNextFilter>;
};

export type TransactionNextNodeTransactionPaginationConnection = {
  pageInfo: PageInfo;
  results: Array<TransactionNextNode>;
  summary: TransactionSummaryNext;
};

export type TransactionNextOrder = {
  amount?: InputMaybe<Ordering>;
  checkNumber?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  date?: InputMaybe<Ordering>;
  displayId?: InputMaybe<Ordering>;
  invoiceNumber?: InputMaybe<Ordering>;
  signedAmount?: InputMaybe<Ordering>;
  transactionType?: InputMaybe<Ordering>;
};

export type TransactionNextReviseInput = {
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  cancellationReason?: InputMaybe<Scalars['String']>;
};

export type TransactionNode = {
  amount: Scalars['Float'];
  appliedResolutionActivities: Array<ResolutionActivityNode>;
  assignedUsers: Array<UserNode>;
  attachedDeductions: Array<TransactionNode>;
  attachedRepayments: Array<TransactionNode>;
  checkNumber?: Maybe<Scalars['String']>;
  childrenResolutionActivities: Array<ResolutionActivityNode>;
  company: CompanyNode;
  createUser?: Maybe<UserNode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer: CustomerNode;
  date: Scalars['Date'];
  displayId: Scalars['String'];
  hasAtLeastOneLinkedInvoice: Scalars['Boolean'];
  hasResolutionHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  linkedInvoices: Array<InvoiceNode>;
  openAmount: Scalars['Float'];
  originalFile: CompanyFileNode;
  signedAmount: Scalars['Float'];
  status: TransactionStatuses;
  transactionType: TransactionTypes;
};


export type TransactionNodeAssignedUsersArgs = {
  filters?: InputMaybe<UserFilter>;
};


export type TransactionNodeAttachedDeductionsArgs = {
  filters?: InputMaybe<TransactionFilter>;
};


export type TransactionNodeAttachedRepaymentsArgs = {
  filters?: InputMaybe<TransactionFilter>;
};


export type TransactionNodeLinkedInvoicesArgs = {
  filters?: InputMaybe<InvoiceFilter>;
};

export type TransactionNodeTransactionPaginationConnection = {
  pageInfo: PageInfo;
  results: Array<TransactionNode>;
  summary: TransactionSummary;
};

export type TransactionOrder = {
  amount?: InputMaybe<Ordering>;
  checkNumber?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  customer?: InputMaybe<CustomerOrder>;
  date?: InputMaybe<Ordering>;
  displayId?: InputMaybe<Ordering>;
  invoiceNumber?: InputMaybe<Ordering>;
  signedAmount?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  transactionType?: InputMaybe<Ordering>;
};

export type TransactionReviseInput = {
  assignedUsers?: InputMaybe<Array<Scalars['UUID']>>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TransactionStatuses>;
};

export enum TransactionStatuses {
  Cancelled = 'CANCELLED',
  NeedsAttention = 'NEEDS_ATTENTION',
  New = 'NEW',
  Pending = 'PENDING',
  Resolved = 'RESOLVED'
}

export type TransactionSummary = {
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export type TransactionSummaryNext = {
  allTransactionIds: Array<Scalars['UUID']>;
  totalOpenAmount: Scalars['Float'];
  totalSignedAmount: Scalars['Float'];
};

export enum TransactionTypes {
  Billpay = 'BILLPAY',
  Deduction = 'DEDUCTION',
  Repayment = 'REPAYMENT'
}

export type TransactionUploadErrors = {
  alreadyInDatabase?: Maybe<Array<Scalars['Int']>>;
  customerNotFound?: Maybe<Array<Scalars['Int']>>;
  fieldTypeErrors?: Maybe<Array<FieldValidationErrorType>>;
  typeNotSupported?: Maybe<Array<Scalars['Int']>>;
};

export type TransactionUploadValidationResponse = {
  errors?: Maybe<TransactionUploadErrors>;
  warnings?: Maybe<TransactionUploadWarnings>;
};

export enum TransactionUploadWarningEnum {
  InfileDuplicates = 'INFILE_DUPLICATES'
}

export type TransactionUploadWarnings = {
  InfileDuplicates?: Maybe<Array<Array<Scalars['Int']>>>;
  infileDuplicates: TransactionGroupedIndiceWarning;
};

export type UuidFilterLookup = {
  contains?: InputMaybe<Scalars['UUID']>;
  endsWith?: InputMaybe<Scalars['UUID']>;
  exact?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  iContains?: InputMaybe<Scalars['UUID']>;
  iEndsWith?: InputMaybe<Scalars['UUID']>;
  iExact?: InputMaybe<Scalars['UUID']>;
  iRegex?: InputMaybe<Scalars['String']>;
  iStartsWith?: InputMaybe<Scalars['UUID']>;
  inList?: InputMaybe<Array<Scalars['UUID']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  range?: InputMaybe<Array<Scalars['UUID']>>;
  regex?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['UUID']>;
};

export type UnlinkInvoiceFromTransactionInput = {
  invoiceId: Scalars['UUID'];
  transactionId: Scalars['UUID'];
};

export type UpdateBackupFileInput = {
  checkNumber?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

export type UpdateBackupSourceGroupInput = {
  confidenceThreshold?: InputMaybe<Scalars['Int']>;
  customers?: InputMaybe<Array<Scalars['UUID']>>;
  enforceSourceMatch?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  wildcardCustomer?: InputMaybe<Scalars['UUID']>;
};

export type UpdateBackupSourceInput = {
  displayName?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  scannerName?: InputMaybe<Scalars['String']>;
  sensibleConfigurations?: InputMaybe<Array<Scalars['String']>>;
  sourceType?: InputMaybe<BackupSourceTypes>;
};

export type UpdateBackupSourceSettingsOverrideInput = {
  confidenceThreshold?: InputMaybe<Scalars['Int']>;
  customers?: InputMaybe<Array<Scalars['UUID']>>;
  enforceSourceMatch?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  wildcardCustomer?: InputMaybe<Scalars['UUID']>;
};

export type UpdateBackupSourceTemplateFileInput = {
  backupSource: Scalars['UUID'];
  id: Scalars['UUID'];
  internalFile: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateBulkInvoiceLineStatusesForInvoiceResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<InvoiceNode>>;
};

export type UpdateDsmFreeTrialInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  maxDays?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type UpdateDeductionMatchingColumnMappingInput = {
  amount?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  customerNameAcc?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  fundType?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  productName?: InputMaybe<Scalars['String']>;
  spendRate?: InputMaybe<Scalars['String']>;
};

export type UpdateDeductionMatchingColumnOrderInput = {
  id: Scalars['UUID'];
  orderIndex: Scalars['Int'];
};

export type UpdateDeductionMatchingInstanceInput = {
  backupSource?: InputMaybe<Scalars['UUID']>;
  backupSourceGroup?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  resultFile?: InputMaybe<Scalars['UUID']>;
  status?: InputMaybe<Scalars['String']>;
  uploadFile?: InputMaybe<Scalars['UUID']>;
};

export type UpdateDeductionScanFileInput = {
  pagesScanned?: InputMaybe<Scalars['Int']>;
  status: DeductionScanningStatus;
};

export type UpdateDeductionScanInput = {
  allScansZip?: InputMaybe<Scalars['UUID']>;
  autoClassificationName?: InputMaybe<Scalars['String']>;
  concatenatedScan?: InputMaybe<Scalars['UUID']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  status?: InputMaybe<DeductionScanningStatus>;
  uploadedFiles?: InputMaybe<Array<UpdateFileInput>>;
};

export type UpdateFileInput = {
  convertedFile?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

export type UpdateInternalFileInput = {
  customerVisible?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};

export type UpdateInvoiceError = {
  errorMessage: Scalars['String'];
};

export type UpdateInvoiceLineError = {
  errorMessage: Scalars['String'];
};

export type UpdateInvoiceLineResponse = InvoiceLineNode | UpdateInvoiceLineError;

export type UpdateInvoiceMappingInput = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['UUID'];
  productName: Scalars['String'];
  spendRate: Scalars['String'];
  spendRatePct: Scalars['String'];
};

export type UpdateInvoiceMappingInputNext = {
  amount: Scalars['String'];
  customerName: Scalars['String'];
  date: Scalars['String'];
  id: Scalars['UUID'];
  productName: Scalars['String'];
  spendRate: Scalars['String'];
  spendRatePct: Scalars['String'];
};

export type UpdateInvoiceResponse = InvoiceNode | UpdateInvoiceError;

export type UpdateInvoiceResponseNext = {
  error?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceNextNode>;
};

export type UpdateLiftBucketInput = {
  fundTypes?: InputMaybe<Array<Scalars['UUID']>>;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateLiftInput = {
  id: Scalars['UUID'];
  liftValue?: InputMaybe<Scalars['Float']>;
};

export type UpdateLineActualLiftsCronOutput = {
  promotionPosLinesRelationsCreated: Scalars['Int'];
  promotionsUpdated: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type UpdatePromotionLineLiftInput = {
  lift?: InputMaybe<Scalars['Float']>;
  promotionLineKey: Scalars['String'];
};

export type UpdatePromotionLineStatusInput = {
  closed?: InputMaybe<Scalars['Boolean']>;
  lineIds: Array<Scalars['UUID']>;
  promotionId?: InputMaybe<Scalars['UUID']>;
};

export type UpdatePromotionStarInput = {
  companyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePromotionStatusInput = {
  companyId: Scalars['UUID'];
  fromStatus: PromotionStatus;
  id?: InputMaybe<Scalars['UUID']>;
  status: PromotionStatus;
};

export type UpdatePromotionTagInput = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  textColor?: InputMaybe<Scalars['String']>;
};

export type UpdatePromotionTagsInput = {
  companyId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  tags: Array<Scalars['ID']>;
};

export type UpdateRoleInput = {
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<ManyToManyInput>;
  users?: InputMaybe<ManyToManyInput>;
};

export type UpdateSeasonalityBucketInput = {
  bucketId?: InputMaybe<Scalars['UUID']>;
  bucketName: Scalars['String'];
  company: Scalars['UUID'];
  customers: Array<Scalars['UUID']>;
  isAllCustomers: Scalars['Boolean'];
  isAllProducts: Scalars['Boolean'];
  products: Array<Scalars['UUID']>;
};

export type UpdateSeasonalityResult = {
  allCustomersError: Scalars['Boolean'];
  allProductsError: Scalars['Boolean'];
  bucketId: Scalars['UUID'];
  conflictingCustomers: Array<Scalars['UUID']>;
  conflictingProducts: Array<Scalars['UUID']>;
  isSuccessful: Scalars['Boolean'];
};

export type UpdateSpendSplitInput = {
  customer: Scalars['UUID'];
  distributor: Scalars['UUID'];
  percentSplit?: InputMaybe<Scalars['Float']>;
  productGroup?: InputMaybe<Scalars['UUID']>;
};

export type UpdateTransactionError = {
  errorMessage: Scalars['String'];
};

export type UpdateTransactionNextBackupsInput = {
  backups: Array<UpdateBackupFileInput>;
  transactionId: Scalars['UUID'];
};

export type UpdateTransactionNextBackupsResponse = {
  backupFilesDeletedNum: Scalars['Int'];
  relevantInvoicesNum: Scalars['Int'];
};

export type UpdateTransactionNextErrorAgain = {
  errorMessage: Scalars['String'];
};

export type UpdateTransactionResponse = TransactionNode | UpdateTransactionError;

export type UpdateTransactionResponseNext = TransactionNextNode | UpdateTransactionNextErrorAgain;

export type UploadColumnHeader = {
  existingMapping?: Maybe<Scalars['JSON']>;
  header: Array<Scalars['String']>;
  sheet: Scalars['Int'];
};

export type UploadFileRegisteredResponse = {
  company: Scalars['UUID'];
  createUser: Scalars['UUID'];
  fileType: FileTypes;
  id: Scalars['UUID'];
  visibleName: Scalars['String'];
};

export type UploadSignedUrlResponse = {
  filePath: Scalars['String'];
  signedUrl: Scalars['String'];
  tempFileId: Scalars['UUID'];
};

export type UploadTemplateFileFilter = {
  AND?: InputMaybe<UploadTemplateFileFilter>;
  NOT?: InputMaybe<UploadTemplateFileFilter>;
  OR?: InputMaybe<UploadTemplateFileFilter>;
  category?: InputMaybe<InternalFileCategories>;
  id?: InputMaybe<UuidFilterLookup>;
  internalFile?: InputMaybe<InternalFileFilter>;
};

export type UploadTemplateFileNode = {
  category: InternalFileCategories;
  id: Scalars['UUID'];
  internalFile: InternalFileNode;
};

export type UserFileHeader = {
  fileHeader?: InputMaybe<Scalars['String']>;
  internalHeader: Scalars['String'];
};

export type UserFilter = {
  AND?: InputMaybe<UserFilter>;
  NOT?: InputMaybe<UserFilter>;
  OR?: InputMaybe<UserFilter>;
  companies?: InputMaybe<DjangoModelFilterInput>;
  email?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<UuidFilterLookup>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StrFilterLookup>;
  phone?: InputMaybe<StrFilterLookup>;
};

export type UserInput = {
  companies: Array<Scalars['UUID']>;
  email: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  roles: Array<Scalars['UUID']>;
};

export type UserNode = {
  companies: Array<CompanyNode>;
  customers: Array<CustomerNode>;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  roles: Array<RoleNode>;
};


export type UserNodeCustomersArgs = {
  filters?: InputMaybe<CustomerFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};


export type UserNodeRolesArgs = {
  filters?: InputMaybe<RoleFilter>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

export type UserNodePaginationConnection = {
  pageInfo: PageInfo;
  results: Array<UserNode>;
};

export type UserOrder = {
  dateJoined?: InputMaybe<Ordering>;
  email?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  phone?: InputMaybe<Ordering>;
};

export type UserPartialInput = {
  companies: Array<Scalars['UUID']>;
  email?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  roles: Array<Scalars['UUID']>;
};

export type UserSettingsFilter = {
  AND?: InputMaybe<UserSettingsFilter>;
  NOT?: InputMaybe<UserSettingsFilter>;
  OR?: InputMaybe<UserSettingsFilter>;
  settingsType?: InputMaybe<UserSettingsTypes>;
  user: UserFilter;
};

export type UserSettingsInput = {
  settingsType: UserSettingsTypes;
  user: Scalars['UUID'];
  value: Scalars['Boolean'];
};

export type UserSettingsNode = {
  id: Scalars['UUID'];
  settingsType: UserSettingsTypes;
  user: UserNode;
  value: Scalars['Boolean'];
};

export enum UserSettingsTypes {
  DrmAggregateInvoiceLines = 'DRM_AGGREGATE_INVOICE_LINES',
  DrmAutopopulateProductGroup = 'DRM_AUTOPOPULATE_PRODUCT_GROUP',
  DrmShowClosedPromotions = 'DRM_SHOW_CLOSED_PROMOTIONS'
}

export type UserToCustomerInput = {
  customer: Scalars['UUID'];
  users: Array<Scalars['UUID']>;
};

export type VoidResolutionActivitiesInput = {
  invoiceLineIds: Array<Scalars['UUID']>;
};

export type VoidResolutionActivitiesResponseNode = {
  errorMessage?: Maybe<Scalars['String']>;
  resolutionActivities?: Maybe<Array<ResolutionActivityNextNode>>;
  unlinkedInvoiceLine?: Maybe<InvoiceLineNextNode>;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type DeleteUserMutation = { deleteUsers: Array<{ id: string }> };

export type CreateUserMutationVariables = Exact<{
  data: UserInput;
}>;


export type CreateUserMutation = { createCompanyUser: { name: string, email: string } };

export type UpdateUserMutationVariables = Exact<{
  data: UserPartialInput;
  filters?: InputMaybe<UserFilter>;
}>;


export type UpdateUserMutation = { updateUsers: Array<{ id: string, name: string, email: string }> };

export type AssignUserToCompanyMutationVariables = Exact<{
  data: CompanyUserRelationshipInput;
}>;


export type AssignUserToCompanyMutation = { assignUserToCompany?: { id: string } | null };

export type UnassignUserMutationVariables = Exact<{
  data: CompanyUserRelationshipPartialInput;
}>;


export type UnassignUserMutation = { unassignUser?: { id: string } | null };

export type GetAllCompaniesQueryVariables = Exact<{
  order?: InputMaybe<CompanyOrder>;
}>;


export type GetAllCompaniesQuery = { companies: Array<{ id: string, name: string, logoutTime: number }> };

export type GetUsersByCompanyQueryVariables = Exact<{
  companyId: Scalars['UUID'];
}>;


export type GetUsersByCompanyQuery = { company: { users: Array<{ id: string, name: string, email: string, roles: Array<{ name: string, company: { id: string } }> }> } };

export type GetAllUnassignedUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUnassignedUsersQuery = { unassignedUsers: Array<{ id: string, email: string, name: string }> };


export const DeleteUserDocument = gql`
    mutation DeleteUser($userId: UUID!) {
  deleteUsers(filters: {id: {exact: $userId}}) {
    id
  }
}
    `;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
};
export const CreateUserDocument = gql`
    mutation CreateUser($data: UserInput!) {
  createCompanyUser(data: $data) {
    name
    email
  }
}
    `;

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument);
};
export const UpdateUserDocument = gql`
    mutation UpdateUser($data: UserPartialInput!, $filters: UserFilter) {
  updateUsers(data: $data, filters: $filters) {
    id
    name
    email
  }
}
    `;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};
export const AssignUserToCompanyDocument = gql`
    mutation AssignUserToCompany($data: CompanyUserRelationshipInput!) {
  assignUserToCompany(data: $data) {
    id
  }
}
    `;

export function useAssignUserToCompanyMutation() {
  return Urql.useMutation<AssignUserToCompanyMutation, AssignUserToCompanyMutationVariables>(AssignUserToCompanyDocument);
};
export const UnassignUserDocument = gql`
    mutation UnassignUser($data: CompanyUserRelationshipPartialInput!) {
  unassignUser(data: $data) {
    id
  }
}
    `;

export function useUnassignUserMutation() {
  return Urql.useMutation<UnassignUserMutation, UnassignUserMutationVariables>(UnassignUserDocument);
};
export const GetAllCompaniesDocument = gql`
    query GetAllCompanies($order: CompanyOrder) {
  companies(order: $order) {
    id
    name
    logoutTime
  }
}
    `;

export function useGetAllCompaniesQuery(options?: Omit<Urql.UseQueryArgs<GetAllCompaniesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCompaniesQuery>({ query: GetAllCompaniesDocument, ...options });
};
export const GetUsersByCompanyDocument = gql`
    query GetUsersByCompany($companyId: UUID!) {
  company(id: $companyId) {
    users {
      id
      name
      email
      roles(filters: {company: {id: {exact: $companyId}}}) {
        company {
          id
        }
        name
      }
    }
  }
}
    `;

export function useGetUsersByCompanyQuery(options: Omit<Urql.UseQueryArgs<GetUsersByCompanyQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersByCompanyQuery>({ query: GetUsersByCompanyDocument, ...options });
};
export const GetAllUnassignedUsersDocument = gql`
    query GetAllUnassignedUsers {
  unassignedUsers {
    id
    email
    name
  }
}
    `;

export function useGetAllUnassignedUsersQuery(options?: Omit<Urql.UseQueryArgs<GetAllUnassignedUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllUnassignedUsersQuery>({ query: GetAllUnassignedUsersDocument, ...options });
};